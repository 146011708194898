import React from "react";
import { HiExternalLink } from "react-icons/hi";
import ArbitNormalBadge from "../../sub-elements/normal-badge";
import priceHandler from "../../../utils/priceHandler";
import Icons from "../../orders/icons";
import Icon from "../icons";
import SupplyCost from "../components/supplyCost";
import components from "../../orders/components";
import InternalNote from "../components/internalNote";
import descriptiveContent from "../../../utils/descriptiveContent";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import CopyText from "../../dynamic-page/util/copyText";
import PopupUp from "../../dynamic-page/util/popUp";
import Button from "../../dynamic-form/elements/Button";
import DateDisplay from "../../dynamic-page/util/dateDisplay";
import TruncateText from "../../dynamic-page/util/truncateText";
import shortenString from "../../dynamic-page/util/shortenString";
import upperCase from "../../dynamic-page/util/upperCaseString";

const TableView = (
  list,
  handleSavePrice,
  handleSaveQty,
  handleConnectProduct,
  handledisConnectProduct,
  connect
) => {
  const activeStyle = (status) => {
    if (status) {
      return "!text-blue-500";
    } else {
      return "text-gray-300 hover:text-gray-500";
    }
  };

  const Note = Icons.internalNote;
  const Website = Icon.website;
  const Whole = Icon.wholesaler;

  return {
    list: list ?? [],
    theme: {
      notes: (item) => (
        <div className={"w-[25px]"}>
          <div className={"grid grid-cols-1  gap-[4px]"}>
            <PopupUp
              toggle={(selected) => (
                <div className={"relative"}>
                  <Button
                    item={{
                      buttonType: "flat",
                      className: `p-[2px]  ${activeStyle(
                        item?.notes?.length > 0
                      )} text-gray-500 hover:text-gray-300`,
                    }}
                  >
                    <Note className="" />
                  </Button>
                  {item?.notes?.length > 0 && (
                    <div
                      className={
                        "absolute top-[-3px] right-[-3px] bg-white text-blue-500 border border-blue-500 flex text-[8px] min-w-[14px] px-[3px] justify-center items-center font-black rounded-full"
                      }
                    >
                      {item?.notes?.length}
                    </div>
                  )}
                </div>
              )}
            >
              <div className={"w-[525px]"}>
                <InternalNote
                  callBack={null}
                  notes={item?.notes}
                  id={item?.id}
                />
              </div>
            </PopupUp>
          </div>
        </div>
      ),
      barcode: (item) => (
        <div className=" flex-col justify-center items-center gap-1 inline-flex">
          {item.source ? (
            <ArbitNormalBadge
              title={item.source}
              bgColor={item.source === "ARBITBOX" ? "blue-100" : "green-100"}
              textColor={item.source === "ARBITBOX" ? "blue-700" : "green-700"}
            />
          ) : (
            "-"
          )}
          <div className="flex items-center gap-1">
            <span>{item?.barcode}</span>
            <CopyText
              className={"text-[12px]"}
              text={item?.barcode}
              hint={false}
            />
          </div>
        </div>
      ),
      "last update": (item) => {
        return <DateDisplay item={item} date={item?.updated_at} />;
      },

      channel: (item) => {
        return (
          <div className="flex flex-col w-full gap-2 ">
            <div className="flex flex-col gap-1">
              <div className="flex gap-1 justify-start items-center">
                {item?.channel?.supply_channel_type === "WEBSITE" ? (
                  <Website className={"w-[16px] h-[18px] text-gray-300"} />
                ) : (
                  <Whole className={"w-[16px] h-[18px] text-gray-300"} />
                )}
                <div className="text-gray-900 text-[14px] font-normal leading-[14px]">
                  {item?.["channel"]?.name}
                </div>
              </div>
            </div>

            <div className="">
              <div className="flex items-center">
                {item?.identifier ? (
                  <span className={"text-[12px] text-blue-700"}>
                    {descriptiveContent(
                      item?.identifier ? item?.identifier : "-",
                      "Identifier"
                    )}
                  </span>
                ) : (
                  "-"
                )}
                <CopyText
                  className={"text-[9px]"}
                  text={item?.identifier}
                  hint={false}
                />
                {item?.url && (
                  <HiExternalLink
                    className="w-4 h-4 text-gray-300 cursor-pointer"
                    onClick={() => {
                      window.open(item?.url, "_blank");
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        );
      },

      image: (item) => (
        <div
          className={
            "w-[56px] h-[64px] max-h-[64px] justify-center items-center flex"
          }
        >
          <ImageMagnifier
            src={item?.product?.image || item?.image}
            className={"!max-h-[64px]"}
          />
        </div>
      ),
      name: (item) => (
        <div className=" gap-[8px] flex flex-col justify-start w-[100%] ">
          <div
            className={`flex flex-row gap-[8px] whitespace-pre-wrap cursor-pointer`}
          >
            {item?.title ? (
              <TruncateText
                maxLines={2}
                className={
                  "text-[12px] leading-[15px] !text-start text-gray-900   "
                }
                tooltip={true}
              >
                {item?.title || item?.product?.title}
              </TruncateText>
            ) : (
              "-"
            )}
          </div>
          <div className={"flex justify-between w-full items-center"}>
            <div className={"flex flex-row gap-[4px] text-gray-900"}>
              {shortenString(item?.product?.brand?.name, 30)}
              <CopyText
                className={"text-[14px]"}
                text={item?.product?.brand?.name}
                hint={false}
              />
            </div>

            {item?.product ? (
              <div className="flex items-center gap-[2px] !text-[12px]">
                <span
                  className={`w-[20px] h-[20px] rounded-full  flex justify-center items-center text-xs font-medium ${
                    item?.product?.type === 1
                      ? "text-teal-700 bg-teal-100"
                      : "text-indigo-700 bg-indigo-100"
                  } p-[0px]`}
                >
                  {item?.product?.type === 1 ? "S" : "G"}
                </span>
                <button
                  className={
                    "text-blue-700 hover:text-blue-800 cursor-pointer flex flex-row gap-[4px] "
                  }
                  onClick={() => {
                    window.open(`/product/${item?.product?.id}`, "_blank");
                  }}
                >
                  {item?.product?.brand?.assigned ? (
                    <>
                      {" "}
                      <img
                        alt="brand-assigned"
                        src={
                          item?.product?.brand?.assigned &&
                          (item?.product?.brand?.assigned?.profile_image ||
                            "/assets/images/defaultAvatar.png")
                        }
                        className={
                          "w-[20px] h-[20px] rounded-full object-cover"
                        }
                      />
                      {upperCase(item?.product?.brand?.assigned?.name, "word")}
                    </>
                  ) : (
                    <div className="text-gray-400">Unassigned</div>
                  )}
                </button>
              </div>
            ) : (
              <div className="flex items-center gap-[2px] !text-[12px]">
                <button
                  className={"flex flex-row gap-[2px]"}
                  onClick={() => {
                    connect.onClick(item);
                  }}
                >
                  <span className="w-[20px] h-[20px] rounded-full bg-red-100 flex justify-center items-center text-xs font-medium text-red-800 p-[0px]">
                    <Icons.Link className={"w-[11px] h-[11px]"} />
                  </span>
                  <div
                    className={
                      "text-red-700 hover:text-red-800 cursor-pointer "
                    }
                  >
                    Not Connected
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>
      ),

      price: (item) => (
        <div className="w-[100%] justify-end items-center">
          <SupplyCost
            price={{
              usd_price: priceHandler(item?.usd_price),
              price: priceHandler(item?.price),
              data: item,
            }}
            handleSavePrice={handleSavePrice}
          />
        </div>
      ),
      qty: (item) => (
        <div className="w-[100%] flex justify-end items-center">
          {item?.quantity ? (
            <components.saveWeight
              text={item?.quantity}
              isQuantity={true}
              onSave={(t) => handleSaveQty(t, item)}
              className={
                "min-w-max whitespace-no-wrap group-active:text-green-800 hover:text-green-600 flex text-[24px] text-green-700 font-[700]"
              }
              outerClassName={"!justify-end"}
            />
          ) : (
            <Icon.Amount className={"w-5 h-5 text-gray-300 "} />
          )}
        </div>
      ),
    },
  };
};
export default TableView;
