import ArbitToggle from "../../sub-elements/toggle";
import PropTypes from "prop-types";
const RepricerToggle = ({ item, change, data }) => {
  return (
    <div
      className={`w-full  flex items-center gap-[12px] ${item?.toggleClassName}  `}
    >
      <ArbitToggle
        status={data[item?.name] ?? false}
        bgColor={data[item?.name] ? "bg-blue-700" : "bg-gray-200"}
        handleFulfilment={() => change(!data[item?.name], item?.name)}
      />
      <div
        className={`grow shrink basis-0  flex items-center gap-2 ${item?.toggleDescriptionClassName} `}
      >
        <div
          className={`text-gray-900 text-[14px] font-medium font-['Inter'] !leading-[21px] ${item?.toggleLabelClassName}`}
        >
          {item.label}
        </div>
        <div className="self-stretch text-gray-500 text-[14px] font-normal font-['Inter'] leading-[21px]">
          {item.description}
        </div>
      </div>
    </div>
  );
};
RepricerToggle.propTypes = {
  item: PropTypes.shape({
    checkbox: PropTypes.bool,
    toggleLabelClassName: PropTypes.string,
    disabled: PropTypes.bool,
    innerClassName: PropTypes.string,
    toggleClassName: PropTypes.string,
    toggleDescriptionClassName: PropTypes.string,
    repricerToggleClassName: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  change: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default RepricerToggle;
