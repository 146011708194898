import React from "react";

const summaryEditDescription = (summaryForm, orderItems, callBackAction, data) => {
    return [
        {
            label: 'Recipient Name',
            value: data?.recipient?.name,
            theme: (value) => (
                <button
                    type="button"
                    className={`text-blue-700  text-base font-normal transition opacity-100 cursor-pointer ${!value ? "opacity-50 hover:opacity-100" : "opacity-100"}`}
                    onClick={() => {
                        //callBackAction("tab", {tab: "recipient"})
                        callBackAction("recipient", {action: "switchTab"})
                    }}>{value ?? "Enter Recipient"}</button>
            ),

        },
        {
            label: 'Recipient Country',
            value: data?.boundData?.country ?
                {
                    flag: data?.boundData?.country?.flag,
                    code: data?.boundData?.country?.data?.code
                } : null,
            theme: (value) => (
                <div className='flex items-center flex-row gap-[4px]'>
                    {value?.flag ?
                        <img src={value?.flag} alt='' className='w-[28px] h-[20px] rounded-[2px] object-cover'/> : null}
                    <span>{value?.code ? value?.code : "-"}</span>
                </div>
            )
        }
    ]


}

export default summaryEditDescription