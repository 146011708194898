import React from "react";
import util from "../../dynamic-page/util";
import Icons from "../../orders/icons";
import StatusSteps from "../components/StatusSteps";
import StatusHistory from "../components/statusHistory";

import Weight from "../components/weight";
import ShipmentLabel from "../components/shipmentLabel";

import InternalNote from "../components/internalNote";

import descriptiveContent from "../../../utils/descriptiveContent";

import ShippingContent from "../components/shippingContent";
import ShipTo from "../../orders/components/table/shipTo";
import Button from "../../dynamic-form/elements/Button";

const tableView = (list, callBack) => {
  const activeStyle = (status) => {
    if (status) {
      return "!text-blue-500";
    } else {
      return "text-gray-300 hover:text-gray-500";
    }
  };

  const statusLabel = (item) => {
    if (item?.statuses) {
      return Object.values(item?.statuses).map((status) => {
        return {
          action: status?.detail,
          date: status?.timestamp,
          user: status?.created_by || "System",
          location: status?.location || "Warehouse",
        };
      });
    } else {
      return [];
    }
  };

  const Receipt = Icons.receipt;
  const Archive = Icons.archive;
  const ShippingContentIcon = Icons.shippingContent;
  const InternalNoteIcon = Icons.internalNote;

  const handleText = (item) => {
    if (item?.shippable_type === "order") {
      return (
        <div className="bg-orange-100 justify-center rounded-[6px] px-[8px] py-[2px] flex gap-1 items-center">
          <span>
            <Receipt className="w-3 h-3 text-orange-900" />
          </span>
          <span className="text-[12px] leading-[18px] font-medium text-orange-900 ">
            Customer Order
          </span>
        </div>
      );
    }
    if (item?.shippable_type === "transfer") {
      return (
        <div
          className={`${
            item?.shippable?.type === "order"
              ? "bg-green-100 w-[108px]"
              : "bg-blue-100  w-[129px]"
          } rounded-[6px] px-[16px] py-[2px] flex gap-1 items-center justify-center `}
        >
          <span>
            <Archive
              className={`w-3 h-3 ${
                item?.shippable?.type === "order"
                  ? "text-green-900"
                  : "text-blue-900"
              } `}
            />
          </span>
          <span
            className={`capitalize text-[12px] leading-[18px] font-medium ${
              item?.shippable?.type === "order"
                ? "text-green-900"
                : "text-blue-900"
            } `}
          >
            {item?.shippable?.type} Transfer
          </span>
        </div>
      );
    }
  };

  const statusRedirect = (item) => {
    return encodeURIComponent(JSON.stringify(item));
  };

  const handleNumber = (item) => {
    if (item?.shippable_type === "order") {
      return (
        <div
          className={
            "min-w-max whitespace-no-wrap text-[#1A56DB] text-[12px]  cursor-pointer text-center justify-center items-center flex flex-row gap-[4px]"
          }
        >
          {item?.shippable?.order_numbers?.length > 1 && (
            <util.popUp
              toggle={() => (
                <div
                  className={
                    "bg-blue-100 py-[1px] flex items-center  min-w-[20px] h-[20px] px-[6px] text-blue-800 rounded-full hover:bg-blue-200 transition-all"
                  }
                >
                  {item?.shippable?.order_numbers?.length}
                </div>
              )}
              action="click"
            >
              <div
                className={`flex flex-col gap-1 p-2  ${
                  item?.shippable?.order_numbers?.length > 10 &&
                  "overflow-y-scroll max-h-[230px]"
                } `}
              >
                {item?.shippable?.order_numbers?.map((order, index) => (
                  <div
                    key={item?.id}
                    className="flex justify-between items-center gap-2"
                  >
                    <span className="text-gray-700 font-medium">{order}</span>
                    <div>
                      <util.copyText
                        className={"text-[12px]"}
                        text={order}
                        hint={false}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </util.popUp>
          )}
          <button
            onClick={() => {
              window.open(
                `/logistics/orders?filter[order_number]=${item?.shippable?.order_numbers[0]}&filter[order_status]=${item?.shippable?.order_status_id}&boundFilters=${statusRedirect(
                  {
                    order_status : {
                      value: item?.shippable?.order_status_id,
                      label: item?.shippable?.order_status,
                    }
                  }
                )}`,
                "_blank"
              );
            }}
            className="text-center flex justify-center items-center text-[12px] leading-[18px] font-medium "
          >
            {item?.shippable?.order_numbers[0]}
          </button>
          <div className="flex items-center">
            <util.copyText
              className={"text-[12px]"}
              text={item?.shippable?.order_numbers[0]}
              hint={false}
            />
          </div>
        </div>
      );
    }
    if (item?.shippable_type === "transfer") {
      return (
        <div className="flex gap-1 items-center text-[#1A56DB] ">
          <button
            onClick={() => {
              window.open(
                `/logistics/transfers?filter[code]=${item?.shippable?.code}&filter[status]=${item?.shippable?.status_id}`,
                "_blank"
              );
            }}
            className="text-center cursor-pointer flex justify-center items-center text-[12px] leading-[18px] font-medium "
          >
            {item?.shippable?.code}
          </button>
          <div className="flex items-center">
            <util.copyText
              className={"text-[12px]"}
              text={item?.shippable?.code}
              hint={false}
            />
          </div>
        </div>
      );
    }
  };

  return {
    list: list,
    theme: {
      notes: (item) => (
        <div className="flex flex-col gap-1">
          <div className={"flex justify-center items-center"}>
            {/* Internal note is here */}
            <util.popUp
              toggle={(selected) =>
                descriptiveContent(
                  <div className={"relative"}>
                    <Button
                      item={{
                        buttonType: "flat",
                        className: `p-[2px] w-[20px] h-[20px]  ${activeStyle(
                          item?.notes_count > 0
                        )}`,
                      }}
                    >
                      <InternalNoteIcon className="" />
                    </Button>
                    {item?.notes_count > 0 && (
                      <div
                        className={
                          "absolute top-[-3px] right-[-3px] bg-white text-blue-500 border border-blue-500 flex text-[8px] min-w-[14px] px-[3px] justify-center items-center font-black rounded-full"
                        }
                      >
                        {item?.notes_count}
                      </div>
                    )}
                  </div>,
                  "Internal note",
                  null,
                  "top"
                )
              }
              // leftSpace={style?.leftSpace}
            >
              <div className={"w-[525px]"}>
                <InternalNote
                  callBack={callBack || null}
                  notes={item?.notes_count}
                  id={item?.id}
                />
              </div>
            </util.popUp>
          </div>
          <div className={"flex justify-center items-center"}>
            {/* Shipping Content is here */}

            <util.popUp
              toggle={(selected) =>
                descriptiveContent(
                  <div>
                    <Button
                      item={{
                        buttonType: "flat",
                        className: `p-[2px] w-[20px] h-[20px] ${activeStyle(
                          item?.shippable?.shipping_content ||
                            item?.shippable?.content
                        )}`,
                      }}
                    >
                      <ShippingContentIcon className="" />
                    </Button>
                  </div>,
                  "Shipping content",
                  null,
                  "top"
                )
              }
              // leftSpace={style?.leftSpace}
              className=""
            >
              <div className={"px-[8px] py-[4px]"}>
                <ShippingContent
                  shipping_content={
                    item?.shippable?.shipping_content ||
                    item?.shippable?.content
                  }
                  gtip={item?.shippable?.gtip}
                  shippableType={item?.shippable_type}
                  id={item?.shippable?.id}
                  shipmentId={item?.id}
                  callBack={(data) => {
                    if (callBack) {
                      callBack(data);
                    }
                  }}
                />
              </div>
            </util.popUp>
          </div>
        </div>
      ),
      identifier: (item) => {
        return (
          <div className="flex flex-col  gap-1.5 justify-center items-center">
            {handleText(item)}
            {handleNumber(item)}
          </div>
        );
      },
      "created at": (item) => {
        return <util.dateDisplay item={item} date={item?.created_at} />;
      },
      status: (item) => {
        return descriptiveContent(
          <StatusSteps
            item={item}
            statusId={item?.current_status_id || 1}
            list={<StatusHistory item={item} list={statusLabel(item)} />}
          />,
          item?.current_status || "Status",
          null,
          "top"
        );
      },
      "latest event": (item) => {
        return <util.dateDisplay item={item} date={item?.updated_at} />;
      },
      "tracking id": (item) => (
        <div className={"flex  justify-start !w-full flex-1"}>
          <ShipmentLabel shipment={item} callBack={callBack} />
        </div>
      ),
      weight: (item) => (
        <div className={"flex flex-col  gap-[0px]"}>
          <Weight
            order_status_id={item?.order_status_id}
            weight={{
              billable: item?.shippable?.billable_weight
                ? (+item?.shippable.billable_weight).toFixed(2)
                : "-",
              actual: item?.shippable?.actual_weight
                ? (+item?.shippable.actual_weight).toFixed(2)
                : "-",
              dim: item?.shippable?.dim_weight
                ? (+item?.shippable.dim_weight).toFixed(2)
                : "-",
              selected:
                +item?.shippable?.actual_weight > +item?.shippable?.dim_weight
                  ? "actual"
                  : "dimensional",
              orderId: item?.shippable?.id,
              id: item?.id,
            }}
          />
        </div>
      ),
      "ship to": (item) => (
        <div className={"flex flex-col gap-[8px] relative w-full"}>
          <ShipTo
            state={item?.ship_to?.state}
            name={item?.ship_to?.recipient_name}
            city={item?.ship_to?.city}
            country={item?.ship_to?.country}
            // customerOrderCount = {item?.custome?._order_count}
            callBack={callBack}
          />
        </div>
      ),
      secret: (item) => <div></div>,
    },
  };
};
export default tableView;
