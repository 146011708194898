import React, { useEffect } from "react";
import MainModal from "../../dynamic-page/MainModal";
import HandmadeSkeleton from "../../../utils/skeleton";
import axios from "axios";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import Icons from "../../orders/icons";
import moment from "moment";

const PriceHistoryView = ({
  changes,
  status,
  page,
  data,
  hasMore,
  fetchMoreData,
}) => (
  <div className="min-h-[589.5px] w-[1100px] flex flex-col ">
    <hr />
    <div
      id="scrollableDiv"
      className={`max-h-[589.5px] shadow-md pb-4 min-w-full min-h-[400px] overflow-x-auto flex rounded-[16px] flex-1 mt-4 mb-2 flex-col`}
    >
      <div
        style={{ height: "100%", overflow: "auto" }}
        id="price-history-scrollable"
      >
        <InfiniteScroll
          dataLength={changes.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <div className="w-full px-6 py-3 gap-2 flex flex-col">
              {[1, 2, 3].map((item) => (
                <HandmadeSkeleton key={item} className={"!w-full h-9"} />
              ))}
            </div>
          }
          scrollableTarget="price-history-scrollable"
        >
          <table className="w-full table-auto ">
            <thead className="bg-gray-50 sticky top-0 z-[50] w-full dynamicTableMain">
              <tr>
                <th className="w-[380px] h-[50px] text-start border-b border-gray-200 whitespace-nowrap">
                  <span className="px-4 leading-[18px] font-semibold text-gray-500">
                    OLD PRICE
                  </span>
                </th>
                <th className="w-[380px] h-[50px] text-start border-b border-gray-200 whitespace-nowrap">
                  <span className="px-4 leading-[18px] font-semibold text-gray-500">
                    NEW PRICE
                  </span>
                </th>
                <th className="min-w-[104px] h-[50px] text-start border-b border-gray-200 whitespace-nowrap">
                  <span className="px-4 leading-[18px] font-semibold text-gray-500">
                    SKU
                  </span>
                </th>
                <th className="min-w-[104px] h-[50px] text-start border-b border-gray-200 whitespace-nowrap">
                  <span className="px-4 leading-[18px] font-semibold text-gray-500">
                    CORRELATION ID
                  </span>
                </th>
                <th className="min-w-[133px] h-[50px] text-start border-b border-gray-200 whitespace-nowrap">
                  <span className="px-4 leading-[18px] font-semibold text-gray-500">
                    TIME
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className="min-w-[700px]">
              {changes.map((item, index) => (
                <tr className="hover:bg-gray-50" key={item?.id || index}>
                  <td className="w-[133px] h-[54px] text-start border-b whitespace-nowrap border-gray-200">
                    <div>
                      <span className="px-4 leading-[21px]">
                        {item.old_price?.toFixed(2)}
                      </span>
                    </div>
                  </td>
                  <td className="w-[133px] h-[54px] text-start border-b whitespace-nowrap border-gray-200">
                    <div>
                      <span className="px-4 leading-[21px]">
                        {item.new_price?.toFixed(2)}
                      </span>
                    </div>
                  </td>
                  <td className="w-[210px] h-[54px] text-start border-b whitespace-nowrap border-gray-200">
                    <div className="px-4">{item?.sku}</div>
                  </td>
                  <td className="w-[210px] h-[54px] text-start border-b whitespace-nowrap border-gray-200">
                    <div className="px-4">{item?.correlation_id}</div>
                  </td>
                  <td className="w-[104px] h-[54px] text-start border-b whitespace-nowrap border-gray-200">
                    <div>
                      <span className="px-4 leading-[21px]">
                        {moment(item?.created_at).format("DD/MM/YYYY  HH:mm")}
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
      {changes.length === 0 && status === "idle" && page === 1 && (
        <div
          className={
            "w-[100%] top-[250px] px-6 py-3 gap-2 flex flex-col absolute"
          }
        >
          <div
            className={`flex flex-col h-full text-center py-[50px] rounded-2xl items-center `}
          >
            <Icons.BrokenHeart className="w-10 h-10 mb-4 text-gray-400" />
            <p className={"text-2xl text-gray-500"}>
              The selected Listing has no price change history.
            </p>
          </div>
        </div>
      )}
    </div>
  </div>
);

PriceHistoryView.propTypes = {
  changes: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  hasMore: PropTypes.bool.isRequired,
  fetchMoreData: PropTypes.func.isRequired,
};
PriceHistoryView.propTypes = {
  changes: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  hasMore: PropTypes.bool.isRequired,
};

export default function PriceHistoryModal({ onClose, data }) {
  const [changes, setChanges] = React.useState([]);
  const [status, setStatus] = React.useState("idle");

  const [page, setPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(true);

  const fetchHistory = async (pageNumber) => {
    setStatus("pending");
    try {
      const response = await axios.get(
        `/api/v1/repricer/${data.repricer.id}/changes?page=${pageNumber}`
      );
      setChanges((prevChanges) => [...prevChanges, ...response.data.data]);
      setStatus("idle");

      const { current_page, last_page } = response.data.meta;
      if (current_page >= last_page) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching activities:", error);
      setStatus("error");
    }
  };

  useEffect(() => {
    if (data?.repricer?.id) {
      setChanges([]);
      setPage(1);
      setHasMore(true);
      fetchHistory(1);
    }
  }, [data]);

  useEffect(() => {
    if (page > 1) {
      fetchHistory(page);
    }
  }, [page]);

  const fetchMoreData = () => {
    if (hasMore && status !== "pending") {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const view = () => (
    <PriceHistoryView
      changes={changes}
      status={status}
      page={page}
      data={data}
      hasMore={hasMore}
      fetchMoreData={fetchMoreData}
    />
  );

  return (
    <MainModal
      item={{
        title: `Price History`,
        cancel: onClose,
        view: view,
      }}
    />
  );
}
PriceHistoryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    repricer: PropTypes.shape({
      id: PropTypes.number,
    }),
    sales_channel_listing: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};
