// navItems.js

const navItems = [
  {
    title: "Product",

    options: [
      {
        label: "Products",
        link: "/product/list",
      },
      {
        label: "Order & Supply Summary",
        link: "/product/summary",
      },
      {
        label: "Brands",
        link: "/product/brands",
      },
    ],
  },
  {
    title: "Listing",

    options: [
      {
        label: "Sales Channel Listings",
        link: "/listing/sales_listings",
      },
      {
        label: "Offers",
        link: "/listing/offers",
      },

      {
        label: "Automate Repricing",
        link: "/listing/automate_repricing",
      },
      // {
      //     label: "Repricer Offers",
      //     link: "/listing/repricer_offers",
      //     icon: <MdLibraryAdd/>
      // },
      {
        label: "Supply Channel Listings",
        link: "/listing/supply_channel_listings",
      },
    ],
  },
  {
    title: "Logistics",

    options: [
      {
        label: "Orders",
        link: "/logistics/orders",
      },
      {
        label: "Transfers",
        link: "/logistics/transfers",
      },
      {
        label: "Notifications",
        link: "/logistics/notifications",
      },

      {
        label: "Shipping Costs",
        link: "/logistics/shipping-costs",
      },
      {
        label: "Shipments",
        link: "/logistics/shipments",
      },
      {
        label: "Returns",
        link: "/logistics/returns",
      },
    ],
  },
  {
    title: "Growth",

    options: [
      {
        label: "Target Seller Folders",
        link: "/growth/target_seller_folders",
      },
    ],
  },
  {
    title: "Inventory",

    options: [
      {
        label: "Inventory Adjustments",
        link: "/inventory/inventory_adjustments",
      },
      {
        label: "Inventory Purchases",
        link: "/inventory/inventory_purchases",
      },
      {
        label: "Supply",
        link: "/inventory/supply_requests",
      },
      {
        label: "FC Restocking",
        link: "/inventory/fc_restocking",
      },
    ],
  },
];

export default navItems;
