import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import api from "../../../../store/transfers/api";
import { v4 as uuidv4 } from 'uuid';

import upperCaseString from "../../../dynamic-page/util/upperCaseString";
import HandmadeSkeleton from "../../../../utils/skeleton";
import Icons from "../../../orders/icons";
import Button from "../../../dynamic-form/elements/Button";

const CreateShipmentView = ({ item, cancel, submit, awaiting_transfer }) => {
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [update, setUpdate] = React.useState(false);
  const [carriers, setCarriers] = React.useState([]);
  const [disableButton, setDisableButton] = React.useState(true);

  const headList = ["provider", "CARRiER", "SERViCE", "T. COST"];

  
  

  useEffect(() => {
    const fetchCarriers = () => {
      if (item?.orderId && item?.from) {
        setUpdate(true);
        api.fetchShipmentCarrier(item).then((rs) => {

          setCarriers(rs?.data);
          setUpdate(false);
        });
      }
    };

    fetchCarriers();
  }, [item?.to, item?.orderId]);

  const handleSelectedItem = (item) => {
    if (selectedItem === item) {
      setSelectedItem(null);
      setDisableButton(true);
    } else {
      setSelectedItem(item);
      setDisableButton(false);
    }
  };

  const daysHoursAgoFromIso = (date) => {
    const now = new Date();
    const inputDate = new Date(date);

    const diff = now - inputDate;
    if (diff < 0) {
      return "few minutes";
    }

    const hours = Math.floor(diff / 1000 / 60 / 60) % 24;
    const days = Math.floor(diff / 1000 / 60 / 60 / 24);

    if (days === 0 && hours === 0) {
      return "few minutes";
    }

    return days > 0 ? `${days}d ${hours}h` : `${hours}h`;
  };

  const renderTable = () => {
    return item?.orderId && item?.to && carriers?.length ? (
      carriers?.map((item, index) => (
        <tr
          className={`transition text-[14px] leading-[21px] ${item?.id === selectedItem
              ? "bg-blue-100 hover:bg-blue-100 cursor-default border-b border-b-blue-200"
              : "hover:bg-gray-50 bg-white cursor-pointer border-b border-b-gray-200"
            }`}
          key={index}
          onClick={() => handleSelectedItem(item?.id)}
          style={{ height: "54px", boxSizing: "border-box" }}
        >
          <td
            className={
              "flex items-center max-h-[54px] w-[200px] flex-row gap-[8px] border-b-gray-200"
            }
            style={{
              height: "54px",
              padding: "0",
              boxSizing: "border-box",
            }}
          >
            <div
              className={`p-[16px] flex flex-row gap-[4px] items-center font-medium`}
            >
              <img
                src={item?.carrier?.provider?.logo}
                className={"bg-none h-[16px] w-4"}
              />
              {upperCaseString(item?.carrier?.provider?.name, "first")}
            </div>
          </td>
          <td
            className={
              "max-h-[54px] w-[200px] border-b border-b-gray-200"
            }
            style={{
              height: "54px",
              padding: "0",
              boxSizing: "border-box",
            }}
          >
            <div
              className={`p-[16px] flex flex-row gap-[4px] items-center font-medium`}
            >
              {item?.carrier?.international_carrier?.logo ? (
                <img
                  src={item?.carrier?.international_carrier?.logo}
                  className={"bg-none h-[16px] w-4"}
                />
              ) : (
                <Icons.track
                  className={"w-[16px] h-4 text-gray-300"}
                />
              )}
              {upperCaseString(item?.carrier?.name || "")}
            </div>
          </td>
          <td
            className={"max-h-[54px] border-b border-b-gray-200"}
            style={{
              height: "54px",
              padding: "0",
              boxSizing: "border-box",
            }}
          >
            <div
              className={`p-[16px] flex flex-row gap-[8px] items-center font-medium min-w-[404px]`}
            >
              {upperCaseString(item?.service_name || "")}
            </div>
          </td>
          <td
            className={
              "max-h-[54px] w-[120px] border-b border-b-gray-200"
            }
            style={{
              height: "54px",
              padding: "0",
              boxSizing: "border-box",
            }}
          >
            <div
              className={`p-[16px] flex flex-row gap-[8px] justify-between items-center font-semibold`}
            >
              $
              {item?.cost?.toFixed(2)}
            </div>
          </td>
        </tr>
      ))
    ) : (
      <div
        className={
          "w-[100%] min-h-[712px] px-6 py-3 gap-2 flex flex-col absolute"
        }
      >
        <div
          className={`flex flex-col h-full text-center py-[50px] rounded-2xl items-center `}
        >
          <Icons.brokenHeart className="w-10 h-10 mb-4 text-gray-400" />
          <p className={"w-[700px] text-gray-400 font-medium"}>
            Sorry, we were unable to calculate shipping costs for this
            region. Please check the order shipping details. Or
            refreshing the data can often fix this issue.
          </p>
        </div>
      </div>
    )
  }

  return (
    <div
      className={
        "border-t border-t-gray-200 h-[inherit]  min-w-[700px]  flex flex-col"
      }
    >
      <div
        className={`!max-h-[463px] min-h-[463px] min-w-[924px] ${
          !update ? "overflow-y-auto" : "overflow-y-hidden"
        } flex flex-1 flex-col`}
      >
        <table className={`w-full table-auto h-max`}>
          <thead
            className={"sticky t-[0px] z-[2] l-[0px] bg-gray-50 w-full"}
            style={{ position: "sticky", top: 0, left: 0, zIndex: 2 }}
          >
            <tr className={"text-gray-600 text-[12px] font-semibold"}>
              {headList.map((key, index) => (
                <th
                  key={uuidv4()}
                  className="justify-start !max-w-min w-fit text-left h-[42px] py-4 items-center px-[16px] border-b border-gray-200"
                >
                  <span className="whitespace-no-wrap block text-gray-500 uppercase text-[12px] leading-[18px] font-semibold select-none">
                    {key}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={"relative"}>
            {!update ? renderTable() : (
              <div
                className={
                  "min-w-[924px]  px-6 py-3 gap-2 flex flex-col absolute"
                }
              >
                {[...Array(70)].map((_, index) => (
                  <HandmadeSkeleton key={uuidv4()} className={"w-full h-9"} />
                ))}
              </div>
            )}
          </tbody>
        </table>

      </div>
      <div
        className={
          "flex flex-row gap-[8px] justify-end px-4 pt-3 pb-0 h-[52px] "
        }
      >
        <div
          className={
            "flex w-full text-[16px] items-center text-gray-600 gap-[6px]"
          }
        >
          {
            <>
              <Button
                item={{
                  title: "Refresh",
                  popUp: {
                    leftSpace: 20,
                  },
                  className:
                    "h-[20px] w-[20px] bg-white text-gray-500 px-0 !justify-start",
                }}
                onClick={() => {
                  setUpdate(true);
                  const fetchCarriers = () => {
                    if (item?.orderId && item?.to) {
                      const payload = {
                        ...item,
                        fresh: 1,
                      };
                      api.fetchShipmentCarrier(payload).then((rs) => {
                        setCarriers(rs?.data);
                        setUpdate(false);
                      });
                    }
                  };
                  fetchCarriers();
                }}
              >
                <Icons.Refresh
                  className={`w-[20px] h-[20px]  text-gray-500 ${
                    update ? "animate-spin" : ""
                  }`}
                />
              </Button>
              The costs were last updated{" "}
              {/* show as d h ago */}
              <span className="text-gray-700 font-medium">
                {carriers?.length && !update ? (
                  <>{daysHoursAgoFromIso(carriers[0]?.updated_at)} ago.</>
                ) : null}
              </span>
            </>
          }
        </div>
        <Button
          item={{
            buttonType: "primary",
            className: "h-[40px] !w-[152px] !px-5 !py-2.5 whitespace-nowrap",
            disabled: disableButton, //selectedItem === null
          }}
          onClick={() => {
            if (!disableButton) {
              setDisableButton(true);
              submit({
                warehouse_id: item?.warehouseId,
                order_id: item?.orderId,
                shipment_pricing_id: selectedItem,
                callBack: () => {
                  setDisableButton(false);
                },
              });
            }
          }}
        >
          Create Shipment
        </Button>
      </div>
    </div>
  );
};
CreateShipmentView.propTypes = {
  item: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  awaiting_transfer: PropTypes.bool
};

export default CreateShipmentView;
