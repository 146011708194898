// --------------------------------------------------------------------------------------------
// summary/index.js
// --------------------------------------------------------------------------------------------
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

// Import components.
import SummaryItems from "./items";
import SummaryLeftSide from "./leftSide";

import PropTypes from "prop-types";

// Main component.
const SummaryTab = memo(
  forwardRef((props, ref) => {
    // Get props. and using useMemo to prevent unnecessary re-render.
    const { data, isView, id, callBack, update, selectedOrder } = useMemo(
      () => ({
        data: props.data,
        isView: props.isView,
        id: props.id,
        callBack: props.callBack,
        update: props.update,
        selectedOrder: props.selectedOrder,
      }),
      [props]
    );

    // Set states.
    const [orderItems, setOrderItems] = useState([]);
    const [summaryForm, setSummaryForm] = useState(null);
    const [validation, setValidation] = useState(true);

    // Create ref for SummaryEdit component.
    const summaryEditRef = useRef(null);

    // Set data to state when data is available.
    useEffect(() => {
      setOrderItems(data?.summary?.order_items);
      setSummaryForm(data?.summary);
    }, [data]);

    // Create ImperativeHandle for parent component.
    useImperativeHandle(ref, () => ({
      getData: async () => {
        let summaryEditValidation = await summaryEditRef?.current?.validate(
          true
        );
        if (!orderItems?.length) {
          summaryEditValidation.isValid = false;
          summaryEditValidation.validationErrors.order_items =
            "Orders must have at least one item";
        }
        if (validation !== summaryEditValidation)
          setValidation(summaryEditValidation);
        return {
          ...summaryForm,
          order_items: orderItems,
          _validation: summaryEditValidation,
        };
      },
    }));

    // Update order items data.
    const handleUpdateOrderListData = useCallback(
      (list) => {
        setOrderItems(list);
      },
      [summaryForm]
    );

    // Update summary form data.
    const handleUpdateSummaryFormData = useCallback(
      (form, bind) => {
        setSummaryForm({
          ...summaryForm,
          ...form,
          _data: { ...summaryForm?._data, ...bind },
        });
      },
      [summaryForm]
    );

    const filteredList = isView
      ? orderItems?.filter((item) => item?.unit_price >= 0)
      : orderItems;
    // Render component.
    return (
        <div className="flex flex-row h-[600px] min-h-[600px] max-h-[600px] gap-[8px] flex-1 orderDetailModalSummary ">
          {/* Order Left Side */}
          <div style={{ width: "360px", maxHeight: "calc(100vh - 220px)" }}>
            <SummaryLeftSide
              ref={summaryEditRef}
              callBack={handleUpdateSummaryFormData}
              isView={isView}
              id={id}
              data={data}
              selectedOrder={selectedOrder}
              summaryForm={summaryForm}
              orderItems={orderItems}
              actionCallBack={callBack}
              update={update}
            />
          </div>
          {/* Order Items */}
          <div
            style={{
              width: "calc(100% - 368px)",
              // maxHeight: "calc(100vh - 220px)",
            }}
          >
            <SummaryItems
              isView={isView}
              id={id}
              update={update}
              data={data}
              orderItems={filteredList}
              callBack={handleUpdateOrderListData}
              validation={validation}
              actionCallBack={callBack}
            />
          </div>
        </div>
    );
  })
);

SummaryTab.propTypes = {
  data: PropTypes.object.isRequired,
  isView: PropTypes.bool,
  id: PropTypes.string,
  callBack: PropTypes.func,
  update: PropTypes.func,
  selectedOrder: PropTypes.object,
};

export default SummaryTab;
