/**
 * @author Austin Ames
 * @description Badge - a badge with key and value
 */

import classNames from "classnames";
import PropType from "prop-types";
import ArbitEditableDropdown from "./editable-dropDown";

import Icons from "../orders/icons";
import PopupUp from "../dynamic-page/util/popUp";

const ToggleIcon = () => (
  <Icons.Info className="w-5 h-5 text-gray-300 hover:text-gray-500" />
);

const ArbitBadge = ({
  title,
  value,
  bgColor,
  editable,
  secondValue,
  onChange,
  options,
  loadOptions,
  placeholder,
  creatable,
  tooltip,
  copy,
}) => {
  let defaultOptions;
  if (options) {
    defaultOptions = options.forEach((item) => ({
      label: item.name,
      value: item.id,
    }));
  }

  return (
    <div
      className={classNames(
        bgColor && "bg-" + bgColor,
        "flex flex-col rounded-[8px] gap-0.5 w-full h-[79px] px-[16px] py-[8px] space-y-[4px]  "
      )}
    >
      <div className="flex gap-1  items-center justify-between ">
        <p className="text-[16px] font-bold text-gray-600 flex-[1_0_0]  whitespace-nowrap">
          {title}
        </p>
        {tooltip && (
          <PopupUp
            leftSpace={500}
            toggle={ToggleIcon}
            action={"hover"}
            forcePosition={tooltip?.position}
          >
            <div className="flex flex-col gap-1.5  w-[240px] p-2 ">
              <span className="text-[14px] leading-[14px] font-medium text-gray-900">
                {tooltip?.title}
              </span>
              <span className="text-[12px] leading-[15px] text-gray-500 font-normal">
                {tooltip?.desc}
              </span>
            </div>
          </PopupUp>
        )}
      </div>
      <div
        className={`flex space-x-[8px]     items-center w-full ${
          !editable && "overflow-hidden"
        }`}
      >
        <ArbitEditableDropdown
          creatable={creatable}
          secondValue={secondValue}
          fontSize={"20px"}
          fontWeight={"500"}
          copy={copy}
          fontColor={"gray-800"}
          bgColor={bgColor}
          value={value}
          options={defaultOptions}
          loadOptions={loadOptions}
          placeholder={placeholder}
          showIcon={editable}
          onChange={(value) => onChange(value)}
          width="full"
        />
      </div>
    </div>
  );
};

ArbitBadge.propTypes = {
  title: PropType.string, // String for badge title
  value: PropType.oneOfType([PropType.string, PropType.number]), // String or number
  bgColor: PropType.string, // Background color as a string
  editable: PropType.bool, // Boolean for editable state
  secondValue: PropType.string, // Optional second value
  onChange: PropType.func, // Function callback
  options: PropType.arrayOf(
    PropType.shape({
      name: PropType.string.isRequired, // Each option requires a name
      id: PropType.oneOfType([PropType.string, PropType.number]).isRequired, // ID as string or number
    })
  ),
  loadOptions: PropType.func, // Async load options function
  placeholder: PropType.string, // Placeholder text
  creatable: PropType.bool, // Boolean for creatable options
  tooltip: PropType.shape({
    // Tooltip structure
    position: PropType.string,
    title: PropType.string,
    desc: PropType.string,
  }),
  copy: PropType.bool, // Boolean for copy functionality
};
ArbitBadge.defaultProps = {
  title: "Arbit Badge",
  value: "",
  bgColor: "gray-50",
  editable: false,
  onChange: () => {},
};

export default ArbitBadge;
