import React from "react";

import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import StatusHistory from "../../goods-acceptance/components/SupplyStatusHistory";
import moment from "moment";

import { howLongFromNow } from "../../../utils/helper";
import ShipmentLabel from "../components/table/shipmentLabel";
import Weight from "../components/table/weight";
import Profit from "../components/table/profit";
import Item from "../components/table/item";
import OrderStatus from "../components/table/orderStatus";
import OrderRowAction from "../components/table/orderRowAction";
import SalesChannelStatus from "../components/table/salesChannelStatus";
import OrderId from "../components/table/orderid";
import NumberPriorityColor from "../components/table/numberPriorityColor";
import ShipTo from "../components/table/shipTo";
import PopupUp from "../../dynamic-page/util/popUp";

const statusLabel = (item) => [
  {
    action: "Shipped",
    name: item?.accepted_by?.name,
    date: item?.accpeted_at,
    qty: item?.quantity,
  },
  {
    action: "Dilevered",
    name: item?.purchase?.created_by?.name,
    date: item?.purchase?.created_at,
    qty: item?.purchase?.quantity,
  },
  {
    action: "Created",
    name: item?.purchase?.supply_request?.requester?.name,
    date: item?.purchase?.supply_request?.request_date_time,
    qty: item?.purchase?.supply_request?.qty,
  },
];

const tableView = (
  list,
  callBack,
  isAwaiting,
  updateRow,
  onFilterApply,
  handleRemoveItemFromList
) => {
  const nullHandle = (data, chained) => {
    if (!data || chained == null) {
      return "-";
    } else {
      return chained;
    }
  };

  const howMuchTimeLeft = (date) => {
    const now = moment();
    const inputDate = moment(date);
    const difference = inputDate.diff(now, "days");

    if (difference >= 0) {
      return "text-emerald-700"; // Geçmediyse
    } else if (difference > -3) {
      return "text-orange-500"; // 3 güne kadar geçtiyse
    } else {
      return "text-red-700"; // 3 günden fazla geçtiyse
    }
  };

  const togglePopup = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.34242 1.45457C6.14245 1.29672 5.85746 1.29672 5.65748 1.45457C4.558 2.32248 3.19931 2.87658 1.71658 2.98171C1.47555 2.99879 1.26866 3.17234 1.24253 3.41256C1.21439 3.6712 1.19995 3.93396 1.19995 4.20009C1.19995 7.27267 3.12459 9.89572 5.83405 10.9294C5.94089 10.9702 6.05921 10.9702 6.16605 10.9294C8.87541 9.89566 10.8 7.27266 10.8 4.20016C10.8 3.93401 10.7855 3.67122 10.7574 3.41255C10.7312 3.17234 10.5243 2.99879 10.2833 2.98171C8.80058 2.87658 7.4419 2.32248 6.34242 1.45457ZM5.99995 3.29996C6.41417 3.29996 6.74995 3.63574 6.74995 4.04996V6.14996C6.74995 6.56417 6.41416 6.89996 5.99995 6.89996C5.58574 6.89996 5.24995 6.56417 5.24995 6.14996V4.04996C5.24995 3.63574 5.58574 3.29996 5.99995 3.29996ZM5.99995 8.99996C6.33132 8.99996 6.59995 8.73133 6.59995 8.39996C6.59995 8.06858 6.33132 7.79996 5.99995 7.79996C5.66858 7.79996 5.39995 8.06858 5.39995 8.39996C5.39995 8.73133 5.66858 8.99996 5.99995 8.99996Z"
          fill="#E02424"
        />
      </svg>
    );
  };

  return {
    list: list,
    theme: {
      control: (item) => (
        <div className={"w-[20px] h-[50px]"}>
          <OrderRowAction
            actions={{
              order_id: item?.id,
              customer_note: item?.customer_note,
              internal_notes: item?.notes_count,
              shipping_content: item?.shipping_content,

              customer_note_enabled: item?.customer_note_enabled,
              internal_notes_enabled: item?.internal_notes_enabled,
              shipping_content_enabled: item?.shipping_content_enabled,
            }}
          />
        </div>
      ),
      status: (item) => (
        <div
          className={
            "w-full h-[64px] flex flex-col gap-1 justify-center items-center relative"
          }
        >
          {item?.cancel_requested || item?.refund_requested ? (
            <span>
              {" "}
              <SalesChannelStatus
                cancel_requested={item?.cancel_requested}
                refund_requested={item?.refund_requested}
              />{" "}
            </span>
          ) : (
            ""
          )}
          <OrderStatus
            isAwaiting={isAwaiting}
            statusId={item?.order_status_id}
            color={item?.order_status_color_code}
            text={item?.order_status}
            item={{
              product_connected: item?.order_status !== 14,
              ready_to_ship: item?.ready_to_ship,
              shipped: item?.shipped,
              is_cancelled: item?.is_cancelled,
              order: item,
            }}
            orderId={item?.id}
            orderHistory={
              <StatusHistory title="Order History" list={statusLabel(item)} />
            }
            callBack={callBack}
          />
        </div>
      ),
      "order date": (item) => (
        <div>
          {item?.order_date ? (
            <div className="  flex-col justify-start gap-1.5 h-[64px] py-2  items-start whitespace-nowrap inline-flex w-[140px] ">
              <div className="self-stretch justify-start items-center gap-0.5 inline-flex">
                <div className="text-gray-900 text-sm font-normal leading-[14px] whitespace-nowrap">
                  {moment(nullHandle(item, item?.order_date)).format(
                    "MMM D, YYYY"
                  )}
                </div>
              </div>
              <div className="self-stretch justify-between items-center gap-4 inline-flex">
                <div className="text-gray-600 text-xs font-normal leading-[18px] whitespace-nowrap">
                  {moment(nullHandle(item, item?.order_date)).format("h:mm a")}
                </div>
                <div
                  className={`${howMuchTimeLeft(
                    item?.ship_by_date
                  )} text-xs font-medium leading-[18px] whitespace-nowrap`}
                >
                  {moment(item?.order_date).fromNow() === "a few seconds ago"
                    ? "Just now"
                    : howLongFromNow(item?.order_date, true)}
                </div>
              </div>
            </div>
          ) : (
            <div className=" flex-col justify-center !px-[8px] !py-[12px] items-start gap-1 inline-flex">
              -
            </div>
          )}
        </div>
      ),
      "store & order id": (item) => (
        <div
          className={"flex flex-col gap-[0px] relative justify-start  w-full"}
        >
          <OrderId
            callBack={callBack}
            orderId={item.id}
            order_numbers={item.order_numbers}
            order={{
              order_number: item?.order_numbers[0],
              marketplace: item?.sales_channel_account?.channel?.marketplace,
              ...item?.sales_channel_account,
            }}
            item={item}
            onFilterApply={onFilterApply}
          />
        </div>
      ),

      // image: (item) => (

      // ),

      item: (item) => {
        let imageSrc;
        let isImageMagnifier = false;

        if (item?.items_count === 1) {
          if (item?.first_item?.image_url) {
            imageSrc = item.first_item.image_url;
            isImageMagnifier = true;
          } else {
            imageSrc = "/assets/images/noImage.png";
          }
        } else if (!item?.first_item) {
          imageSrc = "/assets/images/noImage.png";
        } else {
          imageSrc = "/assets/images/multiproduct.png";
        }
        return (
          <div
            className={"w-[100%]  flex flex-row   gap-1 justify-start"}
            style={{ width: "100%" }}
          >
            <div
              className={"w-[56px] h-[64px] justify-center items-center flex"}
            >
              {isImageMagnifier ? (
                <ImageMagnifier src={imageSrc} className={"!max-h-[56px]"} />
              ) : (
                <img alt="s" src={imageSrc} className={"h-[52px] w-[52px]"} />
              )}
            </div>
            <Item
              product={item?.order_items?.filter(
                (item) => +item?.unit_price >= 0
              )}
              callBack={handleRemoveItemFromList}
              orderId={item?.id}
              order={item}
              onFilterApply={onFilterApply}
            />
          </div>
        );
      },

      qty: (item) => {
        let color;

        if (item?.items_total_qty <= 1) {
          color = "gray";
        } else if (item?.items_total_qty <= 5) {
          color = "yellow";
        } else {
          color = "red";
        }
        return (
          <div className={"flex"}>
            <NumberPriorityColor
              number={item?.items_total_qty}
              color={color}
              className={"text-[14px] leading-[21px]"}
            />
          </div>
        );
      },
      weight: (item) => {
        const orderStatusId = item?.order_status_id || null;
        const billableWeight = item?.billable_weight
          ? (+item.billable_weight).toFixed(2)
          : "-";
        const actualWeight = item?.actual_weight
          ? (+item.actual_weight).toFixed(2)
          : "-";
        const dimWeight = item?.dim_weight
          ? (+item.dim_weight).toFixed(2)
          : "-";
        const orderId = item?.id || null;

        // selected ağırlık türünü belirlemek için bağımsız bir ifade
        let selectedWeight;
        if (+actualWeight > +dimWeight) {
          selectedWeight = "actual";
        } else if (+actualWeight < +dimWeight) {
          selectedWeight = "dimensional";
        } else {
          selectedWeight = "actual";
        }
        return (
          <div className={"flex flex-col gap-[0px]"}>
            <Weight
              order_status_id={orderStatusId}
              weight={{
                billable: billableWeight,
                actual: actualWeight,
                dim: dimWeight,
                selected: selectedWeight,
                orderId: orderId,
              }}
            />
          </div>
        );
      },
      profit: (item) => (
        <div className={"flex flex-1 justify-end"}>
          {item?.type !== "MANUAL" ? (
            <Profit
              profit={{
                margin: item?.profit_margin,
                profit: item?.profit,
                currency: item?.sales_channel_account?.currency,
                statCalculator: item?.profit_calculations,
                profit_color_code: item?.profit_color_code,
                has_anemic_item: item?.has_anemic_item,
                total: item?.order_total_paid
              }}
            />
          ) : (
            <div
              className={
                "flex  items-center gap-[2px] justify-center w-full flex-1"
              }
            >
              {item?.has_anemic_item && (
                <PopupUp
                  toggle={togglePopup}
                  action={"hover"}
                  innerClassName={"max-w-[300px] break-words "}
                >
                  <div
                    className={
                      "text-gray-900 font-medium text-[12px] leading-normal"
                    }
                  >
                    This order has items whose supply cost is close to the
                    selling price, it may be loss-making in partial. Please
                    check before supplying/shipping.{" "}
                  </div>
                </PopupUp>
              )}{" "}
              <span className="text-indigo-700 font-bold text-[24px]">-</span>
            </div>
          )}
        </div>
      ),
      "ship to": (item) => (
        <div className={"flex flex-col gap-[8px] relative !min-w-[141px]"}>
          <ShipTo
            order={item}
            state={item?.ship_to_state}
            name={item?.ship_to_name}
            city={item?.ship_to_city}
            country={item?.ship_to_country}
            customerOrderCount={item?.customer_order_count}
            callBack={callBack}
            order_address_id={item?.order_address_id}
          />
        </div>
      ),

      "tracking id": (item) => (
        <div className={"flex  justify-start !w-full flex-1"}>
          <ShipmentLabel
            shipment={{
              shipment_labels: item?.shipment_labels,
              suggested_shipping_cost: item?.suggested_shipping_cost,
            }}
            orderItem={item}
            callBack={callBack}
            updateRow={updateRow}
          />
        </div>
      ),
    },
  };
};
export default tableView;
