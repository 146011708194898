import React from "react";
import upperCaseString from "../../dynamic-page/util/upperCaseString";
import util from "../../dynamic-page/util";
import descriptiveContent from "../../../utils/descriptiveContent";
import { HiExternalLink } from "react-icons/hi";

const orderId = ({ order, callBack, orderId, country, offer }) => {
  return (
    <div className={"justify-start w-full"}>
      <div className={"flex flex-row gap-[4px] items-center text-gray-900  "}>
        {order?.country?.flag && (
          <util.popUp
            toggle={(selected) =>
              descriptiveContent(
                <img
                  alt="flag"
                  src={order?.country?.flag}
                  className={"h-[14px] w-[19.6px] rounded-[2px]"}
                  style={{ objectFit: "cover" }}
                />,
                order?.country?.name
              )
            }
            action={"hover"}
          >
            <div className={"px-[8px] py-[4px]"}> {order?.country?.name} </div>
          </util.popUp>
        )}

        {order?.marketplace && (
          <img
            alt="logo"
            className="h-[14px] w-[14px] object-cover"
            src={order?.marketplace?.logo}
          />
        )}
        {order?.account && (
          <span
            className={
              "text-[14px] leading-[14px] text-ellipsis text-[#111928] items-center flex"
            }
          >
            {upperCaseString(order?.account)}
          </span>
        )}
      </div>

      <div
        className={
          " whitespace-no-wrap text-[#1A56DB] text-[12px] gap-1 cursor-pointer items-center flex flex-row "
        }
      >
        <button
          className="cursor-pointer"
          onClick={() => {
            callBack(offer);
          }}
        >
          {descriptiveContent(order?.order_number, "Identifier")}
        </button>
        <div className="mt-1">
          <util.copyText
            className={"text-[12px] hover:text-gray-500"}
            text={order?.order_number}
            hint={false}
          />
        </div>
        <HiExternalLink
          className="w-4 h-4 text-gray-300 hover:text-gray-500 cursor-pointer"
          onClick={() => {
            window.open(`${order?.url}/gp/product/${order?.order_number}`, "_blank");
          }}
        />
      </div>
    </div>
  );
};
export default orderId;
