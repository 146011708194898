// api.js
import axios from "../../middleware/axios";
import constants from "../../constant";

export const fetchRows = async (params, signal) => {
  let url = constants.APIURL.API_CONSTANT.concat(params.url);
    if (params?.query) url = url.concat(params.query);
    try {
        return await axios.get(url, {
          withCredentials: true,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
          signal: signal, // Pass the signal here
        });
    } catch (error) {
        if (error.name === 'AbortError') {
            // You might want to throw an error here or handle it appropriately
        } else {
            console.error('Error:', error);
            throw error; // Propagate the error if it's not an AbortError
        }
    }
};

export const fetchFilters = async (params, signal) => {
  let url = constants.APIURL.API_CONSTANT.concat(params.url);
  if (params?.query) url = url.concat(params.query);
  return await axios.get(url, {
    withCredentials: true,
    signal: signal, // Pass the signal here
  });
};

export default { fetchRows, fetchFilters };
