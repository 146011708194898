const createFormDataFromJSON = (data, includeEmpty) => {
  if (data === null || data === undefined) {
    return new FormData();
  }

  const formData = new FormData();

  const appendFormData = (data, currentKey = "") => {
    if (data === null || data === undefined) {
      formData.append(currentKey, null);
      return; // Skip null or undefined values
    }

    //data instanceof File
    if (data instanceof Blob) {
      formData.append(currentKey, data);
    } else if (typeof data === "object") {
      Object.entries(data).forEach(([key, value]) => {
        const nestedKey = currentKey ? `${currentKey}[${key}]` : key;
        if (value) {
          appendFormData(value, nestedKey);
        } else if (includeEmpty?.includes(key)) {
          formData.append(nestedKey, "");
        }
      });
    } else {
      formData.append(currentKey, data);
    }
  };

  appendFormData(data);
  return formData;
};

export default createFormDataFromJSON;
