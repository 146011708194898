const sortView = ({callback}) => {
    return {
        list: [
            {
                label: "Updated At",
                value: "updated_at",
                onClick: (item) => {
                    callback("updated_at");
                },
            },

            // add for created_at review_counts and rating
            {
                label: "Created At",
                value: "created_at",
                onClick: (item) => {
                    callback("created_at");
                },
            },
            {
                label: "Review Counts",
                value: "review_counts",
                onClick: (item) => {
                    callback("review_counts");
                },
            },
            {
                label: "Rating",
                value: "rating",
                onClick: (item) => {
                    callback("rating");
                },
            },
            {
                label: "Variant Count",
                value: "variant_count",
                onClick: (item) => {
                    callback("variant_count");
                },
            },
            {
                label: "Last 7d Sales",
                value: "last_7_days_sales",
                onClick: (item) => {
                    callback("last_7_days_sales");
                },
            },
            {
                label: "Last 7d Revenue",
                value: "last_7_days_revenue",
                onClick: (item) => {
                    callback("last_7_days_revenue");
                },
            },
            {
                label: "Last 30d Sales",
                value: "last_30_days_sales",
                onClick: (item) => {
                    callback("last_30_days_sales");
                },
            },
            {
                label: "Last 30d Revenue",
                value: "last_30_days_revenue",
                onClick: (item) => {
                    callback("last_30_days_revenue");
                },
            },
            {
                label: "Buybox Price",
                value: "buybox_price",
                onClick: (item) => {
                    callback("buybox_price");
                },
            },
            {
                label: "Lowest Price",
                value: "lowest_price",
                onClick: (item) => {
                    callback("lowest_price");
                },
            },
            {
                label: "Total Offers Count",
                value: "total_offers_count",
                onClick: (item) => {
                    callback("total_offers_count");
                },
            },
            {
                label: "Sales Rank",
                value: "sales_rank",
                onClick: (item) => {
                    callback("sales_rank");
                },
            },
            {
                label: "FC Own Offer Count",
                value: "fba_offers_count",
                onClick: (item) => {
                    callback("fba_offers_count");
                },
            },
            {
                label: "Available Quantity",
                value: "available_quantity",
                onClick: (item) => {
                    callback("available_quantity");
                },
            },
            {
                label: "Reserved Quantity",
                value: "reserved_quantity",
                onClick: (item) => {
                    callback("reserved_quantity");
                },
            },
            {
                label: "Inbound Quantity",
                value: "inbound_quantity",
                onClick: (item) => {
                    callback("inbound_quantity");
                },
            },
            {
                label: "Available WH Quantity",
                value: "fbm_available_quantity",
                onClick: (item) => {
                    callback("fbm_available_quantity");
                },
            },
            {
                label: "WH Own Offer Count",
                value: "fbm_offers_count",
                onClick: (item) => {
                    callback("fbm_offers_count");
                },
            },
        ],
    };
};

export default sortView;
