import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiRefreshCw } from "react-icons/fi";

import DynamicTable from "../dynamic-page";
import util from "../dynamic-page/util";
import ConfirmModal from "../dynamic-page/util/confirmModal";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";

import filtersView from "./modals-view/filters";
import tableView from "./modals-view/table";
import sortView from "./modals-view/sort";
import zplPrint from "./../print-page/zpl";
// import formView from "./modals-view/form";
// ------------------------------
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import SearchInput from "./../dynamic-page/debt/searchInput";
// ------------------------------
import getActionList from "../dynamic-page/util/getActionList";

import {
  fetchShipments as fetchData,
  fetchShipmentsFilters as fetchFilters,
  markAsDelivered,
  markAsVoided,
  refreshShipments,
  updateBoundFilter,
  updateFilter,
  updateRow,
} from "../../store/shipments";
import FooterPagination from "../dynamic-page/elements/FooterPagination";
import TabButton from "../dynamic-page/elements/TabButton";
import {
  closeNotification,
  startNotification,
} from "../dynamic-page/util/notifierLive";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import useLoadMoreData from "../../utils/useLoadMoreData";
import { useLocation } from "react-router-dom";
import Button from "../dynamic-form/elements/Button";

const Shipments = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  // Initial page at first load.
  useEffect(() => {
    const startPageByFilters = {
      page: 1,
      limit: 10,
      search: "",
      includes: "shippable,internationalCarrier,fromState.country",
    };
    const storeQuery = storeQueryBuilder(startPageByFilters, urlQueryLoader());
    const urlQuery = urlQueryBuilder(storeQuery, null, "term", true);

    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(updateBoundFilter(mappedFilters));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters(urlQuery));
  }, [dispatch, location]);

  // Get data from store.
  const pageData = useSelector((state) => state.shipments);

  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    fetchData,
  });

  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState(null);

  // Handle form modal.
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(null);

  // handle refreshing
  const [refreshing, setRefreshing] = useState(false);

  // Handle order details modal.

  const giveSelectedItemId = (item) => {
    const orderId = item ? [item.id] : selectedRow.map((item) => item.id);
    setSelectedRow([]);
    return orderId;
  };

  const sortList = sortView({
    callback: (value) => {
      onSortApply(value);
    },
  });

  const handleUpdateSingleRow = (orderId) => {
    // get the matching shipment
    const matchedRow = pageData.shipments.find((item) => item.id === orderId);
    dispatch(
      updateRow({
        path: `shipments.[id:${orderId}]`,
        value: { ...matchedRow, _updatedRow: true },
      })
    );
  };

  const handleRemoveItemFromList = (orderId) => {
    const orderItemId = giveSelectedItemId(orderId);
    orderItemId.forEach((item) => {
      handleUpdateSingleRow(item);
      if (pageData?.filters?.filters?.current_status_id !== null) {
        setTimeout(() => {
          dispatch(
            updateRow({
              path: `shipments.[id:${item}]._deletedRow`,
              value: true,
            })
          );
          setTimeout(() => {
            dispatch(updateRow({ path: `shipments.[id:${item}]` }));
          }, 1000);
        }, 3000);
      }
    });
  };

  // Initial action list
  const actionList = {
    printLabel: {
      label: "Print Label",
      // bulkDisable: true,
      onClick: (item) => {
        const fileUrl = [item?.file];
        // const fileUrl = result?.payload?.data?.data?.map(
        //   (item) => item?.label_file_png
        // );
        fileUrl.forEach((item) => {
          zplPrint.getAndSendFileToPrinter(item);
        });
      },
      condition: (data) => false,
    },
    viewLabel: {
      label: "View Label",
      bulkDisable: true,
      onClick: (item) => {
        const fileUrl = [item?.file];
        util.pdfViewer(fileUrl, false, `Shipment Label`);
      },
      condition: false,
    },
    markAsDilivered: {
      label: "Mark as Delivered",
      onClick: (item) => {
        const ids = giveSelectedItemId(item);
        // setCalledItemInAction(ids)
        setConfirmationModalVisibility({
          show: true,
          title: "Mark Shipment/s as Delivered?",
          onClose: () => setConfirmationModalVisibility(false),
          confirmText: "Yes, Mark as Delivered",
          confirmColor: "green",
          cancelText: "Cancel",
          cancelColor: "red",
          styles: {
            confirmButton: "!bg-green-50 ",
            cancelButton: "!bg-red-50 ",
            infoText: "!font-medium",
          },
          onConfirm: (setSubmit) => {
            // console.log('111111');
            let a = startNotification({
              title: `Marking ${ids?.length} Shipment/s as Delivered`,
            });
            dispatch(markAsDelivered({ ids }))
              .unwrap()
              .then((rs) => {
                closeNotification(a, {
                  toast: {
                    type: rs.type === "success" ? "success" : "error",
                    message: rs.message,
                    errors: rs.errors,
                  },
                });
                setSubmit(false);
                if (rs.type === "success") {
                  setConfirmationModalVisibility(null);
                  dispatch(
                    fetchFilters(
                      urlQueryBuilder(pageData?.filters, null, "term")
                    )
                  );
                  if (ids?.length === 1) handleRemoveItemFromList(ids[0]);
                  else onPaginationApply();
                }
              });
          },
        });
      },
      condition: (item) => item?.current_status !== "Delivered",
    },
    markAsVoided: {
      label: "Mark as Voided",
      onClick: (item) => {
        const ids = giveSelectedItemId(item);
        setConfirmationModalVisibility({
          show: true,
          title: "Mark Shipment/s as Voided?",
          onClose: () => setConfirmationModalVisibility(false),
          confirmText: "Yes, Mark as Voided",
          confirmColor: "green",
          cancelText: "Cancel",
          cancelColor: "red",
          styles: {
            confirmButton: "!bg-green-50 ",
            cancelButton: "!bg-red-50 ",
            infoText: "!font-medium",
          },
          onConfirm: (setSubmit) => {
            // console.log('111111');
            let a = startNotification({
              title: `Marking ${ids?.length} Shipment/s as Voided`,
            });
            dispatch(markAsVoided({ ids }))
              .unwrap()
              .then((rs) => {
                closeNotification(a, {
                  toast: {
                    type: rs.type === "success" ? "success" : "error",
                    message: rs.message,
                    errors: rs.errors,
                  },
                });
                setSubmit(false);
                if (rs.type === "success") {
                  setConfirmationModalVisibility(null);
                  dispatch(
                    fetchFilters(
                      urlQueryBuilder(pageData?.filters, null, "term")
                    )
                  );
                  if (ids?.length === 1) handleRemoveItemFromList(ids[0]);
                  else onPaginationApply();
                }
              });
          },
        });
      },
      condition: (item) => item?.current_status !== "Voided",
    },
  };
  const handleCallBackActions = ({ action, data }) => {
    actionList[action].onClick(data);
  };

  const statusList = {
    1: {
      label: "Label created",
      actions: [actionList.markAsDilivered, actionList.markAsVoided],
    },
    5: {
      label: "Delivered",
      actions: [actionList.markAsVoided],
    },
    4: {
      label: "In transit",
      actions: [actionList.markAsDilivered, actionList.markAsVoided],
    },
    10: {
      label: "Shipped",
      actions: [actionList.markAsDilivered, actionList.markAsVoided],
    },
    3: {
      label: "Exception",
      actions: [actionList.markAsDilivered, actionList.markAsVoided],
    },
    6: {
      label: "Voided",
      actions: [actionList.markAsDilivered, actionList.markAsVoided],
    },
  };
  //   };

  // handle actions of statuses
  //
  const actions = (row) => {

    return getActionList(row, statusList, "current_status_id");
    // return []
  };

  const handleRefreshListings = async () => {
    const ids = selectedRow?.map((item) => {
      return item?.id;
    });

    if (ids) {
      try {
        setRefreshing(true);

        let a = startNotification({
          title: `Refreshing ${ids?.length} Shipments`,
        });
        dispatch(refreshShipments({ ids }))
          .unwrap()
          .then((rs) => {
            setRefreshing(false);
            // util.notifier({type : rs.type , message: rs.message});
            closeNotification(a, {
              toast: {
                type: rs.type === "validation_error" ? "error" : rs.type,
                message: rs.message,
                errors: rs.errors,
              },
            });
            if (rs.type === "success") {
              ids.map((id) => {
                dispatch(
                  updateRow({
                    path: `shipments.[id:${id}]._updatedRow`,
                    value: true,
                  })
                );
              });

              // loop on ids and remove the _updatedRow property from the rows after 2 seconds
              ids.map((id) => {
                setTimeout(() => {
                  dispatch(
                    updateRow({
                      path: `shipments.[id:${id}]._updatedRow`,
                      value: false,
                    })
                  );
                }, 3000);
              });
            }

            // setRefreshingDate (rs.meta.timestamp)
          });

        setSelectedRow(null);
      } catch (error) {
        // Handle errors if the refresh fails
        console.error("Error refreshing account listings:", error);
      }
    } else {
      util.notifier({ message: "Please Select at Least One Row" });
      console.error("No identifier found in the selected row.");
    }
  };

  // Handle Tabs
  const onHandleTab = (item) => {
    onFilterApply({ current_status_id: item });
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onFilterApply = (filters, bound) => {
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateBoundFilter({ filters, bound }));

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    let changeSort;


    if (sort === currentStoreFilters?.sort) {
      changeSort = null;
    } else if (currentStoreFilters?.sort === `-${sort}`) {
      // Check if the store's sort is the reverse of the current sort
      changeSort = sort;
    } else {
      // Otherwise, set it to the reverse of the current sort
      changeSort = `-${sort}`;
    }

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow(null);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      limit: limit || currentStoreFilters.limit || 10,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    await dispatch(fetchFilters(urlQuery));
    await dispatch(fetchData(urlQuery));
  };

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  //   const handleCallBackActions = ({ action, data }) => {
  //     actionList[action].onClick(data);
  //   };

  // Extract the filter count
const filterCount = Object.values(pageData?.filters?.filters ?? {}).filter(
  (item) => item !== null && item !== ""
).length;

// Determine if current_status_id is defined and not null
const hasCurrentStatusId = 
  pageData?.filters?.filters?.current_status_id !== undefined && 
  pageData?.filters?.filters?.current_status_id !== null;

// Calculate the adjustment value based on the presence of current_status_id
const adjustment = hasCurrentStatusId ? 1 : 0;
const miniWidth = (filterCount - adjustment) === 0 ? "200" : "250";
  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        {/* Page Title, and buttons of Add,Filter,Sort,Action are here. */}
        <ArbitProductPage.Title
          title={"Shipments"}
          //   form={{
          //     callback: (rs) => {
          //       setModalVisibility(true);
          //     },
          //   }}
          sort={{
            items: sortList,
            selected: pageData?.filters?.sort,
          }}
          filter={{
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            discard: ["current_status_id"],
            items: filtersView(pageData?.filters?.filters),
            callback: (rs, bound) => {
              onFilterApply(rs, bound);
            },
            count:
              Object.values(pageData?.filters?.filters ?? {}).filter(
                (item) => item !== null && item !== ""
              ).length -
              (pageData?.filters?.filters?.current_status_id === undefined ||
              pageData?.filters?.filters?.current_status_id === null
                ? 0
                : 1),
          }}
          actionList={actions(selectedRow)}
          options={[
            {
              callBack: handleRefreshListings,

              data: refreshing,
              view: ({ callBack, data }) => (
                <Button
                  item={{
                    label: (
                      <div
                        className={`flex flex-row items-center justify-center gap-[4px] `}
                      >
                        <div className={"flip-x transform scale-x-[-1] "}>
                          <FiRefreshCw
                            className={`h-[18px] w-[18px] ${
                              data ? "animate-spin rotate-[reverse]" : ""
                            }`}
                          />
                        </div>
                      </div>
                    ),
                    buttonType: "icon",
                    className: "!min-w-[18px] !h-[28px] ",
                  }}
                  // onClick={handleRowClick}
                  onClick={() => {
                    if (!data) {
                      callBack();
                    }
                  }}
                />
              ),
              condition: () => selectedRow?.length > 0,
            },
          ]}
        />

        {/* Tabs and search input are here. */}
        <div
          className={
            "flex flex-row justify-between px-[16px] py-[4px] items-center"
          }
        >
          <SearchInput
            className="w-[362px]"
            placeholder="Search..."
            onSubmit={onSearchApply}
            defaultValue={pageData?.filters?.search}
          />
          <TabButton
            callBack={onHandleTab}
            active={pageData?.filters?.filters?.current_status_id}
            list={[
              {
                id: 3,
                name: "Exception",
                count:
                  pageData?.filtersMeta?.shipment_statuses?.find(
                    (item) => item?.name === "Exception"
                  )?.count || 0,
              },
              {
                id: 6,
                name: "Voided",
                count:
                  pageData?.filtersMeta?.shipment_statuses?.find(
                    (item) => item?.name === "Voided"
                  )?.count || 0,
              },
              {
                id: 9,
                name: "Returned",
                count:
                  pageData?.filtersMeta?.shipment_statuses?.find(
                    (item) => item?.name === "Returned"
                  )?.count || 0,
              },
              {
                id: 1,
                name: "Label Created",
                count:
                  pageData?.filtersMeta?.shipment_statuses?.find(
                    (item) => item?.name === "Label created"
                  )?.count || 0,
              },
              {
                id: 4,
                name: "In Transit",
                count:
                  pageData?.filtersMeta?.shipment_statuses?.find(
                    (item) => item?.name === "In transit"
                  )?.count || 0,
              },
              {
                id: 5,
                name: "Delivered",
                count:
                  pageData?.filtersMeta?.shipment_statuses?.find(
                    (item) => item?.name === "Delivered"
                  )?.count || 0,
              },
            ]}
            allTab={true}
            allCount={pageData?.filtersMeta?.shipment_statuses?.reduce(
              (sum, obj) => sum + obj.count,
              0
            )}
          />
        </div>

        {/* Table is here. */}
        <ArbitProductPage.Content>
          <div className="">
            <div className="overflow-x-auto max-w-[100%]">
              <DynamicTable
                view={tableView}
                data={tableView(
                  pageData.shipments,
                  handleCallBackActions
                  // data
                )}
                mini={true}
                miniWidth={miniWidth}
                actions={actions}
                onSelect={onSelectRow}
                selectedRow={selectedRow}
                meta={pageData?.meta}
                appending={loading}
                loadMoreData={loadMoreData}
                loading={pageData.loading}
                style={{
                  table: {
                    style: { width: "100%", minWidth: "100%" },
                  },
                  header: {
                    className: "justify-center !px-[16px] whitespace-nowrap",
                    "tracking id": { className: "!pr-[30px]" },
                    image: { className: "hidden" },
                    type: { className: "!py-[0px] hidden" },
                    secret: { className: "!py-[0px] hidden" },
                    notes: { className: "!py-[0px] hidden" },
                  },
                  row: {
                    className:
                      "!px-[12px] !py-[0px] !items-center !justify-center flex text-[12px] !h-[64px] ",
                    secret: { td: "!w-[100%]" },
                    status: { className: "!px-[8px] !pr-[24.5px]" },
                    "tracking id": { className: "!px-[8px] " },
                    identifier: { className: "!pr-[24px]" },
                    "created at": { className: "!pr-[24px]" },
                    _actions: { className: "!py-[0px]" },
                  },
                }}
              />
            </div>
          </div>
        </ArbitProductPage.Content>

        {/* Pagination is here. */}
        <FooterPagination
          meta={pageData?.meta}
          pageData={pageData}
          loading={pageData.loading}
          onLimit={onHandleLimit}
        />
      </ArbitProductPage.Page>

      {confirmationModalVisibility?.show && (
        <ConfirmModal
          show={confirmationModalVisibility?.show}
          infoText={confirmationModalVisibility?.title}
          onClose={() => setConfirmationModalVisibility(null)}
          confirmText={confirmationModalVisibility?.confirmText}
          confirmColor={confirmationModalVisibility?.confirmColor}
          cancelText={confirmationModalVisibility?.cancelText}
          cancelColor={confirmationModalVisibility?.cancelColor}
          styles={{
            confirmButton: confirmationModalVisibility?.styles?.confirmButton,
            cancelButton: confirmationModalVisibility?.styles?.cancelButton,
            infoText: confirmationModalVisibility?.styles?.infoText,
          }}
          onConfirm={confirmationModalVisibility?.onConfirm}
        />
      )}
    </>
  );
};

export default Shipments;
