// Handle Pagination

import { useDispatch, useSelector } from "react-redux";
import Page from "./../Page";
import React, { useEffect, useRef, useState } from "react";

import storeQueryBuilder from "./storeQueryBuilder";
import urlQueryLoader from "./urlQueryLoader";
import urlQueryBuilder from "./urlQueryBuilder";
import {
  fetchFilters,
  fetchRows,
  setTitle,
  updateBoundFilter,
  updateFilter,
  updateRow,
} from "../../../store/dynamic-page";
import useLoadMoreData from "../../../utils/useLoadMoreData";
import { useLocation } from "react-router-dom";

const usePage = ({
  searchType = "term",
  pageTitle,
  api,
  ModelsView,
  pageSettings,
}) => {
  const dispatch = useDispatch();
  const pageData = useSelector((state) => state.dynamicPage);

  const currentFetchPromises = useRef({});

  const setLoadMoreDataPromise = (promise) => {
    currentFetchPromises.current.loadMoreDataPromise = promise;
  };
    
  const [innerApi, setInnerApi] = useState(api);
  const [innerModelsView, setInnerModelsView] = useState(ModelsView);

  const fetchList =  (storeQuery, reset, mapped) => {
    const urlQuery = urlQueryBuilder(storeQuery, null, searchType, reset);
    if (mapped) {
      dispatch(updateBoundFilter(mapped));
    }
    dispatch(updateFilter(storeQuery));
    const fetchRowsPromise = dispatch(
      fetchRows({
        url: innerApi.fetch,
        query: api?.query ? `${urlQuery}&${api.query}` : urlQuery,
        title: pageTitle,
      })
    );
      
    let newQuery = "";
    if (pageSettings?.ignoredFilters) {
      pageSettings.ignoredFilters.forEach((filter) => {
        newQuery = urlQuery.replace(
          `&filter[${filter}]=${storeQuery.filters[filter]}`,
          ""
        );
      });
    }

      let fetchFiltersPromise;
    if (api.filters)
        fetchFiltersPromise = dispatch(
            fetchFilters({
              url: innerApi.filters
                ? innerApi.filters
                : innerApi.fetch.concat("/filters"),
              query: pageSettings?.ignoredFilters ? newQuery : urlQuery,
              title: pageTitle,
            })
        )
      

      return { fetchRowsPromise, fetchFiltersPromise };

  };

    
    const location = useLocation();
    
  useEffect(() => {
    // RESET THE path
    urlQueryBuilder({}, null, searchType, true);
    const selectedTab = ModelsView?.tabs
      ? Object.entries(ModelsView?.tabs(pageData))?.filter(
          ([key, value]) => value.active === true
        )[0]
      : null;
    const startPageByFilters = {
      search: "",
      sort: api.defaultSort || null,
      page: 1,
      limit: 10,
      filters: {
        ...(ModelsView?.tabs && selectedTab[1]?.filter),
        ...(pageSettings?.product?.filter && {
          ...pageSettings.product?.filter,
        }),
      },
      tab: selectedTab ? selectedTab[0] : null,
    };
    const storeQuery = storeQueryBuilder(
      startPageByFilters,
      urlQueryLoader(startPageByFilters)
    );

    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(setTitle(pageTitle));
      const fetchPromises = fetchList(storeQuery, true, mappedFilters);
      
    currentFetchPromises.current = fetchPromises;

      return () => {
          if (currentFetchPromises.current.fetchRowsPromise?.abort) {
          currentFetchPromises.current.fetchRowsPromise.abort();
        }
        if (currentFetchPromises.current.fetchFiltersPromise?.abort) {
          currentFetchPromises.current.fetchFiltersPromise.abort();
        }
          
          if (currentFetchPromises.current.loadMoreDataPromise?.abort) {
            currentFetchPromises.current.loadMoreDataPromise.abort();
          }
      };
  }, [location, pageTitle]);

  const fetchData = (params) =>
    fetchRows({
      url: innerApi.fetch,
      ...params,
      query: api?.query ? params.query + "&" + api.query : params.query,
        title: pageTitle,
    });
  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    fetchData,
    setFetchPromise: setLoadMoreDataPromise,
  });

  // Pagination Control
  const onHandlePrevious = (pageData) => {
    onPaginationApply(Number(pageData?.filters?.page || 1) - 1);
  };
  const onHandleNext = (pageData) => {
    onPaginationApply(Number(pageData?.filters?.page || 1) + 1);
  };
  const onHandlePage = (page, pageData) => {
    onPaginationApply(page);
  };
  const onHandleLimit = (limit, pageData) => {
    onPaginationApply(1, limit);
  };

  const onPaginationApply = async (page, limit) => {
    const storeQuery = storeQueryBuilder(pageData?.filters, {
      limit: limit || pageData?.filters.limit || 10,
    });
    fetchList(storeQuery);
  };

  const onSearchApply = (search) => {
    const storeQuery = storeQueryBuilder(pageData?.filters, {
      search: search,
    });
    fetchList(storeQuery);
  };

  const onFilterApply = (filters, bound, reset) => {
    const storeQuery = reset
      ? storeQueryBuilder({}, { filters: filters })
      : storeQueryBuilder(pageData?.filters, { filters: filters });
    // create a new variable that consists of the keys of the filters object and the bound object
    fetchList(storeQuery);
    dispatch(updateBoundFilter({ filters, bound }));
  };

  const refreshFilters = () => {
    const currentFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentFilters, {
      filters: {},
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, searchType);
    dispatch(
      fetchFilters({
        url: innerApi.filters
          ? innerApi.filters
          : innerApi.fetch.concat("/filters"),
        query: urlQuery,
      })
    );
  };

  const onSortApply = (sort) => {
    const changeSort =
      sort === pageData?.filters?.sort
        ? null
        : pageData?.filters?.sort === `-${sort}`
        ? sort
        : `-${sort}`;
    const storeQuery = storeQueryBuilder(pageData?.filters, {
      sort: changeSort,
    });
    fetchList(storeQuery);
  };
  const onTabApply = (tab) => {
    const tabsView = ModelsView?.tabs(pageData);
    const storeQuery = storeQueryBuilder(pageData?.filters, {
      tab: tab,
      filters: { ...(tabsView[tab].filter || { type: "" }) },
    });
    if (tabsView[tab].api) {
      setInnerApi(tabsView[tab].api);
    }
    if (tabsView[tab].ModelsView) {
      setInnerModelsView(tabsView[tab].ModelsView);
    } else {
      setInnerModelsView(ModelsView);
    }
    fetchList(storeQuery);
  };

  const refreshPage = () => {
    fetchList(
      storeQueryBuilder(pageData?.filters, {
        page: pageData?.filters?.page,
      })
    );
  };

  const handleUpdateSingleRow = (id, data) => {
    if (Array.isArray(id)) {
      id.forEach((id) => {
        const row = data
          ? data?.find((row) => row.id === id)
          : pageData?.rows?.find((row) => row.id === id);
        dispatch(
          updateRow({
            id: id,
            value: { ...row, _updatedRow: true },
          })
        );
      });
    } else {
      const row = data ? data : pageData?.rows?.find((row) => row.id === id);
      dispatch(
        updateRow({
          id: id,
          value: { ...row, _updatedRow: true },
        })
      );
    }
    // remove the updated row after 5 seconds
    setTimeout(() => {
      dispatch(
        updateRow({
          id: id,
          path: "_updatedRow",
          value: false,
        })
      );
    }, 5000);
  };

  return (
    <Page
      pageData={pageData}
      control={{
        onPaginationApply: onPaginationApply,
        onSearchApply: onSearchApply,
        onFilterApply: onFilterApply,
        onSortApply: onSortApply,
        onHandlePrevious: onHandlePrevious,
        onHandleNext: onHandleNext,
        onHandlePage: onHandlePage,
        onHandleLimit: onHandleLimit,
        onTabApply: onTabApply,
        dispatch: dispatch,
        pageSettings: pageSettings,
        replaceRow: handleUpdateSingleRow,
        loadMoreData: loadMoreData,
        loading: loading,
        refreshFilters: refreshFilters,
      }}
      pageTitle={pageTitle}
      modelsView={innerModelsView}
      refreshPage={refreshPage}
    />
  );
};

export default usePage;

// pageControl.js

export function createMappedFilters(
  storeQuery,
  boundFilterss,
  fromStore = false
) {
  let boundFilters = {};
  if (!fromStore) {
    const url = new URL(window.location.href);
    const boundFiltersString = url.searchParams.get("boundFilters");
    try {
      boundFilters = JSON.parse(decodeURIComponent(boundFiltersString || "{}"));
    } catch (error) {
      console.error("Failed to parse boundFilters from URL:", error);
      boundFilters = {};
    }
  } else {
    boundFilters = boundFilterss;
  }

  const mappedFilters = { ...storeQuery.filters };

  for (const key in boundFilters) {
    if (boundFilters.hasOwnProperty(key)) {
      mappedFilters[key] = boundFilters[key];
    }
  }

  return mappedFilters;
}
