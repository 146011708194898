// FormModal.js
// -----------------------------------------
// Create container for Normal Form Modal.

// Import general libraries
import React, { useCallback, useEffect, useRef } from "react";

// Import Elements
import MainModal from "./MainModal";
import DynamicForm from "../dynamic-form";
import Button from "../dynamic-form/elements/Button";
import PropTypes from "prop-types";

// Main Component: AddEditModal
const FormModal = React.memo(
  ({
    title,
    noPrefix,
    prefix = null,
    formView,
    data = null,
    onSubmit,
    onClose,
    container,
    createNew,
    saveText,
    params,
    headerClassName,
    buttonsContainer,
    createAnotherClassName,
    containerClassName,
    editPrefix,
  }) => {
    const myFormRef = useRef();
    const checkboxRef = useRef();

    const createNewRef = useCallback((node) => {
      checkboxRef.current = node;
    }, []);

    useEffect(() => {}, [checkboxRef.current?.checked]);

    const determineModalTitle = () => {
      if (typeof title === "object" && title !== null) {
        return title;
      }

      let baseTitle = title;
      if (!noPrefix) {
        if (!data?.id) {
          baseTitle = prefix ? `${prefix} ${title}` : `Add New ${title}`;
        } else {
          baseTitle = `${editPrefix || "Edit"} ${title}`;
        }
      }
      return baseTitle;
    };

    const determineButtonLabel = () => {
      if (saveText) {
        return data?.id ? "Save" : saveText;
      }
      return "Save";
    };

    const myFormCallBack = async () => {
      let formData = await myFormRef?.current?.getData();
      if (formData) {
        if (data) {
          formData.id = data.id;
        }
        onSubmit(formData, async () => {
          if (checkboxRef.current?.checked) {
            await myFormRef.current?.reset();
          } else {
            onClose();
          }
        });
      }
    };

    const modalTitle = determineModalTitle();
    const buttonLabel = determineButtonLabel();

    return (
      <MainModal
        containerClassName={`!pb-1 ${containerClassName || ""}`}
        headerClassName={headerClassName}
        item={{
          title: modalTitle,
          submit: myFormCallBack,
          cancel: onClose,
          view: (item) => (
            <div>
              {container ? (
                container(
                  <DynamicForm items={formView(data, params)} ref={myFormRef} />
                )
              ) : (
                <div className="min-w-[775px] grid grid-cols-2 items-start gap-[16px] w-full justify-center px-[16px] border-t border-t-gray-200 pt-[24px] mt-[10px]">
                  <DynamicForm items={formView(data, params)} ref={myFormRef} />
                </div>
              )}
              <div
                className={`flex w-full sticky bg-white bottom-0 ${
                  createNew ? "justify-between" : "justify-end"
                } items-center pt-[8px] pb-2 px-[16px] gap-[8px] ${buttonsContainer}`}
              >
                {createNew && (
                  <div
                    className={`flex items-center ${createAnotherClassName}`}
                  >
                    <input
                      type="checkbox"
                      id="scales"
                      name="scales"
                      ref={createNewRef}
                      disabled={false}
                      className={
                        "w-[16px] h-[16px] mr-[8px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 rounded-[4px] "
                      }
                    />
                    <label
                      htmlFor="scales"
                      className="text-[14px] font-medium leading-[14px]"
                    >
                      Create another
                    </label>
                  </div>
                )}
                <div className="flex flex-row gap-[8px]">
                  <Button
                    item={{
                      label: <div className={"flex flex-row"}>Cancel</div>,
                      buttonType: "alt",
                      className: "h-[41px] !w-[120px] !px-[16px]",
                    }}
                    onClick={() => onClose()}
                  />
                  <Button
                    item={{
                      label: (
                        <div className={"flex flex-row"}>{buttonLabel}</div>
                      ),
                      buttonType: "primary",
                      className: "h-[41px] !w-[120px] !px-[16px]",
                    }}
                    onClick={myFormCallBack}
                  />
                </div>
              </div>
            </div>
          ),
        }}
      />
    );
  },
  (prevProps, nextProps) => {
    return prevProps.data === nextProps.data;
  }
);

export default FormModal;
FormModal.propTypes = {
  title: PropTypes.string.isRequired,
  noPrefix: PropTypes.bool,
  prefix: PropTypes.string,
  formView: PropTypes.func.isRequired,
  data: PropTypes.object, // data için propTypes tanımı eklendi
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  container: PropTypes.func,
  createNew: PropTypes.bool,
  saveText: PropTypes.string,
  params: PropTypes.object,
  headerClassName: PropTypes.string,
  buttonsContainer: PropTypes.string,
  createAnotherClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  editPrefix: PropTypes.string,
};
