import { pages } from "./components/settings-pages";
import { Link } from "react-router-dom";

const SettingsPage = () => {
  return (
    <div className="!h-full overflow-y-auto">
      <div>
        <div className="h-[60px] flex flex-col my-3 justify-between px-5 gap-2 mx-auto">
          <span className="font-bold text-[28px] leading-[24px] text-gray-900">
            Settings
          </span>
          <span className="font-normal text-[16px] leading-[24px] text-gray-500">
            Customize, manage and do more with Arbitbox.
          </span>
        </div>
        <div
          className=" flex flex-wrap justify-center items-center px-5 lg:grid lg:px-8 mb-5"
          style={{
            gridTemplateColumns: "repeat(auto-fit, minmax(390px, 1fr))",
            gap: "8px",
          }}
        >
          {pages.map((item, index) => (
            <Link
              key={item?.name}
              to={`/${item?.category}/${item?.path}`}
              className="shadow-md border flex flex-col h-[182px]   hover:bg-gray-50   border-gray-200  gap-2 min-w-[350px]  max-w-[350px] lg:max-w-[450px]     p-[24px] rounded-[8px]"
            >
              <div className="flex justify-between items-center">
                <span className="font-semibold text-[24px] leading-[30px] text-gray-900">
                  {item?.name}
                </span>
                <span className="text-gray-500 ">{item?.icon}</span>
              </div>
              <p className=" text-justify text-gray-600 text-[16px] leading-[24px]">
                {item?.text}
              </p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
