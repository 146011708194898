/**
 * @author Redwan.j
 * @description ProductSupplyPage - a component to display supplies
 */

import { useState } from "react";
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import TabButton from "../dynamic-page/elements/TabButton";
import Supplies from "../Supplies";
import SupplyPurchases from "../supply-purchase";
import { useNavigate, useSearchParams } from "react-router-dom";
import GoodAceptances from "../goods-acceptance";
import PropTypes from "prop-types";

const ProductSupplyPage = ({ product_id }) => {
  const [searchParams] = useSearchParams();
  const tabFromURL = searchParams.get("type");
  const [currentTab, setCurrentTab] = useState(tabFromURL || "supply_requests");
  const navigate = useNavigate();

  return (
    <div>
      <ArbitProductPage.Page className="flex flex-col">
        <div
          className={
            "flex flex-row justify-end px-[16px] py-[4px] items-center"
          }
        >
          <TabButton
            callBack={(value) => {
              setCurrentTab(value);
              navigate(`/product/${product_id}?tab=Supply`);
            }}
            active={currentTab}
            list={[
              {
                id: "supply_requests",
                name: "Awaiting Purchase",
              },
              {
                id: "supply_purchases",
                name: "Awaiting Acceptance",
              },
              {
                id: "goods_acceptances",
                name: "Goods Acceptances",
              },
            ]}
          />
        </div>

        <ArbitProductPage.Content>
          {currentTab === "supply_requests" && (
            <Supplies product_id={product_id} />
          )}
          {currentTab === "supply_purchases" && (
            <SupplyPurchases product_id={product_id} />
          )}
          {currentTab === "goods_acceptances" && (
            <GoodAceptances product_id={product_id} />
          )}
        </ArbitProductPage.Content>
      </ArbitProductPage.Page>
    </div>
  );
};

ProductSupplyPage.propTypes = {
  product_id: PropTypes.string.isRequired, // Adjust type according to your expected type
};

export default ProductSupplyPage;
