import { combineReducers, configureStore } from "@reduxjs/toolkit";
import dashboard from "./dashboard";
import authReducer from "./auth";
import productReducer from "./product";
import listingMatchReducer from "./listing-match";
import opportunityReducer from "./opportunities";
import targetSellerListingsReducer from "./target-seller-listings";
import shippingtemplateReducer from "./shippingtemplate";
import shippingcostlistReducer from "./shippingcostlist";
import offersReducer from "./offers";
import salesListingReducer from "./sales-listings";
import channelsReducer from "./channels";
import accountsReducer from "./accounts";
import usersReducer from "./users";
import rolesReducer from "./positions";
import PermissionReducer from "./permissions";
import shippingpricinglistReducer from "./shippingpricinglist";
import ProductsReducer from "./products";
import supplyListings from "./supply-listings";
import categories from "./categories";
import supplyRequests from "./supplies";
import authUser from "./auth-user";
import bankAccountsReducer from "./bank-accounts";
import currencies from "./currencies";
import brands from "./brands";
import paymentMethods from "./payment-methods";
import balance from "./balance";
import supplyPurchase from "./supply-purchase";
import vatRates from "./vat-rate";
import purchasePayments from "./purchase-payments";
import goodsAcceptance from "./goods-acceptance";
import goodsAcceptanceUpdate from "./goods_acceptance_update";
import mainInventory from "./main-inventory";
import units from "./units";
import notifications from "./notifications";
import inventory from "./inventory";
import localeReducer from "./locale";
import languageReducer from "./language";
import orderReducer from "./orders";
import calculatorReducer from "./calculator";

import dynamicPage from "./dynamic-page";
import fcRestocking from "./fc-restocking";
import returns from "./returns";

import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import warehouses from "./warehouses";
import attributes from "./attributes";
import carrier from "./carrier";
import carrier_service from "./carrier_service";
import shippingPrices from "./shipping-price";
import shippingCostListUpdate from "./shipping-cost-list";
import shippingTemplate from "./shipping-template";
import targetSellerFolder from "./target-seller-folder";
import shipments from "./shipments";
import orderNotifications from "./order-notifications";
import toastNotificationReducer from "./notifications/index";

import supplyChannels from "./supply-channels";
import supplyChannelListings from "./supply-channel-listings";
import shippingCostReducer from "./shipping-cost-page";
import pickupPoints from "./pickup-points";
import transfers from "./transfers";
const loadingReducer = (state = false, action) => {
  switch (action.type) {
    case "START_LOADING":
      return true;
    case "STOP_LOADING":
      return false;
    default:
      return state;
  }
};

const reducers = combineReducers({
  dashboard: dashboard,
  auth: authReducer,
  product: productReducer,
  listingMatch: listingMatchReducer,
  opportunity: opportunityReducer,
  targetSellerListings: targetSellerListingsReducer,
  shippingTemplate: shippingtemplateReducer,
  shippingCostList: shippingcostlistReducer,
  shippingPricingList: shippingpricinglistReducer,
  offers: offersReducer,
  salesListing: salesListingReducer,
  channel: channelsReducer,
  account: accountsReducer,
  user: usersReducer,
  role: rolesReducer,
  permission: PermissionReducer,
  productReducer: ProductsReducer,
  supplyListings: supplyListings,
  categories: categories,
  supplyRequests: supplyRequests,
  authUser: authUser,
  bankAccounts: bankAccountsReducer,
  currencies: currencies,
  brands: brands,
  paymentMethods: paymentMethods,
  balance: balance,
  supplyPurchase: supplyPurchase,
  vatRates: vatRates,
  carriers: carrier,
  purchasePayments: purchasePayments,
  inventory: inventory,
  goodsAcceptance: goodsAcceptance,
  goodsAcceptanceUpdate: goodsAcceptanceUpdate,
  mainInventory: mainInventory,
  units: units,
  notifications: notifications,
  warehouses: warehouses,
  locale: localeReducer,
  languages: languageReducer,
  attributes: attributes,
  orders: orderReducer,
  carrierServices: carrier_service,
  shippingPrices: shippingPrices,
  shippingCostListUpdate: shippingCostListUpdate,
  shippingTemplates: shippingTemplate,

  loading: loadingReducer,
  targetSellerFolder: targetSellerFolder,
  shipments: shipments,
  fcRestocking: fcRestocking,
  returns: returns,
  orderNotifications: orderNotifications,
  supplyChannels: supplyChannels,
  supplyChannelListings: supplyChannelListings,
  notification: toastNotificationReducer,
  dynamicPage: dynamicPage,

  calculator: calculatorReducer,
  shippingCost: shippingCostReducer,
  pickupPoints: pickupPoints,
  transfers: transfers,
});

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  version: 1.2, //7 April 2023 by Memet, after Dynamic Page
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
  /*middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        thunk: true,
        serializableCheck: false,
        immutableCheck: false
    }).concat(notificationMiddleware),*/
});

export const persistor = persistStore(store);
export default store;
