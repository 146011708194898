import React from "react";
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import SearchInput from "./../dynamic-page/debt/searchInput";
import FooterPagination from "../dynamic-page/elements/FooterPagination";
import getActions from "../dynamic-page/util/getActions";
import { useDispatch, useSelector } from "react-redux";
import filtersView from "./models-view/filters";
import tableView from "./models-view/table";
import sortView from "./models-view/sort";
import DynamicTable from "../dynamic-page";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";
import {
  fetchNotifications as fetchData,
  fetchNotificationsFilters as fetchFilters,
  updateFilter,
  addNotification,
  updateBoundFilter,
} from "../../store/order-notifications";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import useLoadMoreData from "../../utils/useLoadMoreData";

const Notifications = () => {
  const dispatch = useDispatch();
  // Get data from store.
  const pageData = useSelector((state) => state.orderNotifications);
  const tableData = useSelector(
    (state) => state.orderNotifications.notification
  );

  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    fetchData,
  });

  // Initial page at first load.
  React.useEffect(() => {
    const startPageByFilters = {
      search: "",
      sort: null,
      page: 1,
      limit: 10,
      filters: {
        status: null,
      },
    };
    const storeQuery = storeQueryBuilder(
      startPageByFilters,
      urlQueryLoader(startPageByFilters)
    );
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(updateBoundFilter(mappedFilters));
    dispatch(addNotification(storeQuery));
    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters(urlQuery));
  }, [dispatch]);

  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = React.useState(null);

  const mappedStatuses = tableData?.map((item) => ({
    ...item,
    status: item?.order_status,
  }));

  // Initial action list
  const actionList = {
    orderDetails: {
      label: "Order Details",
    },
    markAsShipped: {
      label: "Mark as Shipped",
    },
  };

  // handle actions of statuses
  const actions = (row) => {
    //console.log(row, "row")
    return getActions(row, actionList);
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      limit: limit || currentStoreFilters.limit || 10,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    await dispatch(fetchFilters(urlQuery));
    await dispatch(fetchData(urlQuery));
  };
  
  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(addNotification(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  const onFilterApply = (filters, bound) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateBoundFilter({ filters, bound }));
    dispatch(fetchFilters(urlQuery));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    let changeSort;

    if (sort === currentStoreFilters?.sort) {
      changeSort = null;
    } else if (currentStoreFilters?.sort === `-${sort}`) {
      changeSort = sort;
    } else {
      changeSort = `-${sort}`;
    }

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term", "");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  // Initial sort list
  const sortList = sortView({
    callback: (value) => {
      onSortApply(value);
    },
  });

  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        <ArbitProductPage.Title
          title={"Notifications"}
          sort={{
            items: sortList,
            selected: pageData?.filters?.sort,
          }}
          filter={{
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            items: filtersView(pageData?.filters?.filters),
            callback: (rs, bound) => {
              onFilterApply(rs, bound);
            },
            count: Object.values(pageData?.filters?.filters ?? {}).filter(
              (item) => item !== null
            ).length,
          }}
          actionList={actions(selectedRow)}
        />
        <div
          className={
            "flex flex-row justify-between px-[16px] py-[4px] items-center"
          }
        >
          <SearchInput
            className="w-[362px]"
            placeholder="Search..."
            onSubmit={onSearchApply}
            defaultValue={pageData?.filters?.search}
          />
        </div>
        <ArbitProductPage.Content>
          <div className="overflow-x-auto max-w-[100%]">
            <DynamicTable
              view={tableView}
              data={tableView(mappedStatuses)} // Use dummyTableData for the table data
              actions={actions}
              onSelect={onSelectRow}
              selectedRow={selectedRow}
              meta={pageData?.meta}
              appending={loading}
              loadMoreData={loadMoreData}
              loading={pageData.loading} // You can set this to true if you want to simulate loading
              style={{
                table: {
                  style: { width: "100%", minWidth: "100%" },
                },
                header: {
                  className: "justify-center !pl-[16px] !pr[8px]",
                  control: { className: "!py-[0px] hidden" },
                  status: { className: "!py-[0px] hidden" },
                },
                row: {
                  className:
                    "!px-[8px] !py-[0px] !items-center !justify-center flex text-[12px] ",
                  "order id": { className: "!justify-start" },
                  _actions: { className: "!py-[0px]" },
                },
                hideSelect: true,
                hiddenActions: true,
              }}
            />
          </div>
        </ArbitProductPage.Content>
        {/* Pagination is here. */}
        <FooterPagination
          meta={{ ...pageData?.meta, limit: pageData?.filters?.limit }}
          pageData={pageData}
          loading={pageData.loading}
          onLimit={onHandleLimit}
        />
      </ArbitProductPage.Page>
    </>
  );
};

export default Notifications;
