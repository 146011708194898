const tabsView = (pageData) => {
  return {
    active: {
      label: "Active",
      count:
        pageData?.filtersMeta?.status?.find((item) => item.status === "active")
          ?.count || 0,
      filter: {
        status: "active",
      },
    },
    inactive: {
      label: "Inactive",
      count:
        pageData?.filtersMeta?.status?.find(
          (item) => item.status === "inactive"
        )?.count || 0,
      filter: {
        status: "inactive",
      },
    },
    all: {
      label: "All",
      count:
        pageData?.filtersMeta?.status?.find((item) => item.status === "All")
          ?.count -
          pageData?.filtersMeta?.status?.find(
            (item) => item.status === "deleted"
          )?.count || 0,
      filter: {
        status: "All",
      },
      active: true,
    },
  };
};
export default tabsView;
