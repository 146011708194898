import { createMappedFilters } from "../components/dynamic-page/util/pageControl";

export const updateBoundFilters = (state, action) => {
  let { filters, bound } = action.payload;
  if (!bound) {
    bound = action.payload?.filters ? state.boundFilters : action.payload;
  }

  if (!filters) {
    filters = state.filters.filters;
  }
  const mergedBound =
    Object.keys(bound)?.length === 1
      ? { ...state.boundFilters, ...bound }
      : bound;

  filters &&
    Object.keys(filters).forEach((key) => {
      if (filters[key] && !mergedBound[key] && state.boundFilters[key]) {
        mergedBound[key] = state.boundFilters[key];
      }
    });

  const boundFilters = createMappedFilters(
    { filters: filters },
    mergedBound,
    true
  );

  Object?.keys(boundFilters).forEach((key) => {
    if (boundFilters[key] === null) {
      delete boundFilters[key];
    }

    if (Array.isArray(boundFilters[key])) {
      boundFilters[key] = boundFilters[key].map((item) => {
        if (item?.data) {
          delete item.data;
        }
        return item;
      });
    }
  });

  // Encode the boundFilters
  const boundFiltersString = encodeURIComponent(JSON.stringify(boundFilters));

  // Append or update the encoded string in the URL
  const url = new URL(window.location.href);
  url.searchParams.set("boundFilters", boundFiltersString);

  // Update the URL using history.replaceState to avoid adding to history stack
  window.history.replaceState({}, "", url.toString());

  // Update state if necessary
  state.boundFilters = boundFilters;
};
