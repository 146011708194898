// axios.js
import { api_endpoint } from "./../config";
import axios from "axios";
import store from "../store";
import { signout } from "../store/auth";

// Set default Axios configurations
axios.defaults.baseURL = api_endpoint;
axios.defaults.withCredentials = true;
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Accept"] = "application/json";
// Max content length of 10MB
axios.defaults.maxContentLength = 10 * 1000 * 1000;

// Function to change the cursor and overlay display
const changeCursor = (cursor) => {
  const overlay = document.getElementById("overlay");

  if (cursor) {
    document.body.style.cursor = "wait";
    if (overlay) overlay.style.display = "block";
  } else {
    document.body.style.cursor = "default";
    if (overlay) overlay.style.display = "none";
  }
};

// Map to store AbortControllers for each URL
const controllersMap = new Map();

/**
 * Utility function to merge multiple AbortSignals into a single signal.
 * The returned signal will be aborted if any of the input signals are aborted.
 * @param {AbortSignal[]} signals - An array of AbortSignals to merge.
 * @returns {AbortSignal} - The merged AbortSignal.
 */
function mergeSignals(signals) {
  const controller = new AbortController();
  const { signal } = controller;

  const abortHandler = () => {
    controller.abort();
    removeAllListeners();
  };

  const removeAllListeners = () => {
    signals.forEach((s) => {
      if (s && typeof s.removeEventListener === "function") {
        s.removeEventListener("abort", abortHandler);
      }
    });
  };

  signals.forEach((s) => {
    if (s) {
      if (s.aborted) {
        controller.abort();
      } else if (typeof s.addEventListener === "function") {
        s.addEventListener("abort", abortHandler);
      }
    }
  });

  return signal;
}

// Request Interceptor
axios.interceptors.request.use(
  (config) => {
    const url = config.url.split("?")[0];

    if (!config.skipCancel) {
      // Cancel any previous request to the same URL
      if (controllersMap.has(url)) {
        const existingController = controllersMap.get(url);
        existingController.abort();
      }

      // Create a new AbortController for the current request
      const abortController = new AbortController();

      if (config.signal) {
        // If a signal already exists (e.g., from thunkAPI.signal), merge it
        config.signal = mergeSignals([config.signal, abortController.signal]);
      } else {
        // Otherwise, use the new AbortController's signal
        config.signal = abortController.signal;
      }

      // Store the new controller in the map for this URL
      controllersMap.set(url, abortController);
    }

    return config;
  },
  (error) => {
    changeCursor(false);
    return Promise.reject(error);
  }
);

// Response Interceptor
axios.interceptors.response.use(
  (response) => {
    changeCursor(false);
    return response;
  },
  (error) => {
    changeCursor(false);

    if (axios.isCancel(error) || error.message === "canceled") {
      // Handle cancellation
      return Promise.reject(error);
    } else {
      // Handle other errors
      if (error?.response?.data?.message === "This action is unauthorized.") {
        return Promise.reject(error.response);
      }

      if (error?.response?.data?.message === "Unauthenticated.") {
        store.dispatch(signout());
      }

      // Reject the Promise for non-canceled errors
      return Promise.reject(error);
    }
  }
);

export default axios;
