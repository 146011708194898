import React from "react";
import Icons from "../../orders/icons";
import { HiExternalLink } from "react-icons/hi";
import PropTypes from "prop-types";
import descriptiveContent from "../../../utils/descriptiveContent";
import CopyText from "../util/copyText";

const Fire = Icons.fire;
function SalesChannel({ item, openListingModal, date, item2, pageData }) {
  return (
    <div className="flex flex-col w-full gap-1">
      <div className="flex flex-col gap-1">
        <div className="flex gap-1 justify-start items-center">
          {item?.sold_by_us &&
            descriptiveContent(<Fire />, "Sold By Arbitbox")}
          <img
            className="h-[14px] object-cover"
            src={item?.["channel"]?.logo}
          />
          {
            <div className="text-gray-900 text-[14px] font-normal leading-[21px]">
              {item?.["channel"]?.name}
            </div>
          }
        </div>
      </div>
      <div className="">
        <div className="flex items-center gap-1">
          {descriptiveContent(
            <button
              className={"text-[12px] cursor-pointer text-blue-700 leading-[18px] font-medium "}
              onClick={() => openListingModal()}
            >
              {item?.identifier ? item?.identifier : "-"}
            </button>,
            "Identifier Value"
          )}
          <CopyText
              className={"text-[12px]"}
              text={item?.identifier}
              hint={false}
            />
          <HiExternalLink
            className="w-3 h-3 text-gray-300 hover:text-gray-500 cursor-pointer"
            onClick={() => {
              window.open(`${item?.channel?.url}/gp/product/${item?.identifier}`, "_blank");
            }}
          />
        </div>
      </div>
    </div>
  );
}

const itemShape = PropTypes.shape({
  sold_by_us: PropTypes.any,
  channel: PropTypes.shape({
    logo: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  }),
  identifier_type: PropTypes.string,
  identifier_value: PropTypes.string,
  identifier: PropTypes.string,
  url: PropTypes.string,
});

SalesChannel.propTypes = {
  item: itemShape,
  openListingModal: PropTypes.func,
  item2: PropTypes.any,
  pageData: PropTypes.any,
  date : PropTypes.string
};

export default SalesChannel;
