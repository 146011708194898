import * as Yup from "yup";
import React, { useEffect, useState } from "react";

import upperCaseString from "../../dynamic-page/util/upperCaseString";
import PropTypes from "prop-types";

import axios from "axios";
import Input from "../../dynamic-form/elements/Input";

const formView = (item) => {
  const innerSubmit = [
    {
      label: "Save",
      type: "primary",
      action: "save",
    },
  ];

  return {
    title: "Add New Inventory Adjustment",
    submit: innerSubmit,
    // close: !item?.id,
    // cancel: !item?.id ? false : true,
    viewModel: (formDefaultValue) => {
      return [
        {
          name: "product_id",
          label: "Product",
          type: "Select",
          labelClassName: "!text-gray-900 !font-medium",
          api: {
            url: "/api/v1/product",
            query: "?term={query}&filter[type]=1",
            optionValue: (item) => ({
              value: item?.id,
              label: upperCaseString(item?.title, "word", true),
              image: item?.image,
            }),
          },
          disablePreload: true,
          reset: false,
          clear: false,
          className: "col-span-2",
          style: {
            control: {
              height: "40px !important",
              minHeight: "auto",
            },
            menu: {
              whiteSpace: "wrap !important ",
              zIndex: 9999,
            },
            option: {
              padding: "4px 8px !important",
            },
          },
          features: {
            components: {
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            },
            formatOptionLabel: (child) => (
              <div className={"flex flex-row items-center gap-[8px]"}>
                {child?.image ? (
                  <img
                    className={"w-[32px] h-[32px] rounded-full"}
                    src={child?.image}
                    alt="prodcut"
                  />
                ) : (
                  <img
                    className={"w-[32px] h-[32px] rounded-full"}
                    src={"/assets/images/noImage.png"}
                    alt="none"
                  />
                )}
                <span className={"text-gray-800"}>{child?.label}</span>
              </div>
            ),
          },
          defaultValue: formDefaultValue?.product?.id || null,
          validation: Yup.string().required("Product is required"),
          placeholder: (
            <div
              className={
                "flex flex-row gap-[8px] items-center text-[14px] text-[#6B7280]"
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15px"
                height="15px"
                fill="none"
              >
                <g clipPath="url(#a)">
                  <path
                    stroke="#6B7280"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m10.5 10.5-3-3m1-2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h12v12H0z" />
                  </clipPath>
                </defs>
              </svg>
              Quick search on products
            </div>
          ),
        },
        {
          name: "qty",
          label: "Quantity",
          placeholder: "ex. 200",
          type: "number",
          labelClassName: "!text-gray-900 !font-medium",
          onlyDigits: true,
          defaultValue: formDefaultValue?.qty || null,
          // disabled: formDefaultValue?.ucode ? true : false,
          validation: Yup.string().required("Quantity is required"),
        },
        {
          name: "warehouse_id",
          label: "Warehouse",
          placeholder: " Warehouse",
          type: "Select",
          api: {
            url: "api/v1/warehouses",
            query: "&filter[name]={query}",
            optionValue: (item) => ({
              value: item?.id,
              label: item?.name,
            }),
          },
          defaultValue: formDefaultValue?.warehouse_id,
          clear: true,
          labelClassName: "!text-gray-900 !font-medium",
          condition: (data) => data?.type !== 3,
          validation: Yup.string().required("Warehouse is required"),
        },
        {
          name: "type",
          label: "Type",
          // placeholder: " In, Out",
          type: "RadioButton",
          outerClassName: "!gap-[20px]",
          list: [
            { label: "In", value: "in" },
            { label: "Out", value: "out" },
          ],
          labelClassName: "!text-gray-900 !font-medium",
          defaultValue: formDefaultValue?.type,
          clear: true,
          validation: Yup.string().required("Type is required"),
        },
        {
          // name: "assign",
          label: "Available Quantity",
          placeholder: "Select Employee",
          type: "Module",
          module: AvailableQuantity,
          defaultValue: null,
          labelClassName: "!text-gray-900 !font-medium",
        },
        {
          name: "reason",
          label: "Adjustment Reason",
          placeholder: "Damaged, Expired, etc..",
          type: "Textarea",
          rows: "3",
          defaultValue: formDefaultValue?.reason || null,
          className: "col-span-2",
          optional: true,
          labelClassName: "!text-gray-900 !font-medium",
        },
      ];
    },
  };
};
export default formView;

const AvailableQuantity = ({ item, binded, change, data }) => {
  const [availableQuantity, setAvailableQuantity] = useState("");

  useEffect(() => {
    if (data.product_id && data.warehouse_id) {
      async function fetchAvailableQuantity() {
        await axios
          .get(
            `api/v1/good-acceptance/available-quantity?warehouse_id=${data.warehouse_id}&product_id=${data.product_id}`,
            { withCredentials: true }
          )
          .then((response) => {
            // setWarehouses(response?.data?.data)
            setAvailableQuantity(response?.data?.availableQuantity);
          });
      }

      fetchAvailableQuantity();
    }
  }, [data.product_id, data.warehouse_id]);

  return (
    <div>
      <Input
        key="product_new_brand"
        id="product_new_brand"
        item={{
          // name: "ava",
          label: "Available Quantity",
          placeholder: "Available Quantity",
          disabled: true,
        }}
        value={availableQuantity} // Use selectedOption directly as the value
        // handleChange={handleSelectChange} // Pass the function reference without ()
        selectedValue={availableQuantity} // Use selectedOption directly as the value
        bind={(a, b) => {
          return null;
        }}
      />
    </div>
  );
};

AvailableQuantity.propTypes = {
  item: PropTypes.object,
  binded: PropTypes.object,
  change: PropTypes.func,
  data: PropTypes.shape({
    product_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    warehouse_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};
