/**
 * @author Austin Ames
 * @version 0.1.0
 * @params Object - Product Summary
 */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArbitBadge from "../sub-elements/badge";
import ArbitLoading from "../sub-elements/loading";
import { toast } from "react-toastify";
import Select from "react-select";
import PropTypes from "prop-types";
import {
  deleteProduct,
  deleteProductImage,
  duplicateProduct,
  fetchProduct,
  getBrands,
  UpdateProduct,
} from "../../store/products";
import ArbitProductDeleteModal from "./Product-delete-modal";
import ArbitImageContainer from "../sub-elements/image-container";
import copy from "copy-to-clipboard";
import axios from "../../middleware/axios";
import constants from "../../constant";
import quantityHandler from "../../utils/quantityHandler";
import priceHandler from "../../utils/priceHandler";
import notifier from "../dynamic-page/util/notifier";
import TruncateText from "../dynamic-page/util/truncateText";

const ProductHeader = ({ datas, id, productLanguage, listingCount }) => {
  const navigate = useNavigate();
  const brands = useSelector((state) => state.productReducer.brands);
  const dispatch = useDispatch();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getBrands());
  }, []);

  const onBrandChange = (lastvalue) => {
    updateProduct(datas?.title, lastvalue.value, datas?.unit?.id);
  };

  const updateProduct = async (productTitle, productBrands, unit_id) => {
    const payload = {
      type: datas?.type,
      // "category_id": datas?.category_id,
      title: productTitle,
      brand_id: productBrands,
      ...(unit_id && { unit_id: unit_id }),
    };
    await dispatch(UpdateProduct({ id, payload }))
      .unwrap()
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchProduct({ id, productLanguage }));
          toast.success(response?.data?.message);
        } else if (response?.status === 422) {
          notifier(response?.data);
        }
      });
  };

  const handleProductDeletion = async () => {
    await dispatch(deleteProduct(id)).then(({ payload }) => {
      notifier(payload?.data);

      if (payload?.status === 200) {
        setDeleteModalVisible(false);
        navigate("/product/list");
      }
    });
  };
  const handleProductDuplicate = async () => {
    setLoading(true);
    await dispatch(duplicateProduct(id)).then(({ payload }) => {
      setLoading(false);
      if (payload?.status === 200) {
        toast.success("Product has been duplicated successfully.", {
          toastId: "success1",
        });
      } else {
        notifier(payload?.data);
      }
    });
  };

  const onCopyUrl = (url) => {
    copy(url);
    toast.info("Copied to clipboard", {
      toastId: "success1",
    });
  };
  const onDelete = async (imageId) => {
    const payload = {
      is_main: true,
    };
    await dispatch(deleteProductImage({ id, imageId, payload }))
      .unwrap()
      .then((response) => {
        if (response?.status === 204 || response?.status === 200) {
          dispatch(fetchProduct({ id, productLanguage }));
          notifier({
            type: response?.data?.type,
            message: response?.data?.message,
          });
        } else {
          notifier({
            type: response?.data?.type,
            message: response?.data?.message,
          });
        }
      })
      .catch((err) => {
        notifier(err);
      });
  };

  const mapOptionsToValues = (options) => {
    return options.map((option) => ({
      value: option?.id,
      label: option?.name,
    }));
  };

  const fetchBrands = async (params) => {
    let url = constants.APIURL.GET_BRANDS;
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });

    return response.data;
  };

  const loadBrandsOptions = async (inputValue) => {
    let url;

    url = "?term=" + inputValue;

    return new Promise((resolve) => {
      fetchBrands(url).then((response) => {
        const options = mapOptionsToValues(response.data);

        resolve(options);
      });
    });
  };

  const renderTextColor = () => {
    return datas?.type !== 1 ? "text-orange-600" : "text-[#1F2A37]";
  };

  const getLastPurchaseCost = (cost, className, currency) => {
    let cur = "";
    if (!currency) {
      cur = "$";
    }
    return (
      <span className={className}>
        {cost ? `${cur}${priceHandler(cost)}${currency || ""}` : "-"}
      </span>
    );
  };
  const calculateTotalValue = (
    averageCost,
    inventoryCount,
    reservedQuantity
  ) => {
    const avgCost = averageCost || 0;
    const inventory = inventoryCount || 0;
    const reserved = reservedQuantity || 0;

    return avgCost * (inventory + reserved);
  };
  const renderTotalInventoryValue = (datas, listingCount) => {
    const totalValue = calculateTotalValue(
      datas?.average_cost_usd,
      datas?.inventory_count,
      listingCount?.reserved_quantity
    );

    return (
      <span>
        {totalValue > 0 ? (
          <span className={renderTextColor()}>${priceHandler(totalValue)}</span>
        ) : (
          <span className="text-[#1F2A37]">-</span>
        )}
      </span>
    );
  };

  const getTotalInventory = (available, reserved) => {
    const total = (available || 0) + (reserved || 0);
    return total > 0 ? total : <span className="text-[#1F2A37]">-</span>;
  };
  const getProductType = (type) =>
    type === 1 ? "Single Product" : "Grouped Product";

  const getType = (type) => type === 1;

  const renderPrice = (price) => {
    if (!price) return "-";
    return (
      <span className={`${renderTextColor()}`}>${priceHandler(price)}</span>
    );
  };

  return (
    <>
      <div className={`flex w-full overflow-x-auto relative   `}>
        <div className=" flex-shrink-0 flex justify-center items-center">
          {
            <ArbitImageContainer
              src={datas?.image}
              key={1}
              onCopyUrl={onCopyUrl}
              sourceId={datas?.id}
              onDelete={onDelete}
            />
          }
        </div>
        <div
          className={`flex flex-col flex-[1_0_0] p-2 gap-2 flex-grow space-y-[8px] min-w-[500px] overflow-x-hidden `}
        >
          <div className="flex justify-between  items-center ">
            <div className="flex space-x-[16px] !w-full  text-[24px]  font-[600] text-[#111928]">
              <TruncateText
                tooltip={true}
                popUpToggleClassName={"!w-full"}
                hideCopy={true}
                className={""}
                innerClassName="whitespace-normal !w-full  !text-start	"
                tooltipClassName="whitespace-pre-wrap max-w-[700px] break-words  "
              >
                {datas?.title}
              </TruncateText>
            </div>
            <div className="flex gap-2">
              <Select
                options={[
                  {
                    value: "Duplicate",
                    label: "Duplicate",
                  },
                  {
                    value: "Delete",
                    label: "Delete",
                  },
                ]}
                placeholder="Actions"
                value={""}
                onChange={(e) => {
                  console.log(e);
                  if (e.value === "Duplicate") {
                    handleProductDuplicate();
                  } else {
                    setDeleteModalVisible(true);
                  }
                }}
                styles={{
                  control: (provided, state) => {
                    return {
                      ...provided,
                      height: 42,
                      width: "100%",
                      backgroundColor: "#F9FAFB",
                      borderRadius: 8,
                      overflow: "auto",
                      border: `1px solid #BDBDBD`,
                      boxShadow: state.isFocused
                        ? "0px 0px 0px 1px #00A3FF"
                        : "none",
                      "&:hover": {
                        border: `1px solid ${
                          state.isFocused ? "#00A3FF" : "#E5E5E5"
                        }`,
                      },
                    };
                  },
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#00A3FF" : "white",
                    color: state.isSelected ? "white" : "black",

                    "&:hover": {
                      backgroundColor: "#00A3FF",
                      color: "white",
                    },
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    position: "absolute",
                    zIndex: 999,
                    // borderRadius: 10,
                    // overflow: 'hidden',
                    border: state.isFocused
                      ? "1px solid #00A3FF"
                      : "1px solid #E5E5E5",
                    boxShadow: state.isFocused
                      ? "0px 0px 0px 1px #00A3FF"
                      : "none",
                    "&:hover": {
                      border: state.isFocused
                        ? "1px solid #00A3FF"
                        : "1px solid #E5E5E5",
                    },
                  }),
                  container: (provided) => ({
                    ...provided,
                    width: "150px", // Retain the specified width
                  }),
                }}
              />
            </div>
          </div>
          <div className={`grid  grid-cols-2 md:grid-cols-5 gap-[4px] !mt-0  `}>
            <ArbitBadge
              title="APID"
              value={datas?.["apid"]}
              bgColor="indigo-50"
              copy={true}
              tooltip={{
                title: "Arbitbox Product ID",
                desc: "A unique identifier for each product in Arbitbox, ensuring accurate tracking, management, and integration across all modules.",
                position: "bottom",
              }}
            />
            <ArbitBadge
              title="BRAND"
              value={
                <span className="text-[#1F2A37]">{datas?.brand?.name}</span>
              }
              onChange={onBrandChange}
              options={brands}
              loadOptions={loadBrandsOptions}
              placeholder={"Search Brands"}
              tooltip={{
                title: "Brand",
                desc: "Represents the manufacturer or label associated with a product in Arbitbox, ensuring clear identification and categorization.",
                position: "bottom",
              }}
            />
            {getType(datas?.type) ? (
              <ArbitBadge
                title="W. AVG. COST"
                value={
                  <span className="text-[#1F2A37]">
                    {renderPrice(datas?.average_cost_usd)}
                  </span>
                }
                tooltip={{
                  title: "Weighed Average Cost",
                  desc: "Represents the weighted average supply cost over the last 6 months, considering quantity and dollar value on the supply date.",
                  position: "bottom",
                }}
              />
            ) : (
              <ArbitBadge
                title="30D ORDERS"
                value={<span>-</span>}
                tooltip={{
                  title: "Weighed Average Cost",
                  desc: "Represents the weighted average supply cost over the last 6 months, considering quantity and dollar value on the supply date.",
                  position: "bottom",
                }}
              />
            )}

            {getType(datas?.type) ? (
              <ArbitBadge
                title="WAREHOUSE INV."
                value={
                  <span className="text-[#1F2A37]">
                    {quantityHandler(datas?.inventory_count, datas?.unit) ||
                      "-"}
                  </span>
                }
                tooltip={{
                  title: "Warehouse Inventory",
                  desc: "Indicates the total quantity of products available across all warehouses.",
                  position: "bottom",
                }}
              />
            ) : (
              <ArbitBadge
                title="W. AVG. COST"
                value={
                  <span className="text-[#1F2A37]">
                    {renderPrice(datas?.average_cost_usd)}
                  </span>
                }
                tooltip={{
                  title: "Weighed Average Cost",
                  desc: "Represents the weighted average supply cost over the last 6 months, considering quantity and dollar value on the supply date.",
                  position: "bottom",
                }}
              />
            )}
            {getType(datas?.type) ? (
              <ArbitBadge
                title="L. PURCHASE PRICE"
                value={getLastPurchaseCost(
                  datas?.last_purchase_cost,
                  "text-[#1F2A37]"
                )}
                secondValue={getLastPurchaseCost(
                  datas?.last_purchase_cost_try,
                  "text-gray-500",
                  "₺"
                )}
                tooltip={{
                  title: "Last Purchase Price",
                  desc: "Indicates the most recent price paid for the product.",
                  position: "bottom",
                }}
              />
            ) : (
              <ArbitBadge
                title="VIRTUAL INV."
                value={
                  (
                    <span className="text-orange-600">
                      {quantityHandler(datas?.inventory_count, datas?.unit)}
                    </span>
                  ) || "-"
                }
                tooltip={{
                  title: "Warehouse Virtual Inventory",
                  desc: "Virtual quantity that may occur according to the warehouse inventory of components.",
                  position: "bottom",
                }}
              />
            )}
            <ArbitBadge
              title="PRODUCT TYPE"
              value={
                <span className="text-[#1F2A37]">
                  {getProductType(datas?.type)}
                </span>
              }
              bgColor="indigo-50"
              tooltip={{
                title: "Product Type",
                desc: (
                  <div>
                    <span className=" font-bold ">Single products</span> are
                    individual items, while{" "}
                    <span className=" font-bold ">Grouped products</span> are
                    combinations of multiple Single products.
                  </div>
                ),
                position: "top",
              }}
            />
            <ArbitBadge
              title="BRAND ASSIGN"
              value={
                <span className="text-[#1F2A37]">
                  {datas?.brand?.assigned?.name || "-"}
                </span>
              }
              tooltip={{
                title: "Brand Assign",
                desc: "Each brand in Arbitbox is assigned to a specific user responsible for managing and maintaining that brand’s products.",
                position: "top",
              }}
            />
            {getType(datas?.type) ? (
              <ArbitBadge
                title="T. INV. VALUE"
                value={renderTotalInventoryValue(datas, listingCount)}
                tooltip={{
                  title: "Total Inventory Value",
                  desc: (
                    <span className="flex flex-col gap-2">
                      Represents the total value of all products in inventory,
                      calculated based on their Weighted Average Cost (WAC).{" "}
                      <span>TIV = WAC x Total Inventory (WH + FC)</span>
                    </span>
                  ),
                  position: "top",
                }}
              />
            ) : (
              <ArbitBadge
                title="FC INVENTORY"
                value={getTotalInventory(
                  listingCount?.available_quantity,
                  listingCount?.reserved_quantity
                )}
                tooltip={{
                  title: "Fulfillment Center Inventory",
                  desc: "Indicates the total quantity of products available in Fulfillment Centers.",
                  position: "top",
                }}
              />
            )}
            {getType(datas?.type) ? (
              <ArbitBadge
                title="FC INVENTORY"
                value={getTotalInventory(
                  listingCount?.available_quantity,
                  listingCount?.reserved_quantity
                )}
                tooltip={{
                  title: "Fulfillment Center Inventory",
                  desc: "Indicates the total quantity of products available in Fulfillment Centers.",
                  position: "top",
                }}
              />
            ) : (
              <ArbitBadge
                title="T. INV. VALUE"
                value={renderTotalInventoryValue(datas, listingCount)}
                tooltip={{
                  title: "Total Inventory Value",
                  desc: (
                    <span className="flex flex-col gap-2">
                      Represents the total value of all products in inventory,
                      calculated based on their Weighted Average Cost (WAC).{" "}
                      <span>TIV = WAC x Total Inventory (WH + FC)</span>
                    </span>
                  ),
                  position: "top",
                }}
              />
            )}
            <ArbitBadge
              title="SCL COST"
              value={getLastPurchaseCost(
                listingCount?.lowestSclPrice?.usd_price,
                renderTextColor()
              )}
              secondValue={getLastPurchaseCost(
                listingCount?.lowestSclPrice?.price,
                "text-gray-500",
                "₺"
              )}
              tooltip={{
                title: "Supply Channel Listing Cost",
                desc: "Current cost of the product in the supply channel listing.",
                position: "top",
              }}
            />
          </div>
        </div>
      </div>
      {loading && <ArbitLoading />}
      {deleteModalVisible && (
        <ArbitProductDeleteModal
          name={datas?.title}
          show={deleteModalVisible}
          center={true}
          onClose={() => setDeleteModalVisible(false)}
          handleProductDeletion={() => handleProductDeletion()}
        />
      )}
    </>
  );
};
ProductHeader.propTypes = {
  datas: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  productLanguage: PropTypes.string.isRequired, // Specify the type
  listingCount: PropTypes.object, // Optional prop
};

// Default props if necessary
ProductHeader.defaultProps = {
  listingCount: {},
};

export default ProductHeader;
