import { ReactComponent as backspace } from "./backspace.svg";
import { ReactComponent as bullet } from "./bullet.svg";
import { ReactComponent as buyerNote } from "./buyerNote.svg";
import { ReactComponent as calculator } from "./calculator.svg";
import { ReactComponent as chartPie } from "./chartPie.svg";
import { ReactComponent as check } from "./check.svg";
import { ReactComponent as chevronRight } from "./chevronRight.svg";
import { ReactComponent as clock } from "./clock.svg";
import { ReactComponent as clockReady } from "./clockReady.svg";
import { ReactComponent as collection } from "./collection.svg";
import { ReactComponent as createLabel } from "./createLabel.svg";
import { ReactComponent as edit } from "./edit.svg";
import { ReactComponent as exclamation } from "./exclamation.svg";
import { ReactComponent as goToTrackURL } from "./goToTrackURL.svg";
import { ReactComponent as printLabel } from "./printLabel.svg";
import { ReactComponent as quickShip } from "./quickShip.svg";
import { ReactComponent as shipmentDetail } from "./shipmentDetail.svg";
import { ReactComponent as shippingContent } from "./shippingContent.svg";
import { ReactComponent as shippingDocument } from "./shippingDocument.svg";
import { ReactComponent as showLabel } from "./showLabel.svg";
import { ReactComponent as internalNote } from "./internalNote.svg";
import { ReactComponent as weightBox } from "./weightBox.svg";
import { ReactComponent as weightTarazo } from "./weightTarazo.svg";
import { ReactComponent as xMark } from "./xMark.svg";
import { ReactComponent as boxArrow } from "./boxArrow.svg";
import { ReactComponent as del } from "./del.svg";
import { ReactComponent as star } from "./star.svg";
import { ReactComponent as hideLabel } from "./hideLabel.svg";
import { ReactComponent as addImage } from "./addImage.svg";
import { ReactComponent as adjustment } from "./adjustment.svg";
import { ReactComponent as globe } from "./globe.svg";
import { ReactComponent as home } from "./home.svg";
import { ReactComponent as puzzle } from "./puzzle.svg";
import { ReactComponent as user } from "./user.svg";
import { ReactComponent as arrow } from "./arrow.svg";
import { ReactComponent as link } from "./link.svg";
import { ReactComponent as link2 } from "./link2.svg";
import { ReactComponent as exclamationTin } from "./exclamation_tin.svg";
import { ReactComponent as offerCount } from "./offerCount.svg";
import { ReactComponent as tradingUp } from "./trending-up.svg";
import { ReactComponent as salesNote } from "./salesNote.svg";
import { ReactComponent as fire } from "./fire.svg";
import { ReactComponent as labelsPaidBy } from "./labelsPaidBy.svg";
import { ReactComponent as returnRequest } from "./returnRequest.svg";
import { ReactComponent as azClaim } from "./azClaim.svg";
import { ReactComponent as cube } from "./cube.svg";
import { ReactComponent as truck } from "./truck.svg";
import { ReactComponent as truck2 } from "./truck2.svg";
import { ReactComponent as cash } from "./cash.svg";
import { ReactComponent as trendingup } from "./trendingUp.svg";
import { ReactComponent as goToListingView } from "./go-to-lising-view.svg";
import { ReactComponent as goToRepricer } from "./go-to-repricer.svg";
import { ReactComponent as repricerNote } from "./repricer-note.svg";
import { ReactComponent as exclamationFill } from "./exclamationFill.svg";
import { ReactComponent as uploadOutline } from "./upload-outline.svg";
import { ReactComponent as download } from "./download.svg";
import { ReactComponent as exportIcon } from "./cloud-download.svg";
import { ReactComponent as trash } from "./trash.svg";
import { ReactComponent as location } from "./location-marker.svg";
import { ReactComponent as shield } from "./shield-check.svg";
import { ReactComponent as notificationDot } from "./notification-dot.svg";
import { ReactComponent as docText } from "./document-text.svg";
import { ReactComponent as uploadSolid } from "./uploadSolid.svg";
import { ReactComponent as plus } from "./plus.svg";
import { ReactComponent as track } from "./truck-solid.svg";
import { ReactComponent as brokenHeart } from "./heart-crack-solid.svg";
import { ReactComponent as reply } from "./reply.svg";
import { ReactComponent as fileCopy } from "./fileCopy.svg";
import { ReactComponent as info } from "./info.svg";
import { ReactComponent as search } from "./search.svg";
import { ReactComponent as close } from "./close.svg";
import { ReactComponent as chevronUp } from "./chevronUp.svg";
import { ReactComponent as chevronDown } from "./chevronDown.svg";
import { ReactComponent as circlePlus } from "./circle-plus.svg";
import { ReactComponent as TrashBin } from "./trash-bin.svg";
import { ReactComponent as merge } from "./merge.svg";
import { ReactComponent as requestNote } from "./Request-note.svg";
import { ReactComponent as purchaseNote } from "./Purchase-note.svg";
import { ReactComponent as acceptanceNote } from "./Acceptance-note.svg";
import { ReactComponent as Send } from "./send.svg";
import { ReactComponent as chechkCircle } from "./checkh-circle.svg";
import { ReactComponent as arrowRotate } from "./arrowRotate.svg";
import { ReactComponent as mapPin } from "./mapPin.svg";
import { ReactComponent as pencilAlt } from "./pencilAlt.svg";
import { ReactComponent as copyText } from "./copyText.svg";
import { ReactComponent as editOutline } from "./editOutline.svg";
import { ReactComponent as refresh } from "./refresh.svg";
import { ReactComponent as inbox } from "./inbox-full.svg";
import { ReactComponent as receipt_percent } from "./receipt-percent.svg";
import { ReactComponent as copyOrders } from "./copyOrders.svg";
import { ReactComponent as union } from "./union.svg";
import { ReactComponent as globeAlt } from "./globe-alt.svg";
import { ReactComponent as rectangleGroup } from "./rectangle-group.svg";
import { ReactComponent as wrench } from "./wrench.svg";
import { ReactComponent as cloudArrowDown } from "./cloud-arrow-down.svg";
import { ReactComponent as ArrowPath } from "./arrow-path.svg";
import { ReactComponent as receipt } from "./receipt.svg";
import { ReactComponent as archive } from "./archive.svg";
import { ReactComponent as inbox_arrow_down } from "./inbox-arrow-down.svg";
import { ReactComponent as archive_box } from "./archive-box.svg";
import { ReactComponent as computer_speaker } from "./computer-speaker.svg";
import { ReactComponent as cog } from "./cog.svg";

// Keep all existing imports...

export default {
  // Original exports with capitalized variants
  backspace,
  Backspace: backspace,
  cog,
  Cog: cog,
  bullet,
  Bullet: bullet,
  search,
  Search: search,
  cloudArrowDown,
  CloudArrowDown: cloudArrowDown,
  archive,
  Archive: archive,
  receipt,
  Receipt: receipt,
  globeAlt,
  GlobeAlt: globeAlt,
  rectangleGroup,
  RectangleGroup: rectangleGroup,
  ArrowPath,
  wrench,
  Wrench: wrench,
  chechkCircle,
  ChechkCircle: chechkCircle,
  buyerNote,
  BuyerNote: buyerNote,
  close,
  Close: close,
  pencilAlt,
  PencilAlt: pencilAlt,
  info,
  Info: info,
  circlePlus,
  CirclePlus: circlePlus,
  calculator,
  Calculator: calculator,
  copyText,
  CopyText: copyText,
  chartPie,
  ChartPie: chartPie,
  fileCopy,
  FileCopy: fileCopy,
  check,
  Check: check,
  mapPin,
  MapPin: mapPin,
  chevronRight,
  ChevronRight: chevronRight,
  clock,
  Clock: clock,
  clockReady,
  ClockReady: clockReady,
  collection,
  Collection: collection,
  createLabel,
  CreateLabel: createLabel,
  edit,
  Edit: edit,
  exclamation,
  Exclamation: exclamation,
  goToTrackURL,
  GoToTrackURL: goToTrackURL,
  printLabel,
  PrintLabel: printLabel,
  quickShip,
  QuickShip: quickShip,
  shipmentDetail,
  ShipmentDetail: shipmentDetail,
  chevronDown,
  ChevronDown: chevronDown,
  chevronUp,
  ChevronUp: chevronUp,
  shippingContent,
  ShippingContent: shippingContent,
  shippingDocument,
  ShippingDocument: shippingDocument,
  showLabel,
  ShowLabel: showLabel,
  internalNote,
  InternalNote: internalNote,
  weightBox,
  WeightBox: weightBox,
  weightTarazo,
  WeightTarazo: weightTarazo,
  xMark,
  XMark: xMark,
  boxArrow,
  BoxArrow: boxArrow,
  del,
  Del: del,
  star,
  Star: star,
  hideLabel,
  HideLabel: hideLabel,
  addImage,
  AddImage: addImage,
  adjustment,
  Adjustment: adjustment,
  globe,
  Globe: globe,
  home,
  Home: home,
  puzzle,
  Puzzle: puzzle,
  user,
  User: user,
  arrow,
  Arrow: arrow,
  link,
  Link: link,
  link2,
  Link2: link2,
  exclamationTin,
  ExclamationTin: exclamationTin,
  offerCount,
  OfferCount: offerCount,
  tradingUp,
  TradingUp: tradingUp,
  salesNote,
  SalesNote: salesNote,
  fire,
  Fire: fire,
  labelsPaidBy,
  LabelsPaidBy: labelsPaidBy,
  returnRequest,
  ReturnRequest: returnRequest,
  azClaim,
  AzClaim: azClaim,
  cube,
  Cube: cube,
  truck,
  Truck: truck,
  truck2,
  Truck2: truck2,
  cash,
  Cash: cash,
  trendingup,
  TrendingUp: trendingup,
  goToListingView,
  GoToListingView: goToListingView,
  goToRepricer,
  GoToRepricer: goToRepricer,
  repricerNote,
  RepricerNote: repricerNote,
  exclamationFill,
  ExclamationFill: exclamationFill,
  uploadOutline,
  UploadOutline: uploadOutline,
  download,
  Download: download,
  TrashBin,
  exportIcon,
  ExportIcon: exportIcon,
  trash,
  Trash: trash,
  location,
  Location: location,
  shield,
  Shield: shield,
  notificationDot,
  NotificationDot: notificationDot,
  docText,
  DocText: docText,
  uploadSolid,
  UploadSolid: uploadSolid,
  plus,
  Plus: plus,
  track,
  Track: track,
  brokenHeart,
  BrokenHeart: brokenHeart,
  reply,
  Reply: reply,
  merge,
  Merge: merge,
  requestNote,
  RequestNote: requestNote,
  purchaseNote,
  PurchaseNote: purchaseNote,
  acceptanceNote,
  AcceptanceNote: acceptanceNote,
  Send,
  arrowRotate,
  ArrowRotate: arrowRotate,
  editOutline,
  EditOutline: editOutline,
  refresh,
  Refresh: refresh,
  inbox,
  Inbox: inbox,
  receipt_percent,
  ReceiptPercent: receipt_percent,
  copyOrders,
  CopyOrders: copyOrders,
  union,
  Union: union,
  inbox_arrow_down,
  InboxArrowDown: inbox_arrow_down,
  archive_box,
  ArchiveBox: archive_box,
  computer_speaker,
  ComputerSpeaker: computer_speaker,
};
