import React, { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import MainModal from "../../dynamic-page/MainModal";
import Util from "../../dynamic-page/util";
import Icons from "../../orders/icons";
import { HiExternalLink } from "react-icons/hi";
import SelectItems from "../../supply-channel-listings/modals-view/searchAndConnect";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { connectProduct } from "../../../store/sales-listings";
import { Tooltip } from "react-tooltip";
import Button from "../../dynamic-form/elements/Button";
import CopyText from "../../dynamic-page/util/copyText";
import upperCase from "../../dynamic-page/util/upperCaseString";

// Main Component: ConnectChannelModal
const ConnectChannelModal = React.memo(
  ({ onClose, product_id, callBack, defaultListings, updateRows }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState({
      products: [],
      salesChannels: [],
    });
    const [defaultProduct, setDefaultProduct] = useState(null);
    const [defaultListingItems, setDefaultListingItems] = useState(null);
    const [connectLoading, setConnectLoading] = useState({});
    useEffect(() => {
      if (product_id) {
        const fetchProducts = async (params) => {
          let url = "api/v1/product/" + product_id;
          if (params) url = url.concat(params);
          const response = await axios.get(url, { withCredentials: true });

          setDefaultProduct(mapProducts(response.data?.data));
          setData((prev) => ({
            ...prev,
            products: [mapProducts(response.data?.data)],
          }));
          // return
        };
        fetchProducts();
      }
      if (defaultListings) {
        setData((prev) => ({
          ...prev,
          salesChannels: defaultListings.map(mapListings),
        }));
        setDefaultListingItems(defaultListings.map(mapListings));
      }
    }, [product_id]);

    const mapProducts = (item) => ({
      id: item.id,
      label: item.title,
      image: item.image,
      apid: item.apid,
      type: item.type,
      brand: item?.brand?.name,
      assign: item?.brand?.assigned,
      numbers: {
        "Supply CL": item.supply_channels_count,
        "Sales CL": item.sales_channels_count,
        Inventory: item.inventory_count,
        Orders: 0,
        "Avg. Cost": item?.average_cost_usd
          ? `$${item?.average_cost_usd}`
          : "-",
      },
    });

    const mapListings = (item) => ({
      id: item?.id,
      identifier: item?.sales_channel_listing.identifier,
      url: item?.sales_channel_listing.url,
      label: item?.sales_channel_listing.name,
      channel: item?.sales_channel_listing.channel?.name,
      brand: item?.sales_channel_listing?.brand,
      image: item?.sales_channel_listing?.main_image_path,
    });

    const onConnect = () => {
      const filteredSalesChannels = data?.salesChannels?.filter((item) => {
        // return only the ones that either not in connectLoading or failed
        return (
          connectLoading[item.id] === "Failed!" || !connectLoading[item.id]
        );
      });

      filteredSalesChannels?.forEach((salesChannel) => {
        setConnectLoading((prevState) => ({
          ...prevState,
          [salesChannel.id]: "Connecting...",
        }));
        handleConnectProduct(salesChannel);
      });
    };

    const handleConnectProduct = (salesChannel) => {
      dispatch(
        connectProduct({
          listId: salesChannel.id,
          productId: data?.products[0]?.id,
        })
      ).then((result) => {
        Util.notifier({
          type: result?.payload?.type,
          message: result?.payload?.message,
        });
        if (result?.payload?.type === "success") {
          if (product_id) callBack();
          else updateRows([salesChannel.id]);
          onClose();
          setConnectLoading((prevState) => ({
            ...prevState,
            [salesChannel.id]: "Connected!",
          }));
        } else {
          setConnectLoading((prevState) => ({
            ...prevState,
            [salesChannel.id]: "Failed!",
          }));
        }
      });
    };

    return (
      <MainModal
        className="max-w-[1000px] "
        mainClassName=" min-h-[672px]"
        containerClassName="!px-5"
        item={{
          title: "Connect Product",
          cancel: onClose,
          view: () => (
            <div className="app flex flex-col gap-3 border-t pt-4 border-gray-300">
              <SelectItems
                title="Product"
                url="product"
                include={"&include=brand.assigned"}
                mapItems={mapProducts}
                createNewRedirect={true}
                defaultItem={defaultProduct}
                ItemCard={ProductItem}
                key="Products"
                data={data}
                setData={setData}
                tooltip={productTooltip}
              />
              <SelectItems
                title="Listing"
                url="account-listings"
                mapItems={mapListings}
                createNewRedirect={false}
                ItemCard={SalesChannelItem}
                defaultItem={defaultListingItems}
                multiple
                key="sales"
                data={data}
                setData={setData}
                connectLoading={connectLoading}
                newDesign={true}
                params="filter[has_product]=false"
                tooltip={salesTooltip}
              />
              {data?.products?.length > 0 &&
                data?.salesChannels?.length > 0 &&
                // not all sales channels are connected
                !data?.salesChannels.every(
                  (item) => connectLoading[item.id] === "Connected!"
                ) && (
                  <div className="flex flex-row justify-end gap-[8px] sticky bg-white bottom-0 pb-2">
                    <Button
                      item={{
                        label: <div className={"flex flex-row"}>Cancel</div>,
                        buttonType: "alt",
                        className: "h-[37px] !w-[120px] !px-[16px]",
                      }}
                      onClick={() => onClose()}
                    />
                    <Button
                      item={{
                        label: <div className={"flex flex-row"}>Connect</div>,
                        buttonType: "primary",
                        className: "h-[37px] !w-[120px]  !px-[16px]",
                      }}
                      onClick={() => onConnect()}
                    />
                  </div>
                )}
              {}
              {Object.keys(connectLoading).length > 0 &&
                data?.salesChannels.every(
                  (item) => connectLoading[item.id] === "Connected!"
                ) && (
                  <div className="flex flex-row justify-end gap-[8px]">
                    <Button
                      item={{
                        label: <div className={"flex flex-row"}>Close</div>,
                        buttonType: "alt",
                        className: "h-[37px] !w-[120px] !px-[16px]",
                      }}
                      onClick={() => onClose()}
                    />
                  </div>
                )}
            </div>
          ),
        }}
      />
    );
  }
);
ConnectChannelModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  product_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  callBack: PropTypes.func.isRequired,
  defaultListings: PropTypes.array.isRequired, // `defaultListings` doğrulandı
  updateRows: PropTypes.func.isRequired,
};
export default ConnectChannelModal;

const ProductItem = ({
  item,
  index,
  results,
  searching,
  setSelectedProduct,
  setSearching,
  selectedProduct,
  setData,
  data,
}) => {
  const [orders, setOrders] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (item && !searching) {
      setLoading(true);
      const fetchOrders = async () => {
        const response = await axios.get(
          `/api/v1/orders/filters?filter[product]=${item.id}`,
          { withCredentials: true }
        );
        setLoading(false);
        if (response.data.data) {
          setOrders(
            response.data.data.statuses?.find((item) => item?.id === 10)
              ?.count || "-"
          );
        }
      };

      fetchOrders();
    }
  }, [item, searching]);

  const Trash = Icons.trash;

  const handleSkeleton = (key) => {
    if (key === "Orders") {
      if (loading) {
        return <Skeleton width={50} height={20} />;
      } else {
        return orders;
      }
    } else {
      return item?.numbers[key];
    }
  };

  return (
    <div
      key={index}
      className={`result-item   w-[630px]  h-[176px] flex items-center p-3 mb-1 border drop-shadow-sm rounded-lg`}
    >
      <img
        src={item.image || "/assets/images/img-placehoder.png"}
        alt={item.label}
        className={`result-image ${
          searching
            ? "w-[152px] h-[152px] max-h-[152px] "
            : "w-[120px] max-h-[120px] h-[120px]"
        } object-contain rounded`}
      />
      <div className="result-details flex-1 pl-4 flex flex-col h-full justify-between gap-0.5">
        {/* apid and badge here */}
        <div className="flex justify-between items-center">
          <div className={"flex flex-row gap-[4px]"}>
            <button
              className=" text-[14px] font-medium leading-normal text-gray-900 hover:text-blue-800 cursor-pointer"
              onClick={() => window.open(`/product/${item?.id}`, "_blank")}
            >
              {item?.apid}
            </button>
            <CopyText
              className={"text-[12px]"}
              text={item?.apid}
              hint={false}
            />
            <HiExternalLink
              className="w-4 h-4  text-blue-700 cursor-pointer"
              onClick={() => window.open(`/product/${item?.id}`, "_blank")}
            />
          </div>
          <div className="flex gap-2">
            {item?.type === 1 ? (
              <div className=" h-[22px] justify-start items-start inline-flex">
                <div className="px-2.5 py-0.5 bg-teal-100 rounded-md justify-center items-center gap-1 flex">
                  <div className="text-center whitespace-nowrap text-teal-800 text-[12px] font-medium leading-normal">
                    Single Product
                  </div>
                </div>
              </div>
            ) : (
              <div className=" h-[22px] justify-start items-start inline-flex">
                <div className="px-2.5 py-0.5 bg-sky-100 rounded-md justify-center items-center gap-1 flex">
                  <div className="text-center whitespace-nowrap text-indigo-800 text-[12px] font-medium leading-normal">
                    Grouped Product
                  </div>
                </div>
              </div>
            )}
            {!searching && results?.results?.length ? (
              <button
                onClick={() => {
                  setSelectedProduct([]);
                  setData({ ...data, products: [] });
                  setSearching(true);
                }}
              >
                <Trash className="w-5 h-5 text-gray-300 cursor-pointer hover:text-gray-500" />
              </button>
            ) : null}
          </div>
        </div>
        {/* title here */}
        <div>
          <p className="text-[16px]  leading-normal whitespace-pre-wrap font-semibold break-words w-[430px]">
            {item?.label?.length > 150
              ? Util.shortenString(item?.label, 150, false, true, null, "top")
              : item?.label}
          </p>
        </div>
        {/* assign */}
        {!searching && results?.results?.length && (
          <div className={"flex justify-between"}>
            <div className={"flex flex-row gap-[4px] text-gray-600"}>
              <div className=" text-[14px] text-gray-900 font-medium leading-[21px]  ">
                {item?.brand}
              </div>
            </div>

            <div className="flex items-center gap-[2px] !text-[12px]">
              <button
                className={"text-gray-900 flex flex-row gap-[4px] "}
                onClick={() => {
                  window.open(`/product/${item?.id}`, "_blank");
                }}
              >
                <img
                  alt="avatar"
                  src={
                    item?.assign?.image_url ||
                    "/assets/images/defaultAvatar.png"
                  }
                  className={"w-[20px] h-[20px] rounded-full object-cover"}
                />
                {upperCase(item?.assign?.name, "word")}
              </button>
            </div>
          </div>
        )}
        {/* numbers here */}
        {searching && results?.results?.length ? (
          <div className="flex justify-end">
            <button
              className="text-blue-700 font-medium text-[14px] leading-normal "
              onClick={() => {
                setSelectedProduct([item]);
                setData({ ...data, products: [item] });
                setSearching(false);
              }}
            >
              Select product
            </button>
          </div>
        ) : (
          <div className="flex justify-between px-4">
            {Object.keys(item?.numbers).map((key, index) => (
              <div key={item?.id} className="flex flex-col gap-0.5 ">
                <p className="text-xs font-bold leading-3 text-gray-600">
                  {key}
                </p>
                <p className="text-xl leading-5 font-bold  text-purple-700">
                  {handleSkeleton(key)}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
ProductItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  results: PropTypes.object,
  searching: PropTypes.bool.isRequired,
  setSelectedProduct: PropTypes.func.isRequired,
  setSearching: PropTypes.func.isRequired,
  selectedProduct: PropTypes.array,
  setData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export const SalesChannelItem = ({
  item,
  index,
  results,
  searching,
  setSelectedProduct,
  setSearching,
  selectedProduct,
  setData,
  data,
  connectLoading,
  selection,
}) => {
  const Trash = Icons.trash;
  return (
    <div
      key={index}
      className={`result-item bg-blue-100 w-[630px]  ${
        searching ? "h-[144px]" : "h-[176px]"
      } flex items-center p-3 mb-2 border shadow-sm rounded-lg`}
    >
      <img
        src={item.image || "/assets/images/noImage.png"}
        alt={item.label}
        className={`result-image ${
          searching
            ? "w-[120px] h-[120px] max-h-[120px]"
            : "w-[152px] h-[152px] max-h-[152px] "
        } object-contain rounded`}
      />
      <div className="result-details flex-1 flex pl-4 flex-col h-full justify-between">
        {/* apid and badge here */}
        <div className="flex justify-between items-center">
          <div className={"flex flex-row gap-[4px]"}>
            <div className=" text-sm text-gray-900 font-normal leading-[18px]  cursor-pointer">
              {item?.identifier}
            </div>
            <CopyText
              className={"text-[12px]"}
              text={item?.identifier}
              hint={false}
            />
            {item?.url && (
              <HiExternalLink
                className="w-4 h-4  text-blue-700 cursor-pointer"
                onClick={() => window.open(item?.url, "_blank")}
              />
            )}
          </div>
          <div className="flex gap-2">
            <div className="w- h-[22px] justify-start items-start inline-flex">
              <div
                className={`px-2.5 py-0.5 ${
                  connectLoading?.[item.id] === "Connected!"
                    ? "bg-green-100"
                    : "bg-orange-100"
                } rounded-md justify-center items-center gap-1 flex`}
              >
                <div
                  className={`text-center whitespace-nowrap ${
                    connectLoading?.[item.id] === "Connected!"
                      ? "text-green-900"
                      : "text-orange-800"
                  }
                                 text-xs font-medium leading-[18px]`}
                >
                  {connectLoading?.[item.id] || "Sales Channel Listing"}
                </div>
              </div>
            </div>
            {!searching && results?.results?.length ? (
              <button
                onClick={() => {
                  setSelectedProduct([
                    ...selectedProduct.filter(
                      (product) => product.id !== item.id
                    ),
                  ]);
                  setData({
                    ...data,
                    salesChannels: [
                      ...selectedProduct.filter(
                        (product) => product.id !== item.id
                      ),
                    ],
                  });
                  if (selectedProduct.length === 1) {
                    setSearching(true);
                  }
                }}
              >
                <Trash className="w-5 h-5 text-gray-300 cursor-pointer hover:text-gray-500" />
              </button>
            ) : null}
          </div>
        </div>
        {/* title here */}
        <div>
          <p className="text-[16px] leading-[24px] text-gray-900 font-semibold whitespace-pre-wrap">
            {item?.label?.length > 100
              ? Util.shortenString(item?.label, 100, false, true)
              : item?.label}
          </p>
        </div>
        {/* numbers here */}
        {searching && !selection && results?.results?.length ? (
          <div className="flex justify-end">
            <button
              className={`${
                !selectedProduct?.find((channel) => channel.id === item.id)
                  ? "text-blue-500"
                  : "text-gray-300 cursor-default"
              }`}
              onClick={() => {
                if (selectedProduct?.find((channel) => channel.id === item.id))
                  return;
                setSelectedProduct([...selectedProduct, item]);
                setData({ ...data, salesChannels: [...selectedProduct, item] });
                setSearching(false);
              }}
            >
              {selectedProduct?.find((channel) => channel.id === item.id)
                ? "Selected"
                : "Select Listing"}
            </button>
          </div>
        ) : (
          <div
            className={
              "flex justify-between text-[14px]  font-medium text-gray-900"
            }
          >
            <div className={"flex gap-1 items-center text-gray-900"}>
              <div className=" text-[14px] text-gray-900 font-medium leading-[21px]  ">
                {item?.brand}
              </div>
            </div>

            <div className="flex items-center gap-[2px] ">{item?.channel}</div>
          </div>
        )}
      </div>
    </div>
  );
};
SalesChannelItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  results: PropTypes.object,
  searching: PropTypes.bool.isRequired,
  setSelectedProduct: PropTypes.func.isRequired,
  setSearching: PropTypes.func.isRequired,
  selectedProduct: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  connectLoading: PropTypes.object,
  selection: PropTypes.bool,
};
const Info = Icons.info;
const productTooltip = (
  <>
    <span className="flex ">
      <Info
        className="w-5 h-5  text-gray-300 hover:text-gray-500 "
        data-tooltip-id="productTooltip"
      />
    </span>
    <Tooltip
      id="productTooltip"
      place="top"
      positionStrategy="fixed"
      className="z-[99999] bg-white !opacity-100"
      style={{
        backgroundColor: "white",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        borderRadius: "4px",
      }}
    >
      <div className="flex flex-col gap-1.5 w-[250px]">
        <p className="text-[14px] font-medium text-gray-900">Select Product</p>
        <p className="text-xs text-gray-600 whitespace-pre-wrap">
          Select a product to connect listing(s) and access detailed supply
          costs and inventory levels.
        </p>
      </div>
    </Tooltip>
  </>
);

const salesTooltip = (
  <>
    <span className="flex ">
      <Info
        className="w-5 h-5  text-gray-300 hover:text-gray-500"
        data-tooltip-id="my-tooltip"
      />
    </span>
    <Tooltip
      id="my-tooltip"
      place="top"
      positionStrategy="fixed"
      className="z-[99999] bg-white !opacity-100"
      style={{
        backgroundColor: "white",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        borderRadius: "4px",
      }}
    >
      <div className="flex flex-col gap-1.5 w-[250px]">
        <p className="text-[14px] font-medium text-gray-900">
          Select Listing(s)
        </p>
        <p className="text-xs text-gray-600 whitespace-pre-wrap">
          Please select one or more listings to connect to the product.
        </p>
      </div>
    </Tooltip>
  </>
);
