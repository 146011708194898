import React from "react";
import { howLongFromNow } from "../../../utils/helper";
import moment from "moment";

export default function dateDisplay({
  item,
  className,
  date,
  secondDate,
  tooltip,
  text,
}) {
  const nullHandle = (data, chained) => {
    if (!data || chained == null) {
      return "-";
    } else {
      return chained;
    }
  };

  let result;

  if (moment(date).fromNow() === "a few seconds ago") {
    result = text || "Just now";
  } else {
    result = howLongFromNow(date, true, secondDate, true, text);
  }

  return date ? (
    <div
      className={`${
        className || "h-[60px] py-2 "
      }  w-[124px] flex-col justify-center items-start  inline-flex`}
    >
      <div className="justify-center items-center gap-1 inline-flex">
        <div className="text-[#111928] text-sm font-medium font-['Inter'] leading-[14px]">
          {moment(nullHandle(item, date)).format("MMM D, YYYY")}
        </div>
      </div>
      <div className="self-stretch justify-start items-center gap-1 inline-flex">
        <div className="grow shrink basis-0 text-gray-500 text-xs font-normal font-['Inter'] leading-[18px]">
          {moment(nullHandle(item, date)).format("h:mm a")}
        </div>
        {tooltip ? (
          <div className="flex items-center gap-1">
            <span className="text-gray-500 text-[12px] leading-[21px] font-medium">
              {howLongFromNow(date, true, secondDate, true, text).replace(
                " ago",
                ""
              )}
            </span>
            {tooltip}
          </div>
        ) : (
          <div className="text-[#057a55] text-xs font-medium font-['Inter'] leading-[18px]">
            {result}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className=" flex-col justify-center !px-[8px] !py-[12px] items-start gap-1 inline-flex">
      -
    </div>
  );
}
