import React from "react";
import PropTypes from "prop-types";
import PopupUp from "../../dynamic-page/util/popUp";
import icons from "../../orders/icons";
import priceHandler from "../../../utils/priceHandler";

const Calculator = icons.calculator;

const Toggle = (selected) => (
  <Calculator
    className={`text-gray-300 hover:text-gray-500 h-[12px] ${
      selected && "!text-gray-500"
    }`}
  />
);

function RepricerPricing({ type, repricer, price }) {
  const tooltipContent = (type) => {
    let content;
    if (type === "fba_min") {
      content = {
        title: "FBA Min. Sell Price Calculation",
        content: (
          <span>
            Sales Price = (Product Cost + Shipping Cost + FBA Fee + Closing Fee
            + Other Fees except Referral Fee) / (1 - Min. Target Margin % -{" "}
            <span className="font-bold underline">Referral Fee%</span> )
          </span>
        ),
      };
    }
    if (type === "fba_max") {
      content = {
        title: "FBA Max. Sell Price Calculation",
        content: (
          <span>
            Sales Price = (Product Cost + Shipping Cost + FBA Fee + Closing Fee
            + Other Fees except Referral Fee) / (1 - Max. Target Margin % -{" "}
            <span className="font-bold underline">Referral Fee%</span> )
          </span>
        ),
      };
    }
    if (type === "fbm_min") {
      content = {
        title: "FBM Min. Sell Price Calculation",
        content: (
          <span>
            Sales Price = (Product Cost + Shipping Cost + Closing Fee + Other
            Fees except Referral Fee) / (1 - Min. Target Margin % -{" "}
            <span className="font-bold underline">Referral Fee%</span> )
          </span>
        ),
      };
    }
    if (type === "fbm_max") {
      content = {
        title: "FBM Max. Sell Price Calculation",
        content: (
          <span>
            Sales Price = (Product Cost + Shipping Cost + Closing Fee + Other
            Fees except Referral Fee) / (1 - Max. Target Margin % -{" "}
            <span className="font-bold underline">Referral Fee%</span> )
          </span>
        ),
      };
    }
    return content;
  };

  const tooltip = (type) => {
    const content = tooltipContent(type);

    return (
      <PopupUp toggle={Toggle} action={"hover"}>
        <div className="px-4 py-2 flex gap-2 flex-col">
          <div className="text-gray-600 text-[16px] font-semibold">
            {content.title}
          </div>
          <div className="text-gray-600 text-[14px] font-normal">
            {content.content}
          </div>
        </div>
      </PopupUp>
    );
  };

  
  const getMinPrice = () => {
  const isMargin = repricer?.price_type === "MARGIN";
  const isFba = type === "fba";
    const price_fba = isMargin ? repricer?.fba_price_min_usd : repricer?.fba_price_min;
    const price_fbm = isMargin ? repricer?.fbm_price_min_usd : repricer?.fbm_price_min;
    const price = isFba ? price_fba : price_fbm;  
    return price ? `$${priceHandler(price)}` : "-";
  };

  const getSecondaryMinPrice = () => {
    const isFba = type === "fba";
    const isMargin = repricer?.price_type === "MARGIN";
    const priceMinPercentageFba = isMargin ? repricer?.fba_price_min : repricer?.fba_price_min_percentage;
    const priceMinPercentageFbm = isMargin ? repricer?.fbm_price_min : repricer?.fbm_price_min_percentage;
    const price = isFba ? priceMinPercentageFba : priceMinPercentageFbm;
    return price ? `${price}%` : "-";
  };

  const getMaxPrice = () => {
    const isMargin = repricer?.price_type === "MARGIN";
    const isFba = type === "fba";
    const price_fba = isMargin ? repricer?.fba_price_max_usd : repricer?.fba_price_max;
    const price_fbm = isMargin ? repricer?.fbm_price_max_usd : repricer?.fbm_price_max;
    const price = isFba ? price_fba : price_fbm;
    return price ? `$${priceHandler(price)}` : "-";
  };
    


  const getSecondaryMaxPrice = () => {
    const isFba = type === "fba";
    const isMargin = repricer?.price_type === "MARGIN";
    const priceMaxPercentageFba = isMargin ? repricer?.fba_price_max : repricer?.fba_price_max_percentage;
    const priceMaxPercentageFbm = isMargin ? repricer?.fbm_price_max : repricer?.fbm_price_max_percentage;
    const price = isFba ? priceMaxPercentageFba : priceMaxPercentageFbm;
    return price ? `${price}%` : "-";
  };

  const getMainPrice = () => {
    return price ? "$" + priceHandler(price) : "-";
  };

  return (
    <div className=" gap-2 flex-col justify-between items-center box-border inline-flex">
      <div
        className={`px-[7px] py-[5px]  h-[74px] rounded-lg box-border border-2 ${
          type === "fbm" ? "border-[#C3DDFD]" : "border-orange-200"
        } justify-center items-center inline-flex`}
      >
        <div className="w-24 flex-col justify-center items-start gap-[2px] inline-flex">
          <div className="flex-col justify-center items-start gap-0.5 flex">
            <div className="text-gray-400 text-[12px] font-normal font-['Inter'] !leading-[12px]">
              MIN.
            </div>
            <div className="justify-end items-center gap-0.5 inline-flex">
              <div className="text-gray-600 text-[18px] font-medium font-['Inter'] !leading-[18px]">
                {getMinPrice()}
              </div>
            </div>
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="justify-center items-center gap-1 flex">
                <div className="text-center text-gray-600 text-[14px] font-normal font-['Inter'] !leading-[14px]">
                  {getSecondaryMinPrice()}
                </div>
                {getSecondaryMinPrice() !== "-" && tooltip(`${type}_min`)}
              </div>
            </div>
          </div>
        </div>
        <div className="min-w-[76px] flex-col self-center justify-center items-center gap-[2px] inline-flex">
          <div className="text-gray-400 text-[12px] font-normal font-['Inter'] !leading-[12px]">
            YOUR PRICE
          </div>
          <div className="flex-col justify-center items-center gap-0.5 flex">
            <div className="justify-end items-center gap-0.5 inline-flex">
              <div
                className={`text-center text-blue-700 text-2xl font-bold font-['Inter'] !leading-[24px] `}
              >
                {getMainPrice()}
              </div>
            </div>
            {/* <div className="self-stretch justify-center items-center inline-flex">
              <div className="justify-center items-center gap-0.5 flex">
                <div
                  className={`text-center ${
                    type === "fba" ? "text-orange-700" : "text-blue-700"
                  } text-sm font-medium font-['Inter'] !leading-[14px]`}
                >
                  12.3%
                </div>
              </div>
            </div> */}
          </div>
          <div className="text-[14px] font-normal font-['Inter'] !leading-[14px] text-blue-700">
            -
          </div>
        </div>
        <div className="w-24 flex-col justify-center items-end gap-[2px] inline-flex">
          <div className="flex-col justify-end  text-end items-end gap-0.5 flex">
            <div className="text-gray-400 text-[12px] font-normal font-['Inter'] !leading-[12px]">
              MAX.
            </div>
            <div className="justify-end items-center gap-0.5 inline-flex">
              <div className="text-center text-gray-600 text-[18px] font-medium font-['Inter'] !leading-[18px]">
                {getMaxPrice()}
              </div>
            </div>
            <div className="self-stretch justify-end items-end inline-flex">
              <div className="justify-end items-end gap-1 flex">
                <div className="text-end text-gray-600 text-[14px] font-normal font-['Inter'] !leading-[14px]">
                  {getSecondaryMaxPrice()}
                </div>
                {getSecondaryMaxPrice() !== "-" && tooltip(`${type}_max`)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
RepricerPricing.propTypes = {
  type: PropTypes.string.isRequired,
  price: PropTypes.string,
  repricer: PropTypes.shape({
    fba_price_min: PropTypes.number,
    fbm_price_min: PropTypes.number,
    fba_price_max: PropTypes.number,
    fbm_price_max: PropTypes.number,
    price_type: PropTypes.string,
    fba_price_min_usd: PropTypes.number,
    fba_price_min_percentage: PropTypes.number,
    fbm_price_min_usd: PropTypes.number,
    fbm_price_min_percentage: PropTypes.number,
    fba_price_max_usd: PropTypes.number,
    fba_price_max_percentage: PropTypes.number,
    fbm_price_max_usd: PropTypes.number,
    fbm_price_max_percentage: PropTypes.number,
    fba_price: PropTypes.number,
    fbm_price: PropTypes.number,
  }).isRequired,
};

export default RepricerPricing;
