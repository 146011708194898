/**
 * @author Austin
 */
import classNames from "classnames";
import PropTypes from "prop-types";

const ArbitToggle = ({ status, bgColor, handleFulfilment }) => {
  return (
    <button
      className={classNames(
        status ? " justify-end " : " justify-start",
        `w-[40px] h-[20px] flex items-center rounded-[40px] px-[2px] cursor-pointer ${bgColor}`
      )}
      onClick={() => {
        handleFulfilment();
      }}
    >
      <div className="w-[16px] h-[16px] rounded-[40px] bg-white"></div>
    </button>
  );
};
ArbitToggle.propTypes = {
  status: PropTypes.bool,
  bgColor: PropTypes.string,
  handleFulfilment: PropTypes.func.isRequired,
};

ArbitToggle.defaultProps = {
  status: true,
  bgColor: "orange",
};

export default ArbitToggle;
