/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Austin Ames
 * @version 0.1.0
 * @description Product Page with Title, Toolbar, Tabs
 * @created 7/06/2022
 * @updated 7/06/2022
 * @returns Product Page Component
 */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { HiPlus } from "react-icons/hi";

import {
  fetchAttributeGroups,
  fetchProduct,
  getProductLocale,
} from "../../store/products";
import ArbitProductPage from "../sub-elements/arbit-product-page";
import ProductHeader from "./product-header";
import ProductDetail from "./product-detail";
import ProductSalesChannel from "./product-sales-channel";
import ProductSupplyChannelPage from "./product-supply-channels";
import ProductInventoryPage from "./product-inventory";

import FilterBuilder from "./product-filter-builder";
import ProductHeaderContentLoader from "../ProductHederContentLoader";
import ProductOrdersPage from "./product-orders";
import ProductInventoryAdjustmentsPage from "./product-inventory-adjustments";
import ProductSupplyPage from "./product-supply";

import UploadImageSkeleton from "./upload-image-skeleton";
import axios from "axios";
import ProductComponents from "./product.components";
import ProductOverview from "./product-overview";
import TabButton from "../dynamic-page/elements/TabButton";
import ProductInventoryPurchases from "./product-inventory-purchases";
import Button from "../dynamic-form/elements/Button";

const ProductComponent = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const attributeGroupsData = useSelector(
    (state) => state.productReducer.attributeGroups
  );
  const ProductData = useSelector((state) => state.productReducer.product);
  const loading = useSelector((state) => state.productReducer.loading);
  const locale = useSelector((state) => state.productReducer.locale);
  const languageLimit = 1000;
  const tabsWithComponent = [
    "Overview",
    "Sales Channels",
    "Supply Channels",
    "Inventory",
    "Orders",
    "Components",
  ];
  const tabsWithProducts = [
    "Overview",
    "Sales Channels",
    "Supply Channels",
    "Inventory",
    "Inventory Movements",
    "Orders",
    "Supply",
    "Grouped Products",
    "Inventory Purchases",
  ];

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const tabFromURL = searchParams.get("tab") || "Overview";
  const [currentTab, setCurrentTab] = useState(tabFromURL);
  const [productLanguage, setProductLanguage] = useState("40");
  const tabsToUse =
    ProductData?.type === 1 ? tabsWithProducts : tabsWithComponent;

  useEffect(() => {
    FilterBuilder.setLocale(productLanguage);
    FilterBuilder.setLimitQuery(languageLimit);

    dispatch(fetchProduct({ id, productLanguage }));
    dispatch(fetchAttributeGroups());
    dispatch(getProductLocale(FilterBuilder.getUrlParams()));
  }, []);

  useEffect(() => {
    if (!searchParams.get("tab")) {
      // Update the URL to include the default tab, replacing the current history entry
      navigate(`?tab=${currentTab}`, { replace: true });
    }
    // We can safely include navigate and searchParams in the dependency array
  }, [navigate, searchParams, currentTab]);

  useEffect(() => {
    const tabFromURL = searchParams.get("tab") || "Overview";
    const latest = tabFromURL === "all" ? "Overview" : tabFromURL;
    setCurrentTab(latest);
  }, [searchParams]);

  const [listingsCount, setListingsCount] = useState({
    sales: 0,
    supply: 0,
    reserved_quantity: 0,
    available_quantity: 0,
    lowestSclPrice: 0,
  });

  const updateListingsCount = (url, type) => {
    axios
      .get(
        `/api/v1/${url}?filter[${
          type === "sales" ? "product" : "product_id"
        }]=${id}`,
        { skipCancel: true }
      )
      .then((res) => {
        const lowestSclPrice = res?.data?.data?.reduce((acc, loc) =>
          Number(acc.price) < Number(loc.price) ? acc : loc
        );

        setListingsCount((prev) => ({
          ...prev,
          [type]: res.data.meta.total,
          reserved_quantity: res.data[0]?.fba_reserved_qty || 0,
          available_quantity: res.data[0]?.fba_available_qty || 0,
          lowestSclPrice: lowestSclPrice || 0,
        }));
      });
  };

  const [tabCounts, setTabCounts] = useState({
    orders: null,
    inventory_purchase: null,
  });

  const getTabsCount = (url, filterName) => {
    if (url === "inventory_purchase") {
      url = "supply-purchase";
    }
    axios
      .get(`/api/v1/${url}?filter[${filterName}]=${id}`, { skipCancel: true })
      .then((res) => {
        if (url === "supply-purchase") {
          url = "inventory_purchase";
        }
        setTabCounts((prev) => ({
          ...prev,
          [url]: res.data.meta.total,
        }));
      });
  };

  useEffect(() => {
    updateListingsCount("account-listings", "sales");
    updateListingsCount("supply-channel-listing", "supply");
    getTabsCount("orders", "product");
    getTabsCount("inventory_purchase", "product_id");
  }, []);

  const changeLanguage = (language) => {
    setProductLanguage(language);
    dispatch(fetchProduct({ id, productLanguage: language }));
  };

  const [showSalesModal, setShowSalesModal] = useState(false);
  const [showSupplyModal, setShowSupplyModal] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);

  const handleTabChange = (event) => {
    const selectedTab = event.target.value;
    setCurrentTab(selectedTab);
    navigate(`?tab=${selectedTab}`);
  };

  const currentTabComponent = () => {
    switch (currentTab) {
      case "Overview":
        return (
          <ProductOverview
            loading={loading}
            productData={ProductData}
            id={id}
            locale={locale}
            productLanguage={productLanguage}
            setProductLanguage={changeLanguage}
          />
          // <ProductDetail
          //   loading={loading}
          //   productData={ProductData}
          //   id={id}
          //   locale={locale}
          //   productLanguage={productLanguage}
          //   setProductLanguage={changeLanguage}
          // />
        );
      case "Inventory":
        return (
          <ProductInventoryPage
            loading={loading}
            product_id={id}
            inventory={ProductData?.inventory_detail}
          />
        );
      case "Inventory Movements":
        return <ProductInventoryAdjustmentsPage product_id={id} />;
      case "Sales Channels":
        return (
          <ProductSalesChannel
            updateListingsCount={updateListingsCount}
            showSalesModal={showSalesModal}
            setShowSalesModal={setShowSalesModal}
            product_id={id}
          />
        );

      case "Supply Channels":
        return (
          <ProductSupplyChannelPage
            updateListingsCount={updateListingsCount}
            showSupplyModal={showSupplyModal}
            setShowSupplyModal={setShowSupplyModal}
            product_id={id}
          />
        );
      case "Orders":
        return <ProductOrdersPage product_id={id} />;
      case "Grouped Products":
        return (
          <ProductComponents
            product_id={id}
            type={2}
            productData={ProductData}
            showProductModal={showProductModal}
            setShowProductModal={setShowProductModal}
          />
        );
      case "Inventory Purchases":
        return <ProductInventoryPurchases product_id={id} />;
      case "Components":
        return (
          <ProductComponents
            product_id={id}
            type={1}
            productData={ProductData}
            showProductModal={showProductModal}
            setShowProductModal={setShowProductModal}
          />
        ); // load products of type 1 (single)
      case "Supply":
        return <ProductSupplyPage product_id={id} />;
      // case "Shipping":
      //   return <ProductShippingPage />
      default:
        return (
          <ProductDetail productData={ProductData} id={id} locale={locale} />
        );
    }
  };

  return (
    <ArbitProductPage.Page className="flex flex-col flex-grow pt-2 h-[calc(100vh-64px)]">
      <ArbitProductPage.Toolbar height="200px" className="flex-[0_0_auto]">
        <ArbitProductPage.ToolbarLeft width="full">
          {/* <ProductHeader datas={ProductData} groupData={attributeGroupsData} id={id} productLanguage={productLanguage} /> */}
          {loading === "pending" ? (
            <ProductHeaderContentLoader />
          ) : (
            <ProductHeader
              listingCount={listingsCount}
              datas={ProductData}
              groupData={attributeGroupsData}
              id={id}
              productLanguage={productLanguage}
            />
          )}
        </ArbitProductPage.ToolbarLeft>
      </ArbitProductPage.Toolbar>
      <div
        className={`flex sm:justify-between h-[36px] sm:px-0 py-9   md:py-0   sm:gap-0 gap-3 items-center  flex-[0_0_auto] `}
      >
        <ArbitProductPage.Tab>
          {loading === "idle" && (
            <div className="w-full">
              <div className=" hidden md:flex gap-4">
                <TabButton
                  mainClassName={"!gap-5"}
                  active={currentTab}
                  list={tabsToUse.map((item) => {
                    let count;

                    if (item === "Sales Channels") {
                      count = listingsCount.sales;
                    } else if (item === "Supply Channels") {
                      count = listingsCount.supply;
                    } else if (item === "Orders") {
                      count = tabCounts.orders;
                    } else if (item === "Inventory Purchases") {
                      count = tabCounts.inventory_purchase;
                    } else if (item === "Grouped Products") {
                      count = ProductData?.parent_products?.length;
                    } else if (item === "Components") {
                      count = ProductData?.child_products?.length;
                    } else {
                      count = null;
                    }
                    return {
                      id: item,
                      name: item,
                      count: count,
                    };
                  })}
                  callBack={(item) => {
                    setCurrentTab(item);
                    navigate(`?tab=${item}`);
                  }}
                />
              </div>
              <div className="w-full flex  md:hidden  ">
                <select
                  className="w-full focus:border-blue-300 rounded-[12px] text-[14px] font-medium text-gray-900"
                  value={currentTab}
                  onChange={handleTabChange}
                >
                  {tabsToUse.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </ArbitProductPage.Tab>
        {(currentTab === "Sales Channels" ||
          currentTab === "Supply Channels" ||
          currentTab === "Components") && (
          <Button
            item={{
              label: <HiPlus className="h-6 w-6" />,
              buttonType: "icon",
              className: "order-[0]",
            }}
            onClick={() => {
              if (currentTab === "Sales Channels") {
                setShowSalesModal(true);
              }
              if (currentTab === "Supply Channels") {
                setShowSupplyModal(true);
              }
              if (currentTab === "Components") {
                setShowProductModal(true);
              }
            }}
          />
        )}
      </div>
      {currentTab !== "Overview" && ""}

      <ArbitProductPage.Content
        className={`${
          currentTab === "Components" && "-mt-[50px"
        } flex-[1_1_auto] overflow-hidden`}
      >
        {loading === "pending" ? (
          <UploadImageSkeleton />
        ) : (
          currentTabComponent()
        )}
      </ArbitProductPage.Content>
    </ArbitProductPage.Page>
  );
};

export default ProductComponent;
