import RepricerToggle from "../components/repricerToggle";
import * as Yup from "yup";

const formView = (formDefaultValue, param) => {
  const { params, auto_kickoff } = param;
  const isParams = params;
  const defaultValue = getDefaultValue(
    formDefaultValue,
    isParams,
    auto_kickoff
  );

  function getDefaultValue(formDefaultValue, isParams, auto_kickoff) {
    if (isParams) {
      return formDefaultValue?.auto_kickoff;
    } else if (formDefaultValue?.id) {
      return formDefaultValue?.set_repricing_rule;
    }
    return auto_kickoff ? true : null;
  }

  function getFirstField(isParams, defaultValue) {
    return {
      name: isParams ? "auto_kickoff" : "set_repricing_rule",
      label: isParams
        ? "Start repricing automatically for eligible listings"
        : "Use Default Repricing Rule",
      description: isParams
        ? "The repricing process starts automatically for all listings with product connection, cost and fees using the default rule."
        : null,
      labelClassName: "hidden",
      placeholder: "blach",
      type: "Module",
      toggleDescriptionClassName: isParams
        ? "!flex-col !items-start !gap-[2px]"
        : "",
      toggleClassName: isParams ? "!items-start" : "",
      module: RepricerToggle,
      className: "col-span-12",
      defaultValue: defaultValue,
    };
  }

  function validateRequired(message) {
    return Yup.string().required(message);
  }

  function getCondition(dataField) {
    return (data) => !data[dataField];
  }

  function getPriceLabel(dataField, prefix) {
    return (data) =>
      data[dataField] === "MARGIN"
        ? `${prefix} Profit Margin`
        : `${prefix} Price`;
  }

  function getLabelDescription(dataField) {
    return (data) => (data[dataField] === "MARGIN" ? "%" : "$");
  }

  function getValidation(dataField, message) {
    return (data) =>
      !data.set_repricing_rule ? validateRequired(message) : Yup.string();
  }

  function getField({
    name,
    label,
    type,
    validation,
    condition,
    defaultValue,
    className = "col-span-12",
    ...rest
  }) {
    return {
      name,
      label,
      type,
      validation,
      condition,
      defaultValue,
      className,
      ...rest,
    };
  }

  const fields = [
    getFirstField(isParams, defaultValue),
    getField({
      name: "price_type",
      label: "Min. / Max. Profit Type",
      type: "RadioButton",
      validation: getValidation("price_type", "Profit Type is Required"),
      condition: getCondition("set_repricing_rule"),
      defaultValue: formDefaultValue?.price_type,
      outerClassName: "!gap-[24px]",
      list: [
        {
          label: "Percentage",
          value: "MARGIN",
          description: "Profit margin target",
        },
        { label: "Fixed", value: "FIXED", description: "Between fixed prices" },
      ],
    }),
    getField({
      name: "fbm_rule_type",
      label: "FBM Repricing Action",
      type: "RadioButton",
      validation: getValidation(
        "fbm_rule_type",
        "Repricing Action is Required"
      ),
      condition: getCondition("set_repricing_rule"),
      defaultValue: formDefaultValue?.fbm_rule_type,
      outerClassName: "!gap-[24px]",
      list: [
        {
          label: "Match",
          value: "match",
          description: "To the competition price",
        },
        {
          label: "Below",
          value: "below",
          description: "Of the competition price",
        },
        {
          label: "Above",
          value: "above",
          description: "Of the competition price",
        },
      ],
    }),
    getField({
      name: "fbm_price_min",
      label: getPriceLabel("price_type", "FBM Min."),
      type: "Number",
      validation: getValidation("fbm_price_min", "Min Price is Required"),
      condition: getCondition("set_repricing_rule"),
      defaultValue: formDefaultValue?.fbm_price_min || null,
      className: "col-span-4",
      innerClassName: "border border-gray-300 bg-gray-50",
      labelDiscription: getLabelDescription("price_type"),
    }),
    getField({
      name: "fbm_price_max",
      label: getPriceLabel("price_type", "FBM Max."),
      type: "Number",
      validation: getValidation("fbm_price_max", "Max Price is Required"),
      condition: getCondition("set_repricing_rule"),
      defaultValue: formDefaultValue?.fbm_price_max || null,
      className: "col-span-4",
      innerClassName: "border border-gray-300 bg-gray-50",
      labelDiscription: getLabelDescription("price_type"),
    }),
    getField({
      name: "fbm_rule_value_type",
      label: (data) =>
        data.fbm_rule_type === "below"
          ? "FBM Below Value Type"
          : "FBM Above Value Type",
      type: "Select",
      validation: (data) =>
        !data.set_repricing_rule &&
        ["below", "above"].includes(data.fbm_rule_type)
          ? validateRequired("Value Type is Required")
          : Yup.string(),
      condition: (data) =>
        !data.set_repricing_rule &&
        ["below", "above"].includes(data.fbm_rule_type),
      defaultValue: formDefaultValue?.fbm_rule_value_type || null,
      className: "col-span-6 mb-1",
      labelClassName: "!text-gray-900 !font-medium",
      style: {
        control: {
          height: "42px !important",
          minHeight: "auto",
          width: "100% !important",
          background: "#F9FAFB",
          border: "1px solid #D1D5DB !important",
        },
        menu: {
          whiteSpace: "wrap !important",
        },
      },
      list: [
        { label: "Percentage", value: "MARGIN" },
        { label: "Fixed", value: "FIXED" },
      ],
    }),
    getField({
      name: "fbm_rule_value",
      label: (data) =>
        data.fbm_rule_value_type === "MARGIN" ? "FBM Percentage" : "FBM Value",
      type: "Number",
      validation: (data) =>
        !data.set_repricing_rule && data.fbm_rule_value_type
          ? validateRequired("Value is Required")
          : Yup.string(),
      condition: (data) =>
        !data.set_repricing_rule &&
        data.fbm_rule_value_type &&
        ["below", "above"].includes(data.fbm_rule_type),
      defaultValue: formDefaultValue?.fbm_rule_value || null,
      className: "col-span-4",
      disabled: (data) => !data.fbm_rule_value_type,
      innerClassName: "border border-gray-300 bg-gray-50",
      labelDiscription: getLabelDescription("fbm_rule_value_type"),
    }),
    getField({
      name: "fba_rule_type",
      label: "FBA Repricing Action",
      type: "RadioButton",
      validation: getValidation(
        "fba_rule_type",
        "Repricing Action is Required"
      ),
      condition: getCondition("set_repricing_rule"),
      defaultValue: formDefaultValue?.fba_rule_type,
      outerClassName: "!gap-[24px]",
      list: [
        {
          label: "Match",
          value: "match",
          description: "To the competition price",
        },
        {
          label: "Below",
          value: "below",
          description: "Of the competition price",
        },
        {
          label: "Above",
          value: "above",
          description: "Of the competition price",
        },
      ],
    }),
    getField({
      name: "fba_price_min",
      label: getPriceLabel("price_type", "FBA Min."),
      type: "Number",
      validation: getValidation("fba_price_min", "Min Price is Required"),
      condition: getCondition("set_repricing_rule"),
      defaultValue: formDefaultValue?.fba_price_min || null,
      className: "col-span-4",
      innerClassName: "border border-gray-300 bg-gray-50",
      labelDiscription: getLabelDescription("price_type"),
    }),
    getField({
      name: "fba_price_max",
      label: getPriceLabel("price_type", "FBA Max."),
      type: "Number",
      validation: getValidation("fba_price_max", "Max Price is Required"),
      condition: getCondition("set_repricing_rule"),
      defaultValue: formDefaultValue?.fba_price_max || null,
      className: "col-span-4",
      innerClassName: "border border-gray-300 bg-gray-50",
      labelDiscription: getLabelDescription("price_type"),
    }),
    getField({
      name: "fba_rule_value_type",
      label: (data) =>
        data.fba_rule_type === "below"
          ? "FBA Below Value Type"
          : "FBA Above Value Type",
      type: "Select",
      validation: (data) =>
        !data.set_repricing_rule &&
        ["below", "above"].includes(data.fba_rule_type)
          ? validateRequired("Value Type is Required")
          : Yup.string(),
      condition: (data) =>
        !data.set_repricing_rule &&
        ["below", "above"].includes(data.fba_rule_type),
      defaultValue: formDefaultValue?.fba_rule_value_type || null,
      className: "col-span-6",
      labelClassName: "!text-gray-900 !font-medium",
      style: {
        control: {
          height: "42px !important",
          minHeight: "auto",
          width: "100% !important",
          background: "#F9FAFB",
          border: "1px solid #D1D5DB !important",
        },
        menu: {
          whiteSpace: "wrap !important",
        },
      },
      list: [
        { label: "Percentage", value: "MARGIN" },
        { label: "Fixed", value: "FIXED" },
      ],
    }),
    getField({
      name: "fba_rule_value",
      label: (data) =>
        data.fba_rule_value_type === "MARGIN" ? "FBA Percentage" : "FBA Value",
      type: "Number",
      validation: (data) =>
        !data.set_repricing_rule && data.fba_rule_value_type
          ? validateRequired("Value is Required")
          : Yup.string(),
      condition: (data) =>
        !data.set_repricing_rule &&
        data.fba_rule_value_type &&
        ["below", "above"].includes(data.fba_rule_type),
      defaultValue: formDefaultValue?.fba_rule_value || null,
      className: "col-span-4",
      disabled: (data) => !data.fba_rule_value_type,
      innerClassName: "border border-gray-300 bg-gray-50",
      labelDiscription: getLabelDescription("fba_rule_value_type"),
    }),
  ];

  return fields;
};

export default formView;
