import React from "react";
import ContentLoader from "react-content-loader";

const GithubProfile = (props) => {
  return (
    <ContentLoader
      height={300}
      width={1500}
      viewBox="0 0 1500 300"
      backgroundColor="#f5f5f5"
      foregroundColor="#dbdbdb"
      {...props}
    >
      <rect x="50" y="10" rx="3" ry="3" width="200" height="200" />

      <rect x="300" y="2" rx="3" ry="3" width="300" height="30" />

      <rect x="300" y="40" rx="3" ry="20" width="260" height="75" />
      <rect x="600" y="40" rx="3" ry="3" width="260" height="75" />
      <rect x="900" y="40" rx="3" ry="3" width="260" height="75" />
      <rect x="1200" y="40" rx="3" ry="3" width="260" height="75" />
      <rect x="1500" y="40" rx="3" ry="3" width="260" height="75" />

      <rect x="300" y="130" rx="3" ry="3" width="260" height="75" />
      <rect x="600" y="130" rx="3" ry="3" width="260" height="75" />
      <rect x="900" y="130" rx="3" ry="3" width="260" height="75" />
      <rect x="1200" y="130" rx="3" ry="3" width="260" height="75" />
      <rect x="1500" y="130" rx="3" ry="3" width="260" height="75" />
    </ContentLoader>
  );
};

GithubProfile.metadata = {
  name: "Nikhil Anand", // My name
  github: "anandnkhl", // Github username
  description: "Latest Github Profile", // Little tagline
  filename: "GithubProfile", // filename of your loaderr
};

export default GithubProfile;
