import Icons from "../../orders/icons";
import PopupUp from "../../dynamic-page/util/popUp";
import Button from "../../dynamic-form/elements/Button";
import InternalNote from "../../sales-listing/components/internalNote";
import PropTypes from "prop-types";

const Exc = Icons.exclamationFill;


const Toggle = (selected) => (
  <Exc className="w-[20px] h-[20px] text-red-600" />
)


const OrderRowAction = ({ actions, callBack, item, defaultSettings }) => {
  const purpleButton = () => {
    // item.repricer veya defaultSettings tanımlı değilse boş bir string döndür
    if (!item?.repricer || !defaultSettings) {
      return "";
    }

    const excludedKeys = ["_id", "updated_at", "created_at", "auto_kickoff"];

    // Karşılaştırılacak anahtarları filtrele
    const keysToCheck = Object.keys(defaultSettings).filter(
      (key) => !excludedKeys.includes(key)
    );

    const allMatch = keysToCheck.every((key) => {
      const defaultValue = defaultSettings[key];
      const repricerValue = item.repricer[key];

      if (typeof defaultValue === "number") {
        return parseFloat(repricerValue) === defaultValue;
      } else {
        return String(repricerValue) === String(defaultValue);
      }
    });

    // Eğer tüm değerler eşleşiyorsa, "text-purple-600 hover:text-purple-400" döndür
    return allMatch ? "text-purple-600 hover:text-purple-400" : "";
  };

  const activeStyle = (status) => {
    if (status) {
      return "!text-blue-500";
    } else {
      return "text-gray-300 hover:text-gray-500";
    }
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <div className={"grid grid-cols-2 grid-rows-2 "}>
        <div className="ml-1">
          <Button
            onClick={() => callBack({ action: "priceHistory", data: item })}
            item={{
              title: !item?.repricer ? "No Repricer" : null,
              disabled: !item?.repricer,
              buttonType: "flat",
            }}
          >
            <Icons.RepricerNote className="w-[20px] h-[20px] text-gray-300 hover:text-gray-500 " />
          </Button>
        </div>
        <button
          onClick={() => {
            callBack({ action: "ruleSettings", data: item });
            //window.open(`/`, '_blank')
          }}
        >
          <Icons.Cog
            className={`w-[20px] h-[20px] text-gray-300 hover:text-gray-500 ${purpleButton()} `}
          />
        </button>
        <div className={"flex justify-center items-center"}>
          <PopupUp
            toggle={(selected) => (
              <div className={"relative -z-1"}>
                <Button
                  item={{
                    buttonType: "flat",
                    className: `p-[2px]  ${activeStyle(
                      actions?.internal_notes.length > 0
                    )}`,
                  }}
                >
                  <Icons.InternalNote className="w-[16px] h-[16px] " />
                </Button>
                {actions?.internal_notes.length > 0 && (
                  <div
                    className={
                      "absolute top-[-3px] right-[-3px] bg-white text-blue-500 border border-blue-500 flex text-[8px] min-w-[14px] px-[3px] justify-center items-center font-black rounded-full"
                    }
                  >
                    {actions?.internal_notes.length}
                  </div>
                )}
              </div>
            )}
          >
            <div className={"w-[525px]"}>
              <InternalNote
                notes={actions?.internal_notes}
                id={
                  actions?.item_id
                    ? `${actions?.order_id}/${actions?.item_id}`
                    : actions?.order_id
                }
              />
            </div>
          </PopupUp>
        </div>
        <div>
          <button
            onClick={() =>
              window.open(item?.sales_channel_listing?.url, "_blank")
            }
          >
            <Icons.GoToListingView className="w-[20px] h-[20px] text-gray-300 hover:text-gray-500 " />
          </button>
        </div>
      </div>
      {!item?.sales_channel_listing?.available ? (
        <div className="w-5">
          <PopupUp
            toggle={Toggle}
            outerClassName="justify-center"
            action="hover"
          >
            <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
              Unavailable
            </div>
          </PopupUp>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

OrderRowAction.propTypes = {
  actions: PropTypes.shape({
    shipping_content: PropTypes.any,
    gtip: PropTypes.any,
    item_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    order_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    internal_notes: PropTypes.array,
  }).isRequired,
  callBack: PropTypes.func.isRequired,
  item: PropTypes.shape({
    repricer: PropTypes.object, // item.repricer'ı kontrol ettiğiniz için ekledim
    sales_channel_listing: PropTypes.shape({
      url: PropTypes.string,
      available: PropTypes.number,
    }),
  }).isRequired,
  defaultSettings: PropTypes.object.isRequired, // defaultSettings prop'unu ekledik
};
export default OrderRowAction;
