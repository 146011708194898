import React from "react";

import { Tooltip } from "react-tooltip";
import PopupUp from "./popUp";

const shortenString = (
  string,
  maxLength,
  noTooltip,
  customTooltip,
  tooltipClassName,
  extraText,
  place = "bottom"
) => {
  if (!string) return "";
  if (string?.length <= maxLength) {
    return string;
  }

  const shortenedString = string.slice(0, maxLength - 3) + "...";

  if (noTooltip) {
    return <>{shortenedString}</>;
  }

  if (customTooltip) {
    return (
      <span className="">
        <span data-tooltip-id={string}>
          {extraText}
          {shortenedString}
        </span>
        <Tooltip
          id={string}
          place={place}
          positionStrategy="fixed"
          className="z-[99999] bg-white !opacity-100"
          style={{
            backgroundColor: "white",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
            borderRadius: "4px",
            padding: "0px 0px 0px 0px",
          }}
        >
          <div
            className={`flex flex-col gap-2 py-2 px-3 w-[622px] ${
              tooltipClassName || ""
            }`}
          >
            <span className="text-[14px] leading-[14px] font-medium text-gray-900">
              {string}
            </span>
          </div>
        </Tooltip>
      </span>
    );
  }

  return (
    <PopupUp toggle={(selected) => <>{shortenedString}</>} action={"hover"}>
      <div className="px-[8px] py-[4px] text-[14px] whitespace-pre-wrap max-w-[300px]">
        {string}
      </div>
    </PopupUp>
  );
};

export default shortenString;
