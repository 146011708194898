import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "./elements/Header";
import Row from "./elements/Row";
import TableActions from "./elements/TableActions";
import ClickDropDown from "./elements/ClickDropDown";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

// Loading View
const LoadingView = () => (
  <div
    className="w-full h-full min-h-[400px] gap-2 flex flex-col absolute"
    style={{ width: "100%" }}
  >
    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => {
      const randomOpacity = crypto.getRandomValues(new Uint8Array(1))[0] / 255;
      const randomWidth =
        (crypto.getRandomValues(new Uint32Array(1))[0] % 70) + 30;
      return (
        <div className="px-[40px] py-[10px]" key={_}>
          <div
            className="skeleton transition"
            style={{
              height: "20px",
              opacity: 0.5 + randomOpacity,
              width: `${randomWidth}%`,
              borderRadius: "50px",
              background:
                "linear-gradient(to right, #eeeeee 0%, #dddddd 50%, #eeeeee 100%)",
              animation: "skeleton-pulse 1.2s infinite",
            }}
          />
        </div>
      );
    })}
  </div>
);

// No Data View
const NoDataView = ({ style }) => (
  <div className="w-full p-[16px] box-border">
    <div
      className={`flex flex-col w-[calc(100vw-32px)] text-center py-[50px] rounded-2xl items-center ${style?.noData?.className}`}
    >
      <h1 className="text-3xl text-gray-400 mb-6">No Data Found!</h1>
      <p className="w-[700px] text-gray-400 font-medium">
        Please adjust your search and/or filter settings to find data that
        matches your criteria.
        <br />
        If you haven't set any filters yet and still don't see any data, it's
        possible that no records exist in the system.
      </p>
    </div>
  </div>
);

NoDataView.propTypes = {
  style: PropTypes.shape({
    noData: PropTypes.shape({
      className: PropTypes.string,
    }),
  }),
};

// Context Menu
const ContextMenu = ({
  selectedRow,
  actions,
  clickedItem,
  menuRef,
  lastClickEvent,
  isOpen,
  setIsOpen,
}) => {
  if (selectedRow?.length > 1) {
    return (
      <ClickDropDown
        ref={menuRef}
        lastClickEvent={lastClickEvent}
        itemMenu={actions(selectedRow)}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    );
  }
  if (actions?.(clickedItem)) {
    return (
      <ClickDropDown
        ref={menuRef}
        lastClickEvent={lastClickEvent}
        itemMenu={actions(clickedItem)}
        item={clickedItem}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    );
  }
  return null;
};

ContextMenu.propTypes = {
  selectedRow: PropTypes.array,
  actions: PropTypes.func,
  clickedItem: PropTypes.object,
  menuRef: PropTypes.object,
  lastClickEvent: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

// Table Row
const TableRow = ({
  item,
  rowIndex,
  handleContextMenu,
  extendedTheme,
  style,
}) => {
  const getUpdatedRowClass = () => {
    if (!item._updatedRow) return "";
    return item._updatedColor
      ? ` _updatedRow ${item._updatedColor}`
      : " _updatedRow";
  };

  const rowClass = `${getUpdatedRowClass()} 
    ${item._deletedRow ? " _deletedRow " : ""} 
    h-full overflow-y-scroll hover:bg-gray-100  
    border-b border-b-gray-200 transition  
    ${item.rowSelectedInTable ? "bg-blue-50 hover:bg-blue-100" : ""}`;

  return (
    <tr
      onContextMenu={(event) => handleContextMenu(event, item)}
      key={rowIndex}
      id={`dynamicTableRow_${item.id}`}
      className={rowClass}
    >
      <Row
        data={item}
        rowIndex={rowIndex}
        theme={extendedTheme}
        tableStyle={style}
      />
    </tr>
  );
};

TableRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    _updatedRow: PropTypes.bool,
    _updatedColor: PropTypes.string,
    _deletedRow: PropTypes.bool,
    rowSelectedInTable: PropTypes.bool,
  }).isRequired,
  rowIndex: PropTypes.number.isRequired,
  handleContextMenu: PropTypes.func.isRequired,
  extendedTheme: PropTypes.object.isRequired,
  style: PropTypes.object,
};

// Table Content
const TableContent = ({
  loading,
  innerData,
  handleContextMenu,
  extendedTheme,
  style,
}) => {
  if (loading !== "idle") {
    return <LoadingView style={style} />;
  }
  return (
    <>
      {innerData.list.map((item, rowIndex) => (
        <TableRow
          key={item.id}
          item={item}
          rowIndex={rowIndex}
          handleContextMenu={handleContextMenu}
          extendedTheme={extendedTheme}
          style={style}
        />
      ))}
    </>
  );
};

TableContent.propTypes = {
  loading: PropTypes.string.isRequired,
  innerData: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
      })
    ),
  }).isRequired,
  handleContextMenu: PropTypes.func.isRequired,
  extendedTheme: PropTypes.object.isRequired,
  style: PropTypes.object,
};

const DynamicTable = React.memo(
  ({
    data,
    actions,
    onSelect,
    selectedRow = [],
    loading,
    style = null,
    ver = 0,
    mini,
    meta,
    miniWidth,
    hiddenFields,
    loadMoreData,
  }) => {
    const [state, setState] = useState({
      isOpen: false,
      clickedItem: null,
      lastClickEvent: null,
      isLoaded: false,
      innerData: data,
    });

    const refs = {
      table: useRef(null),
      container: useRef(null),
      loader: useRef(null),
      menu: useRef(null),
    };
    const updateLoaderWidth = () => {
      if (refs.container.current && refs.loader.current && refs.table.current) {
        refs.loader.current.style.width = `${refs.table.current.scrollWidth}px`;
      }
    };

    const checkLoadMoreData = useCallback(() => {
      if (
        refs.table.current &&
        refs.table.current.scrollHeight <=
          refs.container.current.clientHeight &&
        meta?.last_page > meta?.current_page
      ) {
        loadMoreData();
        updateLoaderWidth();
      }
    }, [loadMoreData, meta]);

    useEffect(() => {
      window.addEventListener("resize", checkLoadMoreData);
      return () => {
        window.removeEventListener("resize", checkLoadMoreData);
      };
    }, [checkLoadMoreData]);

    useEffect(() => {
      checkLoadMoreData();
      updateLoaderWidth();
    }, [state.innerData]);

    const handleContextMenu = (event, item) => {
      if (window.getSelection().toString()) return;
      event.preventDefault();
      setState((prev) => ({
        ...prev,
        clickedItem: item,
        isOpen: true,
        lastClickEvent: {
          clientX: event.clientX,
          clientY: event.clientY,
        },
      }));
      if (refs.menu.current) {
        refs.menu.current.style.left = `${event.clientX}px`;
        refs.menu.current.style.top = `${event.clientY}px`;
      }
      document.addEventListener("click", handleHide);
    };

    const handleHide = () => {
      setState((prev) => ({ ...prev, isOpen: false }));
      document.removeEventListener("click", handleHide);
    };
    const handleSelect = (id, select) => {
      let selected = [...(selectedRow || [])];

      if (id === undefined) {
        if (select) {
        
        selected = [...selected, ...data.list.filter((item) => !selected.some((i) => i.id === item.id))];
      } else {
        selected = selected.filter((item) => !data.list.some((i) => i.id === item.id));
      }
      } else if (select) {
        const itemToAdd = data.list.find((item) => item.id === id);
        if (itemToAdd) {
          selected.push(itemToAdd);
        }
      } else {
        const index = selected.findIndex((item) => item.id === id);
        if (index !== -1) {
          selected.splice(index, 1);
        }
      }

      onSelect(selected);
    };

    useEffect(() => {
      const initializedData = {
        ...data,
        list: data.list?.map((item) => ({
          ...item,
          rowSelectedInTable: selectedRow?.some(
            (selected) => selected.id === item.id
          ),
        })),
      };
      setState((prev) => ({
        ...prev,
        innerData: initializedData,
        isLoaded: false,
      }));
    }, [data, selectedRow]);

    const height = mini
      ? `calc(100vh - ${miniWidth || "400"}px)`
      : "calc(100vh - 220px)";
    const tableHeight = style?.table?.style?.height || height;

    const filteredTheme = Object.keys(state.innerData?.theme || {})
      .filter((key) => !hiddenFields?.includes(key))
      .reduce(
        (acc, key) => ({ ...acc, [key]: state.innerData.theme[key] }),
        {}
      );

    const extendedTheme = {
      ...{
        ...(style.hiddenActions && style.hideSelect
          ? {}
          : {
              _actions: (item, index) => (
                <TableActions
                  rowActions={actions}
                  item={item}
                  index={index}
                  hideSelect={style?.hideSelect}
                  hiddenActions={style?.hiddenActions}
                  handleSelect={handleSelect}
                  ver={ver}
                  style={style}
                  allSelected={
                    state.innerData?.list?.length ===
                      state.innerData?.list?.filter(
                        (item) => item?.rowSelectedInTable === true
                      ).length && state.innerData?.list?.length > 0
                  }
                />
              ),
            }),
      },
      ...filteredTheme,
    };

    return (
      <div
        className={`overflow-x-auto ${style?.outerContainer}`}
        id="scrollableDiv"
      >
        <div
          ref={refs.container}
          style={{
            height: tableHeight,
            boxSizing: "border-box",
            width: "fit-content",
            minWidth: "100%",
            ...style?.table?.style,
          }}
          className={`h-full contt ${style?.table?.className}`}
        >
          <ContextMenu
            selectedRow={selectedRow}
            actions={actions}
            clickedItem={state.clickedItem}
            menuRef={refs.menu}
            lastClickEvent={state.lastClickEvent}
            isOpen={state.isOpen}
            setIsOpen={(isOpenValue) =>
              setState((prev) => ({ ...prev, isOpen: isOpenValue }))
            }
          />

          {loadMoreData ? (
            <InfiniteScroll
              dataLength={state.innerData.list?.length || 0}
              next={loadMoreData}
              hasMore={meta?.last_page > meta?.current_page}
              loader={
                loading !== "pending" && (
                  <div
                    ref={refs.loader}
                    className="loader-wrapper"
                    style={{
                      width: refs.table.current?.scrollWidth || "100%",
                    }}
                  >
                    <Skeleton
                      count={3}
                      height={56}
                      style={{
                        maxWidth: "100%",
                        width: "100%",
                      }}
                    />
                  </div>
                )
              }
              style={{ overflow: "unset" }}
              scrollableTarget="scrollableDiv"
            >
              <table
                className="table-auto w-full dynamicTableMain"
                ref={refs.table}
              >
                <thead
                  className="sticky t-[0px] z-[10] l-[0px] bg-gray-50 border-b border-gray-200"
                  style={{ top: 0 }}
                >
                  <tr className="py-3">
                    <Header theme={extendedTheme} tableStyle={style} />
                  </tr>
                </thead>
                <tbody className={style?.tbody?.className || ""}>
                  <TableContent
                    loading={loading}
                    innerData={state.innerData}
                    handleContextMenu={handleContextMenu}
                    extendedTheme={extendedTheme}
                    style={style}
                  />
                </tbody>
              </table>
            </InfiniteScroll>
          ) : (
            <table
              className="table-auto w-full dynamicTableMain"
              ref={refs.table}
            >
              <thead
                className="sticky t-[0px] z-[10] l-[0px] bg-gray-50 border-b border-gray-200"
                style={{ top: 0 }}
              >
                <tr className="py-3">
                  <Header theme={extendedTheme} tableStyle={style} />
                </tr>
              </thead>
              <tbody className={style?.tbody?.className || ""}>
                <TableContent
                  loading={loading}
                  innerData={state.innerData}
                  handleContextMenu={handleContextMenu}
                  extendedTheme={extendedTheme}
                  style={style}
                />
              </tbody>
            </table>
          )}
          {
            state.innerData?.list?.length === 0 && loading === "idle" && <NoDataView style={style} />
          }
        </div>
        <style>
          {`
                            ._updatedRow {
                                background-color: #DEF7EC;
                                animation: fadeOut 3s forwards;
                            }

                            ._updatedRow.purple {
                                background-color: #edebfe !important;
                                animation: fadeOutPurple 3s forwards;
                            }
                            ._updatedRow.red {
                              background-color: rgb(255 228 230);  !important;
                                animation: fadeOutRed 2s forwards;
                            }
                            ._updatedRow.blue {
                              background-color: rgb(228 241 255);  !important;
                                animation: fadeOutBlue 2s forwards;
                            }

                            ._updatedRow > td > div {
                                max-height: 1000px;
                                transition: max-height 1s;
                            }

                            @keyframes fadeOut {
                                0% {background-color: #DEF7EC;}
                                90% {background-color: #DEF7EC;}
                                100% {background-color: transparent;}
                            }

                            @keyframes fadeOutPurple {
                                0% {background-color: #edebfe;}
                                90% {background-color: #edebfe;}
                                100% {background-color: transparent;}
                            }

                            @keyframes fadeOutRed {
                                0% {background-color: rgb(255 228 230); ;}
                                90% {background-color: rgb(255 228 230); ;}
                                100% {background-color: transparent;}
                            }

                            @keyframes fadeOutBlue {
                                0% {background-color: rgb(228 241 255);}
                                90% {background-color: rgb(228 241 255);}
                                100% {background-color: transparent;}
                            }

                            ._deletedRow {
                                /*background-color: #FDE8E8 !important;*/
                            }
                            ._deletedRow > td > div {
                                overflow: hidden;
                                max-height: 0;
                            }
                            ._deletedRow > td {
                                padding-top: 0px;
                                padding-bottom: 0px;
                            }
                        `}
        </style>
      </div>
    );
  }
);

DynamicTable.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.func,
  onSelect: PropTypes.func,
  selectedRow: PropTypes.array,
  loading: PropTypes.string,
  style: PropTypes.object,
  ver: PropTypes.number,
  mini: PropTypes.bool,
  meta: PropTypes.object,
  miniWidth: PropTypes.number,
  hiddenFields: PropTypes.array,
  loadMoreData: PropTypes.func,
};

export default DynamicTable;
