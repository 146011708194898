import React, { forwardRef, memo, useEffect, useState } from "react";
import Box from "../../../../../dynamic-page/elements/Box";
import SummaryView from "./summaryView";
import SummaryEdit from "./summaryEdit";
import callEndpoint from "../../../../../dynamic-page/util/callEndpoint";
import PropTypes from "prop-types";

const SummaryLeftSide = memo(
  forwardRef((props, ref) => {
    const {
      order,
      id,
      isView,
      update,
      callBack,
      orderItems,
      summaryForm,
      actionCallBack,
      selectedOrder,
      data,
    } = props;

    SummaryLeftSide.propTypes = {
      order: PropTypes.object.isRequired,
      id: PropTypes.string.isRequired,
      isView: PropTypes.bool.isRequired,
      update: PropTypes.func.isRequired,
      callBack: PropTypes.func.isRequired,
      orderItems: PropTypes.array.isRequired,
      summaryForm: PropTypes.object.isRequired,
      actionCallBack: PropTypes.func.isRequired,
      selectedOrder: PropTypes.object.isRequired,
      data: PropTypes.object.isRequired,
    };

    const [summaryData, setSummaryData] = useState(summaryForm);

    useEffect(() => {
      setSummaryData(summaryForm);
    }, [summaryForm]);

    const changeForm = (form, bind) => {
      if (isView) {
        const newData = {
          ...summaryForm,
          ...form,
          _data: { ...summaryForm?._data, ...bind },
        };
        setSummaryData(newData);
      } else {
        callBack(form, bind);
      }
    };

    const handleSave = async (value, type) => {
      const tempWeight = summaryData?.billable_weight;
      const tempContent = summaryData?.shipping_content;
      if (type === "weight") {
        callEndpoint({
          title: "Update Billable Weight",
          url: `orders/${selectedOrder?.id}`,
          data: { billable_weight: value },
          method: "post",
        }).then((res) => {
          //   callBack(form, _data)
          if (res?.type === "success") {
            setSummaryData({
              ...summaryData,
              billable_weight: (+value).toFixed(2),
            });
            update(res?.data);
          } else {
            setSummaryData({
              ...summaryData,
              billable_weight: tempWeight,
            });
          }
        });
      } else if (type === "shipping_content") {
        callEndpoint({
          title: "Update Shipping Content",
          url: `orders/${selectedOrder?.id}`,
          data: { shipping_content: value?.length === 0 ? null : value },
          pureData: { shipping_content: value?.length === 0 ? null : value },
          method: "post",
        }).then((res) => {
          //   callBack(form, _data)
          if (res?.type === "success") {
            setSummaryData({
              ...summaryData,
              shipping_content: value,
            });
            update(res?.data);
          } else {
            setSummaryData({
              ...summaryData,
              shipping_content: tempContent,
            });
          }
        });
      }
    };

    return (
      <Box
        title={isView ? null : "New Order Details"}
        className="!h-fit !max-h-[600px]"
      >
        <style>
          {`
                    .css-1u9des2-indicatorSeparator {display: none; }
                    .css-1xc3v61-indicatorContainer {padding-left: 0px; padding-right:6px }
                    .css-1xc3v61-indicatorContainer {padding-right: 12px;}
                    .css-1xc3v61-indicatorContainer svg {color:transparent !important; background: url('/assets/images/arrow.svg'); width:12px; height:12px; opacity:0.7}
                    input[type="date"]::before { opacity:1; right:12px; background: url('/assets/images/arrow.svg') no-repeat center !important; width: 12px !important; height:12px !important; opacity:0.7 !important; border:none !important; right:12px !important }
                    input[type="date"]::-webkit-datetime-edit-month-field { opacity:0.4 !important; }
                    input[type="date"].hasValue::-webkit-datetime-edit-month-field { opacity:1 !important; }
                    
                    input[type="date"]::-webkit-datetime-edit-text { opacity:0.4 !important; }
                    input[type="date"]::-webkit-datetime-edit-text { opacity:1 !important; }
                    
                `}
        </style>
        {/*if isView is true, render SummaryView, else render SummaryEdit*/}
        {isView ? (
          <div className="pt-3.5 min-h-[600px]">
            <SummaryView
              id={id}
              orderItems={orderItems}
              selectedOrder={selectedOrder}
              summaryForm={summaryData}
              callBack={callBack}
              isView={isView}
              handleSave={handleSave}
            />
          </div>
        ) : (
          //summaryData &&
          <SummaryEdit
            ref={ref}
            callBack={changeForm}
            order={order}
            data={data}
            actionCallBack={actionCallBack}
            orderItems={orderItems}
            summaryForm={summaryData}
            isView={isView}
          />
        )}
      </Box>
    );
  })
);

export default SummaryLeftSide;
