import React, {forwardRef, memo} from 'react';
import PropTypes from 'prop-types';

import DynamicForm from "../../../../../dynamic-form";
import summaryItemsForm from "./../model-view/summaryItemsForm";
import summaryEditFormStyle from "./../model-view/summaryEditFormStyle";
import summaryEditDescription from "./../model-view/summaryEditDescription";

const SummaryEdit = forwardRef((props, ref) => {

    let {orderItems, summaryForm, callBack, actionCallBack, data} = props;


    const formItems = summaryItemsForm(summaryForm)

    const description = summaryEditDescription(summaryForm, orderItems, actionCallBack,data)
    return (<div className='flex flex-col px-[16px] mb-[16px] '>
            {/* summary content */}
            <DynamicForm onChange={callBack} items={formItems} style={summaryEditFormStyle} ref={ref}/>

            <div className='flex flex-col  gap-[8px] '>
                {description.map((item, index) => (
                    <div key={item.label}>

                        <div  className='flex flex-row  items-start  justify-between w-full'>
                                <span
                                    className='select-none flex text-gray-900 text-[16px] font-semibold justify-start items-start '>
                                    {item?.label}
                                </span>
                            <span className={"font-normal text-[16px] text-gray-700"}>{item?.theme(item?.value)}</span>
                        </div>

                    </div>
                ))}
            </div>
        </div>
    );
});
SummaryEdit.propTypes = {
    orderItems: PropTypes.array.isRequired,
    summaryForm: PropTypes.object.isRequired,
    callBack: PropTypes.func.isRequired,
    actionCallBack: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
};

export default memo(SummaryEdit);


