import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { HiCheck, HiTrash } from "react-icons/hi";
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createGoodsAcceptance as createRow } from "../../store/goods_acceptance_update";
import util from "../dynamic-page/util";
import WarehouseSelect from "./warehouseSelect";
import ProductSelect from "./productSelect";
import GoodsAcceptanceList from "./goodsAcceptanceList";
import axios from "axios";
import ConfirmModal from "../dynamic-page/util/confirmModal";

const validationSchema = Yup.object().shape({
  id: Yup.number().required("ID is required"),
  quantity: Yup.number()
    .typeError("Quantity should be greater than 1")
    .required("Add value greater than 1"),
  purchase: Yup.number()
    .typeError("Please Select Purchase")
    .required("Purchase option is required"),
  expiry_date: Yup.string().nullable(),
  note: Yup.string().nullable(),
});

const validateAll = async (validationSchema, items) => {
  const newErrors = {};
  for (const item of items) {
    try {
      await validationSchema.validateSync(item, { abortEarly: false });
    } catch (error) {
      const itemId = item.id;
      error.inner.forEach((err) => {
        newErrors[itemId] = { ...newErrors[itemId], [err.path]: err.message };
      });
    }
  }
  return newErrors;
};

const validateField = async (
  validationSchema,
  items,
  errors,
  id,
  field,
  value
) => {
  const index = items.findIndex((item) => item.id === id);
  const newItem = {
    ...items[index],
    [field]: field === "purchase" ? value?.id : value,
  };
  let newErrors = { ...errors };

  const fieldValidationSchema = Yup.reach(validationSchema, field);
  try {
    await fieldValidationSchema.validate(newItem[field]);
    if (newErrors[id]) {
      delete newErrors[id][field];
      if (Object.keys(newErrors[id]).length === 0) {
        delete newErrors[id];
      }
    }
  } catch (error) {
    newErrors[id] = { ...newErrors[id], [field]: error.message };
  }
  return newErrors;
};
const styles = {
  infoText: "",
  confirmButton: "btn-blue-700",
  cancelButton: "btn-red-700",
};

const GoodsAcceptanceNew = () => {
  const [product, setProduct] = useState(null);
  const [wareHouse, setWareHouse] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [items, setItems] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [idCounter, setIdCounter] = useState(1);

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputChange = async (id, field, value) => {
    const newErrors = await validateField(
      validationSchema,
      items,
      errors,
      id,
      field,
      value
    );
    setErrors(newErrors);
    const index = items.findIndex((item) => item.id === id);

    setItems((prevItems) => {
      const newItems = [...prevItems];
      if (field === "purchase") {
        newItems[index][field] = value?.id;
        newItems[index]["selectedPurchase"] = value;
      } else {
        newItems[index][field] = value;
      }
      return newItems;
    });
  };

  const handleNotifyUser = (rs) => {
    util.notifier(rs?.data);
  };

  const handleDuplicate = (id) => {
    setItems((oldItems) => {
      const itemToDuplicate = oldItems.find((item) => item.id === id);
      if (!itemToDuplicate) return oldItems;

      const newItem = { ...itemToDuplicate, id: idCounter }; // Create a new object with the same properties but a new ID
      return [...oldItems, newItem];
    });
    const customId = idCounter + 1;
    setIdCounter(customId);
  };

  const handleRemove = (id) => {
    setItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const handleRemoveAll = () => {
    setItems([]);
  };

  const save = async () => {
    const filteredData = items
      .filter((item) => item.selectedPurchase !== null)
      .map((item) => {
        return {
          id: item.product_id,
          supplier_id: item?.selectedPurchase?.supplier?.id,
          purchase_id: item?.selectedPurchase?.id,
          expiry_date: item?.expiry_date,
          note: item?.note,
          quantity: item?.quantity,
        };
      });
    // If there are no errors, prepare items and log them
    if (Object.keys(errors).length === 0) {
      const preparedItems = filteredData.map((item) => {
        return Object.entries(item).reduce((acc, [key, value]) => {
          if (value !== null && value !== undefined) {
            acc[key] = value;
          }
          return acc;
        }, {});
      });

      const data = {
        warehouse_id: wareHouse?.value,
        products: preparedItems,
      };
      handleClose();
      await dispatch(createRow(data))
        .unwrap()
        .then((res) => {
          handleNotifyUser(res);
          setTimeout(() => {
            if (res?.status === 200) {
              handleRemoveAll();
              navigate(-1);
            }
          }, 2000);
        });
    }
  };

  const handleClose = () => {
    setShowConfirm(false);
  };

  const handleConfirm = async () => {
    const newErrors = await validateAll(validationSchema, items);
    setErrors(newErrors);
    if (!items.length > 0) {
      handleNotifyUser({
        data: { type: "warning", message: "Please add Product to continue" },
      });
      return;
    }
    if (!wareHouse) {
      setErrorMessage("Please Select Warehouse");
      return;
    }
    if (Object.keys(newErrors).length === 0) {
      setShowConfirm(true);
    }
  };

  const onProductSelected = (product) => {
    const isProductAlreadyAdded = items.find((p) => p.id === product.id);
    axios.get(`/api/v1/orders?filter[product]=${product.id}`).then((res) => {
    if (!isProductAlreadyAdded) {
      setItems((prevItems) => [
        {
          id: idCounter,
          product_id: product?.id,
          image: product?.image,
          title: product?.title,
          apid: product?.apid,
          assign: product?.assign,
          supplier_id: product?.supplier_id,
          quantity: null,
          expiry_date: null,
          purchase: null,
          selectedPurchase: null,
          note: null,
          awaiting_shipment_orders_count: res?.data?.meta?.total,
        },
        ...prevItems,
      ]);
    }
    const customId = idCounter + 1;
    setIdCounter(customId);
      setProduct(null);
    });
  };

  let hash = window.location.hash;
  hash = hash.replace("#", "?"); // Replace first '&' with '?'
  const params = new URLSearchParams(hash);
  const product_id = params.get("product_id");

  useEffect(() => {
    if (product_id) {
      axios.get(`/api/v1/product/${product_id}`).then((res) => {
        setProduct(res?.data?.data);
        onProductSelected(res?.data?.data);
      });
    }
  }, []);

  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        <ArbitProductPage.Title title={"New Goods Acceptance"} />
        <ArbitProductPage.Tab>
          <ProductSelect
            product={product}
            setProduct={setProduct}
            onProductSelected={onProductSelected}
          />

          <WarehouseSelect
            wareHouse={wareHouse}
            setWareHouse={setWareHouse}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
          />
        </ArbitProductPage.Tab>

        <ArbitProductPage.Content>
          <div className="overflow-x-auto">
            <GoodsAcceptanceList
              items={items}
              handleInputChange={handleInputChange}
              handleDuplicate={handleDuplicate}
              handleRemove={handleRemove}
              errors={errors}
            />
          </div>
        </ArbitProductPage.Content>
        <ArbitProductPage.Footer>
          <div className="w-[1735px] h-[46px] px-2 justify-between items-start gap-4 inline-flex">
            <button
              type="button"
              onClick={items.length > 0 ? handleRemoveAll : null}
              className={`px-5 py-[11px] rounded-lg border border-red-700 justify-center items-center gap-1.5 flex ${
                items.length === 0 ? "opacity-50" : "cursor-pointer"
              }`}
            >
              <HiTrash className="text-red-700 w-5 h-5 relative" />
              <div className="text-red-700 text-[16px] font-medium leading-normal">
                Delete All
              </div>
            </button>
            <div className="justify-start items-start gap-4 flex">
              <button
                type="button"
                onClick={handleConfirm}
                className="cursor-pointer px-5 py-[11px] bg-blue-700 rounded-lg justify-center items-center gap-1.5 flex"
              >
                <HiCheck className="text-white w-5 h-5 relative" />
                <div className="text-white text-[16px] font-medium leading-normal">
                  Complete Acceptance
                </div>
              </button>
            </div>
          </div>
        </ArbitProductPage.Footer>
      </ArbitProductPage.Page>

      {showConfirm && (
        <ConfirmModal
          show={showConfirm}
          onClose={handleClose}
          onConfirm={save}
          cancelColor="failure"
          infoText={`Are you sure you want to continue?`}
          confirmText="Yes, I'm sure"
          cancelText="No, cancel"
          styles={styles}
        />
      )}
    </>
  );
};

export default GoodsAcceptanceNew;
