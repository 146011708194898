/* eslint-disable react-hooks/exhaustive-deps */
// Import main libraries
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// Import dynamic page libraries
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import FooterPagination from "../dynamic-page/elements/FooterPagination";
import DynamicTable from "../dynamic-page";
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import SearchInput from "./../dynamic-page/debt/searchInput";

// Import model-view libraries
import filtersView from "./models-view/filters";
import sortView from "./models-view/sort";
import tableView from "./models-view/table";

// Import redux actions
import { updateRow } from "../../store/orders";

import formView from "./models-view/form";
import FormModal from "../dynamic-page/FormModal";
import {
  getAllPickupPoints,
  updateBoundFilter,
  updateFilters,
  updateRow as updateSingleRow,
} from "../../store/pickup-points";
import ConfirmModal from "../dynamic-page/util/confirmModal";
import callEndpoint from "../dynamic-page/util/callEndpoint";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import useLoadMoreData from "../../utils/useLoadMoreData";
import Icons from "../orders/icons";
import { Tooltip } from "react-tooltip";
import upperCase from "../dynamic-page/util/upperCaseString";

export const PUPTransferCostTooltip = (type) => (
  <span className="">
    <Icons.Info
      className="w-5 h-5  text-gray-300"
      data-tooltip-id={`PUPTransferCostTooltip${type || ""}`}
    />
    <Tooltip
      id={`PUPTransferCostTooltip${type || ""}`}
      place="top"
      positionStrategy="fixed"
      className="z-[99999] bg-white !opacity-100"
      style={{
        backgroundColor: "white",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        borderRadius: "4px",
        padding: "0px 0px 0px 0px",
      }}
    >
      <div className="flex flex-col items-start gap-2 py-2.5 px-3 w-[236px]">
        <span className="text-[14px] leading-[14px] font-medium text-gray-900">
          PUP Transfer Cost Estimated
        </span>
        <span className="text-gray-500 text-[12px] text-start leading-[15px] font-normal whitespace-pre-wrap">
          {`Estimated cost per kilogram to ship from Istanbul warehouse to this pick-up point.`}
        </span>
      </div>
    </Tooltip>
  </span>
);
export const PUPProccessingFeeTooltip = (type) => (
  <span className="">
    <Icons.Info
      className="w-5 h-5  text-gray-300"
      data-tooltip-id={`PUPProccessingFeeTooltip${type || ""}`}
    />
    <Tooltip
      id={`PUPProccessingFeeTooltip${type || ""}`}
      place="top"
      positionStrategy="fixed"
      className="z-[99999] bg-white !opacity-100"
      style={{
        backgroundColor: "white",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        borderRadius: "4px",
        padding: "0px 0px 0px 0px",
      }}
    >
      <div className="flex flex-col items-start gap-2 py-2.5 px-3 w-[270px]">
        <span className="text-[14px] leading-[14px] font-medium text-gray-900">
          PUP Processing Fee
        </span>
        <span className="text-gray-500 text-start text-[12px] leading-[15px] font-normal whitespace-pre-wrap">
          {`Processing fee per item (if applicable) for shipments sent from this pick-up point.`}
        </span>
      </div>
    </Tooltip>
  </span>
);
export const PUPProviderTooltip = (type) => (
  <span className="">
    <Icons.Info
      className="w-5 h-5  text-gray-300"
      data-tooltip-id={`PUPProviderTooltip${type || ""}`}
    />
    <Tooltip
      id={`PUPProviderTooltip${type || ""}`}
      place="top"
      positionStrategy="fixed"
      className="z-[99999] bg-white !opacity-100"
      style={{
        backgroundColor: "white",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        borderRadius: "4px",
        padding: "0px 0px 0px 0px",
      }}
    >
      <div className="flex flex-col items-start gap-2 py-2.5 px-3 w-[270px]">
        <span className="text-[14px] leading-[14px] font-medium text-gray-900">
          Pick-up Point Provider
        </span>
        <span className="text-gray-500 text-[12px] text-start leading-[15px] font-normal whitespace-pre-wrap">
          Provider of the pick-up point.
          <br />
          Indicates the service provider of this pick-up point.
        </span>
      </div>
    </Tooltip>
  </span>
);

// Main Component
const PickupPoints = () => {
  // Define hooks.
  const location = useLocation();
  const dispatch = useDispatch();

  // First page load.
  useEffect(() => {
    const startPageByFilters = {
      search: "",
      sort: null,
      page: 1,
      filters: { status: "" },
      includes: "country,state",
      limit: 10,
    };
    const storeQuery = storeQueryBuilder(
      startPageByFilters,
      urlQueryLoader(startPageByFilters)
    );
    const urlQuery = urlQueryBuilder(storeQuery, null, "term", true);
    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(updateBoundFilter(mappedFilters));
    dispatch(updateFilters(storeQuery));
    dispatch(getAllPickupPoints(urlQuery));
  }, [location]);

  // Get data from store.
  const pageData = useSelector((state) => state.pickupPoints);
  const tableData = useSelector(
    (state) => state.pickupPoints.pickupPoints ?? []
  );

  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState(null);

  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    getAllPickupPoints,
  });

  // Handle modal.
  const [modalVisibility, setModalVisibility] = useState(null);
  const [formData, setFormData] = useState({});

  const onHandleSave = (data, clear) => {
    let payload = null;
    const { state_id, ...rest } = data;
    if (state_id === null) {
      payload = rest;
    } else {
      payload = data;
    }

    // remove the null keys
    Object.keys(payload).forEach(
      (key) => payload[key] == null && delete payload[key]
    );

    if (data?.id) {
      callEndpoint({
        title: `Updating Pickup Point`,
        url: `pickup-points/${data?.id}`,
        method: "PUT",
        data: payload,
        pureData: true,
      }).then((res) => {
        if (res.type === "success") {
          clear();
          setTimeout(() => {
            onPaginationApply();
          }, 500);
        }
      });
    } else {
      if (!payload?.transfer_cost) {
        payload.transfer_cost = 0;
      }
      if (!payload?.processing_fee) {
        payload.processing_fee = 0;
      }
      callEndpoint({
        title: `Create Pickup Point`,
        url: `pickup-points`,
        method: "POST",
        data: payload,
        pureData: true,
      }).then((res) => {
        if (res.type === "success") {
          clear();
          setTimeout(() => {
            onPaginationApply();
          }, 500);
        }
      });
    }
  };

  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(null);

  const actionList = {
    edit: {
      label: "Edit",
      bulkDisable: true,
      onClick: (item) => {
        setModalVisibility(true);
        setFormData(item);
      },
    },
    delete: {
      label: "Delete",
      onClick: (item) => {
        setConfirmationModalVisibility({
          infoText:
            "Are you sure you want to delete the selected pickup point/s?",
          confirmText: "Yes, Delete",
          callBack: (setSubmit) => {
            const rows = item?.id ? [item] : selectedRow;
            const ids = item?.id
              ? [item?.id]
              : selectedRow.map((item) => item?.id);
            callEndpoint({
              title: "Delete Pickup Point",
              url: `pickup-points`,
              method: "DELETE",
              data: { pickup_points: ids },
              pureData: true,
            }).then((res) => {
              if (res.type === "success") {
                setConfirmationModalVisibility(false);
                setSelectedRow([]);
                ids.map((id) => {
                  dispatch(
                    updateSingleRow({
                      path: `pickupPoints.[id:${id}]`,
                      value: {
                        ...rows.find((item) => item?.id === id),
                        _updatedRow: true,
                        _updatedColor: "red",
                      },
                    })
                  );
                  setTimeout(() => {
                    dispatch(
                      updateSingleRow({
                        path: `pickupPoints.[id:${id}]._deletedRow`,
                        value: true,
                      })
                    );
                    setTimeout(() => {
                      dispatch(
                        updateSingleRow({
                          path: `pickupPoints.[id:${id}]`,
                        })
                      );
                    }, 1000);
                  }, 1000);
                });
              }
            });
          },
        });
      },
    },
  };

  // handle actions of statuses
  const actions = (row) => {
    return {
      count: row?.length,
      list: Array.isArray(row)
        ? [actionList.delete]
        : [actionList.edit, actionList.delete],
    };
  };

  // Initial sort list
  const sortList = sortView({
    callback: (value) => {
      onSortApply(value);
    },
  });

  // Handle Pagination

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilters(storeQuery));
    dispatch(getAllPickupPoints(urlQuery));
  };

  const onFilterApply = (filters, bound) => {
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateBoundFilter({ filters, bound }));
    dispatch(updateFilters(storeQuery));
    dispatch(getAllPickupPoints(urlQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    let changeSort;

    if (sort === currentStoreFilters?.sort) {
      changeSort = null;
    } else if (currentStoreFilters?.sort === `-${sort}`) {
      // Check if the store's sort is the reverse of the current sort
      changeSort = sort;
    } else {
      // Otherwise, set it to the reverse of the current sort
      changeSort = `-${sort}`;
    }

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilters(storeQuery));
    dispatch(getAllPickupPoints(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      limit: limit || currentStoreFilters.limit || 10,
    });

    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    await dispatch(updateFilters(storeQuery), currentStoreFilters);
    await dispatch(getAllPickupPoints(urlQuery));
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };
  const onUpdateValue = (data, callback) => {
    const title = data?.type?.split("_").join(" ");
    callEndpoint({
      title: `Updating ${upperCase(title, "word")}`,
      url: `pickup-points/${data?.id}`,
      method: "put",
      data: data?.payload,
      onlyShowMessage: true,
      pureData: true,
    }).then((rs) => {
      if (rs?.type === "success") {
        callback(rs?.data?.[data?.type]);
        dispatch(
          updateSingleRow({
            path: `pickupPoints.[id:${data?.id}]`,
            value: {
              ...pageData?.pickupPoints?.find((item) => item?.id === data?.id),
              [data?.type]: rs?.data?.[data?.type],
              _updatedRow: true,
            },
          })
        );
        setTimeout(() => {
          dispatch(
            updateSingleRow({
              path: `pickupPoints.[id:${data?.id}]`,
              value: {
                ...pageData?.pickupPoints?.find(
                  (item) => item?.id === data?.id
                ),
                [data?.type]: rs?.data?.[data?.type],
                _updatedRow: false,
              },
            })
          );
        }, 3000);
      } else {
        callback(data?.prevVal);
      }
    });
  };
  const onUpdateNote = (data) => {
    callEndpoint({
      url: `pickup-points/${data.id}`,
      method: "put",
      data: { note: data.note },
      pureData: true,
    })
      .then((rs) => {
        if (rs?.type === "success") {
          const row = tableData.find((item) => item.id === data.id);

          dispatch(
            updateSingleRow({
              path: `pickupPoints.[id:${data?.id}]`,
              value: {
                ...row,
                note: rs?.data?.note,
                _updatedRow: true,
              },
            })
          );
          setTimeout(() => {
            dispatch(
              updateRow({
                path: `pickupPoints.[id:${data?.id}]._updatedRow`,
                value: false,
              })
            );
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const tableActions = {
    onUpdateValue,
    onUpdateNote,
  };

  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        <ArbitProductPage.Title
          title={"Pick-up Points"}
          filter={{
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            items: filtersView(pageData?.filters?.filters),
            discard: ["type"],
            callback: (rs, bound) => {
              onFilterApply(rs, bound);
            },
            count:
              Object.values(pageData?.filters?.filters ?? {}).filter(
                (item) => item !== null && item !== ""
              ).length -
              (pageData?.filters?.filters?.status === undefined ||
              pageData?.filters?.filters?.status === null
                ? 0
                : 1),
          }}
          sort={{
            items: sortList,
            selected: pageData?.filters?.sort,
          }}
          form={{
            callback: (rs) => {
              setModalVisibility(true);
            },
          }}
          actionList={actions(selectedRow)}
        />

        <div
          className={
            "flex flex-row justify-between px-[16px] py-[4px] items-center h-[50px]"
          }
        >
          <SearchInput
            className="w-[362px]"
            placeholder="Search..."
            onSubmit={onSearchApply}
            defaultValue={pageData?.filters?.search}
          />
        </div>

        <ArbitProductPage.Content>
          <div className=" ">
            <div className="overflow-x-auto max-w-[100%]">
              <DynamicTable
                view={tableView}
                data={tableView(tableData ?? [], tableActions, actionList)}
                onSelect={onSelectRow}
                selectedRow={selectedRow}
                actions={actions}
                meta={pageData?.meta}
                appending={loading}
                loadMoreData={loadMoreData}
                loading={pageData.loading}
                style={{
                  hiddenActions: true,
                  header: {
                    className:
                      "flex justify-start !px-4 !py-4 whitespace-nowrap",
                    th: "!h-[50px]",

                    "PUP tRANSFER COST Est.": {
                      className: "text-right flex !justify-end",
                    },
                    "PUP processıng fee": {
                      className: "text-right flex !justify-end",
                    },
                  },
                  row: {
                    className:
                      "flex items-start justify-start  !w-max !px-4 !py-2 ",
                    td: "!h-[54px]",
                    country: {
                      className: "!py-0 !min-w-[220px]",
                    },
                    name: {
                      className: "!min-w-[220px]",
                    },
                    provider: {
                      className: "!min-w-[220px]",
                    },

                    "PUP tRANSFER COST Est.": {
                      className: "!min-w-[220px] text-right flex !justify-end",
                      icon: PUPTransferCostTooltip("Table"),
                    },
                    "PUP processıng fee": {
                      className: "!min-w-[220px] text-right flex !justify-end",
                      icon: PUPProccessingFeeTooltip("Table"),
                    },
                    "": {
                      className: " !py-1",
                      td: "w-[100%] ",
                    },
                  },
                }}
              />
            </div>
          </div>
        </ArbitProductPage.Content>

        <FooterPagination
          meta={{ ...pageData?.meta, limit: pageData?.filters?.limit }}
          pageData={pageData}
          loading={pageData.loading}
          onLimit={onHandleLimit}
        />
      </ArbitProductPage.Page>
      {modalVisibility && (
        <FormModal
          title={formData?.name ? `${formData?.name}` : "Pick-up Point"}
          prefix={"Create"}
          formView={formView}
          visibility={modalVisibility}
          data={formData}
          buttonsContainer=" !mt-[18px] !pt-0 !mb-[4px] !px-0"
          onClose={() => {
            setModalVisibility(false);
            setFormData(null);
          }}
          saveText="Create"
          createNew={!formData?.id}
          onSubmit={onHandleSave}
          container={(children) => (
            <div className="!w-[868px] grid grid-cols-12 items-start gap-[8px] justify-center border-t border-t-gray-200  !px-[1px] pt-4">
              {children}
            </div>
          )}
        />
      )}

      {confirmationModalVisibility && (
        <ConfirmModal
          show={confirmationModalVisibility}
          infoText={
            confirmationModalVisibility?.infoText ||
            "Are you sure you want to mark this order as shipped?"
          }
          onClose={() => setConfirmationModalVisibility(false)}
          confirmText={
            confirmationModalVisibility?.confirmText || "Yes, Mark as Shipped"
          }
          confirmColor="green"
          cancelText="Cancel"
          cancelColor="red"
          styles={{
            confirmButton: "!bg-green-50 ",
            cancelButton: "!bg-red-50 ",
            infoText: "!font-medium",
          }}
          onConfirm={(setSubmit) => {
            confirmationModalVisibility?.callBack(setSubmit);
          }}
        />
      )}
    </>
  );
};

export default PickupPoints;
