// Thunk.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";
import { fetchLoading } from ".";

const fetchRows = createAsyncThunk(
  "get:api/v1/dynamicPage/fetchRows",
  async (params, thunkAPI) => {
    if (!params.append) thunkAPI.dispatch(fetchLoading(params.title));
    const response = await api.fetchRows(params, thunkAPI.signal);
    return { data: response.data, append: params.append, title: params?.title };
  }
);

const fetchFilters = createAsyncThunk(
  "get:api/v1/dynamicPage/fetchFilters",
  async (params, thunkAPI) => {
    const response = await api.fetchFilters(params, thunkAPI.signal);
    return { response, title: params?.title };
  }
);

export { fetchRows, fetchFilters };
